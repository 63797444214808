import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const CircleDeleteIcon = () => (<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
  <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
    <path d="m16 8-4 4m0 0-4 4m4-4-4-4m4 4 4 4" />
    <circle cx="12" cy="12" r="10" />
  </g>
</svg>);

export const QuestionIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path d="M10.97 8.265a1.45 1.45 0 00-.487.57.75.75 0 01-1.341-.67c.2-.402.513-.826.997-1.148C10.627 6.69 11.244 6.5 12 6.5c.658 0 1.369.195 1.934.619a2.45 2.45 0 011.004 2.006c0 1.033-.513 1.72-1.027 2.215-.19.183-.399.358-.579.508l-.147.123a4.329 4.329 0 00-.435.409v1.37a.75.75 0 11-1.5 0v-1.473c0-.237.067-.504.247-.736.22-.28.486-.517.718-.714l.183-.153.001-.001c.172-.143.324-.27.47-.412.368-.355.569-.676.569-1.136a.953.953 0 00-.404-.806C12.766 8.118 12.384 8 12 8c-.494 0-.814.121-1.03.265zM13 17a1 1 0 11-2 0 1 1 0 012 0z" />
  <path fillRule="evenodd" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zM2.5 12a9.5 9.5 0 1119 0 9.5 9.5 0 01-19 0z" />
</svg>);

export const WarningIcon = () => (<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
  <path d="m13.1784199 2.2788327c.5100748.25503742.9236711.66863371 1.1787085 1.17870854l7.3640389 14.72807776c.6509827 1.3019655.123257 2.8851429-1.1787085 3.5361257-.3659775.1829887-.7695334.2782553-1.1787086.2782553h-14.72807778c-1.45564168 0-2.63567242-1.1800307-2.63567242-2.6356724 0-.4091752.09526662-.8127311.27825534-1.1787086l7.3640389-14.72807776c.65098276-1.3019655 2.23416016-1.82969129 3.53612566-1.17870854zm-1.9411822 2.19270941-7.14721684 14.29443359c-.05920031.1184007-.09002086.2489586-.09002086.3813345 0 .4709276.38176222.8526898.85268979.8526898h14.29443361c.1323759 0 .2629339-.0308206.3813345-.0900209.4212104-.2106052.5919397-.7227929.3813345-1.1440034l-7.1472169-14.29443359c-.0825094-.16501884-.2163156-.29882505-.3813344-.38133447-.4212104-.21060521-.9333982-.03987595-1.1440034.38133447zm.7627623 11.52845789c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1-1-.4477153-1-1 .4477153-1 1-1zm0-8c.5522847 0 1 .44771525 1 1v4c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1v-4c0-.55228475.4477153-1 1-1z" fillRule="evenodd" />
</svg>);

export const RefreshIcon = () => (<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
  <path d="m5 6.3422181c1.68697264-2.08680769 4.23673432-3.3422181 7-3.3422181 4.9705627 0 9 4.02943725 9 9 0 4.9705627-4.0294373 9-9 9-4.76453427 0-8.69607013-3.7133439-8.98326972-8.4469566-.01672347-.2756355.19316622-.5126393.46880173-.5293628.27563552-.0167234.5126393.1931663.52936276.4688018.25522378 4.2065884 3.75011181 7.5075176 7.98510523 7.5075176 4.418278 0 8-3.581722 8-8s-3.581722-8-8-8c-2.46941985 0-4.74604847 1.12713252-6.24574935 3h2.74574935c.27614237 0 .5.22385763.5.5s-.22385763.5-.5.5h-4c-.27614237 0-.5-.22385763-.5-.5v-4c0-.27614237.22385763-.5.5-.5s.5.22385763.5.5z" />
</svg>);

export const CheckIcon = () => (<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="24">
  <path d="m256 8c-136.967 0-248 111.033-248 248s111.033 248 248 248 248-111.033 248-248-111.033-248-248-248zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068l-141.352 140.216-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" />
</svg>)

export const InfoIcon = ({ text }: { text: string }) => (<OverlayTrigger
  key='top'
  placement='top'
  overlay={<Tooltip id={`tooltip-top`}>{text}</Tooltip>}
><span className="info-icon"><i className="bi bi-info-circle"></i></span></OverlayTrigger>);
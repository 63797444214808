import { getSorter, toLocale } from "system/libraries/utilities";
import { SubjectStatusSubjectSummary, SubjectStatusTimeSubject } from "system/types/wireTypes";
import { CheckIcon, CircleDeleteIcon, QuestionIcon, RefreshIcon, WarningIcon } from "./Icons";
import SortableTableHeader from "./SortableTableHeader";
import { useMemo } from "react";
import useMultiFilters from "system/hooks/use-multi-filters";
import usePageNavigation from "system/hooks/use-page-navigation";
import useSearchField from "system/hooks/use-search-field";
import AppLink from "./AppLink";

interface IProps {
  data: SubjectStatusSubjectSummary | null;
  selectedDateString: string | null;
}

const localToLocale = (x: number) => (x > 0 ? toLocale(x) : "-");

const EndpointsBySubjectTable = ({ data, selectedDateString }: IProps) => {
  const { navigateToEndpointByStatusPage, navigateToSubjectByReasonPage, navigateToEndpointsByReason } = usePageNavigation();
  const { sortDesc, sortField } = useSearchField();
  const { multiFilterSearchString } = useMultiFilters();

  const sortedData = useMemo<SubjectStatusTimeSubject[] | null>(() => {
    if (data === null || data.bySubject === null) {
      return null;
    }

    switch (sortField) {
      case "error":
        return [...data.bySubject].sort(getSorter("nError", sortDesc)) ?? null;
      case "unknown":
        return [...data.bySubject].sort(getSorter("nUnknown", sortDesc)) ?? null;
      case "nonCompliant":
        return [...data.bySubject].sort(getSorter("nNoncompliant", sortDesc)) ?? null;
      case "compliantAfterReboot":
        return [...data.bySubject].sort(getSorter("nCompliantAfterReboot", sortDesc)) ?? null;
      case "compliant":
        return [...data.bySubject].sort(getSorter("nCompliant", sortDesc)) ?? null;
      case "subject":
        return [...data.bySubject].sort(getSorter("subject", sortDesc)) ?? null;
      default:
        return (
          [...data.bySubject]
            .sort(getSorter("nUnknown", true))
            .sort(getSorter("nCompliant", true))
            .sort(getSorter("nCompliantAfterReboot", true))
            .sort(getSorter("nNoncompliant", true))
            .sort(getSorter("nError", true)) ?? null
        );
    }
  }, [data, sortField, sortDesc]);

  if (!data?.bySubject?.length) {
    return <></>;
  }

  const onColumnClickHandler = (status: number) => {
    navigateToEndpointByStatusPage(status, selectedDateString, multiFilterSearchString);
  };

  const onsubjectClickHandler = (subject: string) => {
    navigateToSubjectByReasonPage(subject, selectedDateString, multiFilterSearchString);
  };

  const onSubjectEndpointCountClickHandler = (subject: string, status: number, amount: number | null = null) => {
    if (amount === null || amount > 0) {
      navigateToEndpointsByReason(status, subject, selectedDateString, multiFilterSearchString);
    }
  };

  return (
    <table className="table tb-endpoint">
      <thead className="sticky-top" style={{ backgroundColor: "var(--background)" }}>
        <tr>
          <th className="fit ps-2" scope="col">
            <SortableTableHeader
              title="Subjects"
              property="subject"
              style={{ fontSize: "20px", whiteSpace: "nowrap", color: "var(--title-color)" }}
            />
          </th>
          <th className="status-column text-end">
            <SortableTableHeader
              className="text-failed"
              title={data.statusTime.nError.toString()}
              icon={<CircleDeleteIcon />}
              property="error"
              style={{ fontSize: "20px", whiteSpace: "nowrap" }}
              onClick={() => onColumnClickHandler(20)}
              toolTip="Critical"
              disabled={!data.statusTime.nError}
            />
          </th>
          <th className="status-column text-end">
            <SortableTableHeader
              className="text-not-reporting"
              title={data.statusTime.nUnknown.toString()}
              icon={<QuestionIcon />}
              property="unknown"
              style={{ fontSize: "20px", whiteSpace: "nowrap" }}
              onClick={() => onColumnClickHandler(0)}
              toolTip="Not reporting"
              disabled={!data.statusTime.nUnknown}
            />
          </th>
          <th className="status-column text-end">
            <SortableTableHeader
              className="text-recommended"
              title={data.statusTime.nNoncompliant.toString()}
              icon={<WarningIcon />}
              property="nonCompliant"
              style={{ fontSize: "20px", whiteSpace: "nowrap" }}
              onClick={() => onColumnClickHandler(5)}
              toolTip="Has recommendations"
              disabled={!data.statusTime.nNoncompliant}
            />
          </th>
          <th className="status-column text-end">
            <SortableTableHeader
              className="text-pending-reboot"
              title={data.statusTime.nCompliantAfterReboot.toString()}
              icon={<RefreshIcon />}
              property="compliantAfterReboot"
              style={{ fontSize: "20px", whiteSpace: "nowrap" }}
              onClick={() => onColumnClickHandler(8)}
              toolTip="Compliant pending reboot"
              disabled={!data.statusTime.nCompliantAfterReboot}
            />
          </th>
          <th className="status-column text-end">
            <SortableTableHeader
              className="text-up-to-date"
              title={data.statusTime.nCompliant.toString()}
              icon={<CheckIcon />}
              property="compliant"
              style={{ fontSize: "20px", whiteSpace: "nowrap" }}
              onClick={() => onColumnClickHandler(9)}
              toolTip="Compliant"
              disabled={!data.statusTime.nCompliant}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedData?.map((row, index) => (
          <tr key={index}>
            <td className="fit ps-2" style={{ minWidth: "180px" }}>
              <a
                href="/#"
                onClick={(e) => {
                  onsubjectClickHandler(row.subject!);
                  e.preventDefault();
                }}
              >
                {row.subject}
              </a>
            </td>
            <td className="text-end">
              <AppLink
                onClick={() => onSubjectEndpointCountClickHandler(row.subject!, 20, row.nError)}
                className="tb-en text-failed"
                disabled={!row.nError}
              >
                {localToLocale(row.nError)}
              </AppLink>
            </td>
            <td className="text-end">
              <AppLink
                onClick={() => onSubjectEndpointCountClickHandler(row.subject!, 0, row.nUnknown)}
                className="tb-en text-not-reporting"
                disabled={!row.nUnknown}
              >
                {localToLocale(row.nUnknown)}
              </AppLink>
            </td>
            <td className="text-end">
              <AppLink
                onClick={() => onSubjectEndpointCountClickHandler(row.subject!, 5, row.nNoncompliant)}
                className="tb-en text-recommended"
                disabled={!row.nNoncompliant}
              >
                {localToLocale(row.nNoncompliant)}
              </AppLink>
            </td>
            <td className="text-end">
              <AppLink
                onClick={() => onSubjectEndpointCountClickHandler(row.subject!, 8, row.nCompliantAfterReboot)}
                className="tb-en text-pending-reboot"
                disabled={!row.nCompliantAfterReboot}
              >
                {localToLocale(row.nCompliantAfterReboot)}
              </AppLink>
            </td>
            <td className="text-end">
              <AppLink
                onClick={() => onSubjectEndpointCountClickHandler(row.subject!, 9, row.nCompliant)}
                className="tb-en text-up-to-date"
                disabled={!row.nCompliant}
              >
                {localToLocale(row.nCompliant)}
              </AppLink>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EndpointsBySubjectTable;

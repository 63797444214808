import React from 'react';
import ReactDOM from 'react-dom';
import './index.sass';
import 'font-awesome/css/font-awesome.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import './system/styles/global.css';
import './system/styles/icon.scss';
import './system/styles/table.scss';
import './system/styles/components.scss';
import './system/styles/datepicker.scss';
import './system/styles/datepicker.css';
import './system/styles/cleanStructure/style.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from 'system/store';
//Authentication
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

//start react
ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useCallback } from "react";
import HttpMethods from "system/types/enums/HttpMethods";
import IAuth from "system/types/interfaces/IAuth";

const useHttpRequest = (apiUrl: string, auth: IAuth) => {
  
  return useCallback(async (method: HttpMethods, action: string, body?: any, blob?: boolean): Promise<any> => {
    auth.extendSession(action);
    const token = await auth.getToken();
    if (!token) {
      console.warn(`Api ${method.toString()} request was not sent, not authenticated.`);
      return;
    }

    const response = await fetch(`${apiUrl}${action}`, {
      method: HttpMethods[method].toUpperCase(),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body ?? undefined)
    });

    try {
      if (response.status === 401) {
        auth.logout();
        return null;
      }

      return blob ? response.clone().blob() : await response.clone().json();
    }
    catch {
      const rawJson = await response.text();
      console.log('Server did not return expected response format: ' + rawJson);
      return null;
    }
  }, [auth, apiUrl]);
};

export default useHttpRequest;
import ActionButtons from "components/ui/ActionButtons";
import { defaultPageSize } from "components/ui/PageSelector";
import TableColumnHeader from "components/ui/TableColumnHeader";
import { ComplianceStatusToString, getFactValue, getSorter, isFactUnknown, toDate, toFormatedDate } from "system/libraries/utilities";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import usePageTitle from "system/hooks/use-page-title";
import IPagedRuntimeHistoryResponse from "system/types/interfaces/IPagedRuntimeHistoryResponse";
import IRuntimeHistoryItem from "system/types/interfaces/IRuntimeHistoryItem";
import ITitleData from "system/types/interfaces/ITitleData";
import ComplianceStatus from "components/ui/ComplianceStatus";
import LoadingPage from "./system/LoadingPage";
import usePageNavigation from "system/hooks/use-page-navigation";
import useUrlSearch from "system/hooks/use-url-search";
import useApi from "system/hooks/use-api";
import useBreadcrumbs from "system/hooks/use-breadcrumbs";
import IAppBreadcrumb from "system/types/interfaces/IAppBreadcrumb";
import ExportToCsvButton from "components/ui/ExportToCsvButton";

const EndpointRuntimeHistoryPage = () => {
  const api = useApi();
  const { machineId } = useParams();
  const location = useLocation();
  const urlSearch = useUrlSearch();
  const { navigateToHomePage, navigateToEndpointPage, getEndpointPageUrl } = usePageNavigation();
  const queryParams = new URLSearchParams(location.search);
  const [historyItems, setHistoryItems] = useState<IRuntimeHistoryItem[] | null>(null);
  const [breadcrumbs, setBreadcrumbs] = useState<IAppBreadcrumb[]>([]);
  const [titleData, setTitleData] = useState<ITitleData>({
    title: "Report history",
    subTitle: "",
  });

  const [machineName, setMachineName] = useState<string | null>(null);
  const sortAsc = queryParams.get("asc") === "1";
  const pageSize = +(queryParams.get("pageSize") ?? defaultPageSize);
  const currentPage = +(queryParams.get("page") ?? 1);

  usePageTitle(titleData);
  useBreadcrumbs(breadcrumbs);

  const loadRuntimeHistoryData = useCallback(() => {
    if (!machineId) {
      navigateToHomePage();
      return;
    }

    api.machine
      .getRuntimeHistory(+machineId!, currentPage, pageSize, sortAsc ? 1 : 0)
      .then((data: IPagedRuntimeHistoryResponse) => {
        if (!data.isSuccess) {
          navigateToEndpointPage(machineId);
          return;
        }

        setTitleData({
          title: data.machineName,
          subTitle: "Report history",
          navigateOnTitleClick: getEndpointPageUrl(machineId),
        });

        setBreadcrumbs([
          {
            name: data.machineName,
            url: getEndpointPageUrl(machineId),
          },
          {
            name: "Report history",
          },
        ]);

        setMachineName(data.machineName);
        setHistoryItems(data.items);
      })
      .catch((error) => {
        console.error(error);
        navigateToEndpointPage(machineId);
        return;
      });
  }, [api, currentPage, navigateToEndpointPage, navigateToHomePage, getEndpointPageUrl, pageSize, sortAsc, machineId]);

  useEffect(() => {
    loadRuntimeHistoryData();
  }, [loadRuntimeHistoryData, urlSearch]);

  const exportDataHandler = useCallback(async () => {
    const csvDataToExport = await api.machine
      .getRuntimeHistory(+machineId!, 0, 0, 0)
      .then((data: IPagedRuntimeHistoryResponse) => {
        return data.isSuccess ? data.items : null;
      })
      .catch(() => null);

    var result = csvDataToExport?.sort(getSorter("assessmentTime", true)).map((i) => ({
      AssessmentTime: toFormatedDate(i.assessmentTime),
      AssessmentTimeISO8601: i.assessmentTime,
      Configuration: getFactValue(i.machineFacts, "CONFIG"),
      ComplianceStatus: ComplianceStatusToString(getFactValue(i.machineFacts, "COMPLIANCESTATUS")),
      StateJustification: getFactValue(i.machineFacts, "COMPLIANCEJUSTIFICATION"),
      Repository: getFactValue(i.machineFacts, "REPO"),
      Site: getFactValue(i.machineFacts, "ADSITE"),
      AccountType: getFactValue(i.machineFacts, "WINACCT"),
      OSName: getFactValue(i.machineFacts, "OSNAME"),
      OSArchitecture: getFactValue(i.machineFacts, "OSARCH"),
      OSVersion: getFactValue(i.machineFacts, "OSVER"),
      Manufacturer: getFactValue(i.machineFacts, "MFR"),
      ManufacturerModel: getFactValue(i.machineFacts, "MODEL"),
      BIOSVersion: getFactValue(i.machineFacts, "BIOSVER"),
    }));

    return result;
  }, [api, machineId]);

  const exportData = useMemo(
    () => ({
      fileName: `Aiden ${machineName} Endpoint Report History ${toDate(new Date().toISOString())}`,
      source: exportDataHandler,
    }),
    [exportDataHandler, machineName]
  );

  const refreshHandler = () => {
    setHistoryItems(null);
    loadRuntimeHistoryData();
  };

  if (!historyItems) {
    return <LoadingPage />;
  }

  return (
    <>
      <ActionButtons onRefresh={refreshHandler}>
        <ExportToCsvButton items={[exportData]} />
      </ActionButtons>
      {/* Added false to the display condition to hide the page selector */}
      <table className="table table-dialog sticky-table tb-endpoint">
        <thead className="sticky-top">
          <tr>
            {/* removed properties to disable sorting: property="asc" singleSort={true} */}
            <TableColumnHeader title="Assessment changed" />
            <TableColumnHeader title="Configuration" />
            <TableColumnHeader title="State / Justification" />
            <TableColumnHeader title="Repository" />
            <TableColumnHeader title="Site" />
            <TableColumnHeader title="Account type" />
            <TableColumnHeader title="OS Name Architecture Version" />
            <TableColumnHeader title="Manufacturer Model" />
            <TableColumnHeader title="BIOS Version" />
          </tr>
        </thead>
        <tbody>
          {historyItems.sort(getSorter("assessmentTime", true)).map((i: IRuntimeHistoryItem) => (
            <tr key={i.assessmentTime}>
              <td>{toFormatedDate(i.assessmentTime)}</td>
              <td>{getFactValue(i.machineFacts, "CONFIG")}</td>
              <td>
                <ComplianceStatus
                  code={+(getFactValue(i.machineFacts, "COMPLIANCESTATUS") ?? 0)}
                  isUnknown={isFactUnknown(i.machineFacts, "COMPLIANCESTATUS")}
                />
                <div style={{ marginTop: "5px" }}>{getFactValue(i.machineFacts, "COMPLIANCEJUSTIFICATION")}</div>
              </td>
              <td>{getFactValue(i.machineFacts, "REPO")}</td>
              <td>{getFactValue(i.machineFacts, "ADSITE")}</td>
              <td>{getFactValue(i.machineFacts, "WINACCT")}</td>
              <td>
                {getFactValue(i.machineFacts, "OSNAME")} {getFactValue(i.machineFacts, "OSARCH")}
                <br />
                {getFactValue(i.machineFacts, "OSVER")}
              </td>
              <td>
                {getFactValue(i.machineFacts, "MFR")}
                <br />
                {getFactValue(i.machineFacts, "MODEL")}
              </td>
              <td>{getFactValue(i.machineFacts, "BIOSVER")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default EndpointRuntimeHistoryPage;

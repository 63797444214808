import { useCallback } from "react";
import { useSelector } from "react-redux";
import { NavigateOptions, useNavigate } from "react-router-dom";
import IRootState from "system/types/store/IRootState";

const useAppNavigation = () => {
  const navigate = useNavigate();
  const { currentTenantId } = useSelector((state: IRootState) => state.config);

  return useCallback((to: string, options?: NavigateOptions, ignoreTenant?: boolean) => {
    if (currentTenantId && to.startsWith('/') && !ignoreTenant) {
      navigate(`/${currentTenantId}${to}`, options);
    } else {
      navigate(to, options);
    }
  }, [currentTenantId, navigate]);
};

export default useAppNavigation;
import { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ISearchParams from "system/types/interfaces/ISearchParams";

const useUrlSearch = (): ISearchParams => {
  const { search: searchString } = useLocation();
  const navigate = useNavigate();
  return useMemo<ISearchParams>(() => {
    const searchParams = new URLSearchParams(searchString);
    return {
      get: (name: string) => searchParams.get(name) ?? '',
      set: (name: string, value: string | null) => {
        if (value) {
          searchParams.set(name, value);
        } else {
          searchParams.delete(name);
        }
        navigate(`?${searchParams.toString().replaceAll('%3A', ':')}`);
      },
      setMany: (params: { [key: string]: string | null }) => {
        for (const p in params) {
          if (params[p]) {
            searchParams.set(p, params[p]!);
          } else {
            searchParams.delete(p);
          }
        }

        navigate(`?${searchParams.toString().replaceAll('%3A', ':')}`);
      },
      reset: () => {
        navigate('');
      },
      getSearchString: () => searchParams.toString().replaceAll('%3A', ':')
    };
  }, [navigate, searchString]);
}

export default useUrlSearch;
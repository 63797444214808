import { useMemo, useState } from "react";
import authentication from 'system/libraries/authentication';
import useHttpRequest from "./use-http-request";
import HttpMethods from "system/types/enums/HttpMethods";
import ICvePackage from "system/types/interfaces/ICvePackage";
import ICveResult from "system/types/interfaces/ICveResult";
import ICvePackageData from "system/types/interfaces/ICvePackageData";
import ICveCustomer from "system/types/interfaces/ICveCustomer";

const useApiCve = () => {
  const [auth] = useState(authentication);
  const defaultIcon = '/img/DefaultPackage.png';

  const apiCveUrl = useMemo<string>(() =>
    (process.env.REACT_APP_CVE_FUNCTION_URL ?? ''), []);

  const httpRequest = useHttpRequest(apiCveUrl, auth);

  return useMemo(() => ({
    fetchAllPackages: async (): Promise<ICveResult<ICvePackageData>> => {
      const requestResult = await httpRequest(
        HttpMethods.get,
        '/packages?page-number=1&page-size=999999') as ICveResult<ICvePackageData>;

      return {
        ...requestResult,
        value: {
          ...requestResult.value,
          items: requestResult.value.items.map((x: ICvePackage) => ({
            icon: defaultIcon,
            ...x
          }))
        }
      };
    },
    fetchPackagesByCustomers: async (customerIds: number[]): Promise<ICveResult<ICvePackageData>> => {
      if (!customerIds || customerIds.length < 1) {
        return {
          displayErrors: '',
          errors: [],
          isFailure: false,
          isSuccess: true,
          value: {
            hasNextPage: false,
            hasPreviousPage: false,
            pageNumber: 1,
            totalCount: 0,
            totalPages: 0,
            items: []
          }
        };
      }

      const requestResult = await httpRequest(
        HttpMethods.post,
        '/packages?page-number=1&page-size=50000',
        {
          customerIds
        }) as ICveResult<ICvePackageData>;

      return {
        ...requestResult,
        value: {
          ...requestResult.value,
          items: requestResult.value.items.map((x: ICvePackage) => ({
            icon: defaultIcon,
            ...x
          }))
        }
      };
    },
    fetchCustomers: async (): Promise<ICveResult<ICveCustomer[]>> => {
      return await httpRequest(
        HttpMethods.get, '/customers') as ICveResult<ICveCustomer[]>;
    }
  }), [httpRequest]);
};

export default useApiCve;
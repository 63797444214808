import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    },
    enableCorsCorrelation: true,
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true
  }
});
ai.addTelemetryInitializer(envelope => {
  if(!envelope?.tags){
    return;
  }
  
  envelope.tags["ai.cloud.role"] = process.env.REACT_APP_APP_INSIGHTS_ROLE
    ? process.env.REACT_APP_APP_INSIGHTS_ROLE
    : "AuditorClient";
  
  if (process.env.REACT_APP_APP_INSIGHTS_INSTANCE) {
    envelope.tags["ai.cloud.roleInstance"] = process.env.REACT_APP_APP_INSIGHTS_INSTANCE;
  }
});

if(process.env.REACT_APP_APP_INSIGHTS_KEY){
  ai.loadAppInsights();
}

export { reactPlugin };

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID,
    authority: process.env.REACT_APP_B2C_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_B2C_KNOWN_AUTHORITY],
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  }
};

export const loginRequest = {
  scopes: [process.env.REACT_APP_B2C_REQUEST_SCOPE ?? '']
};
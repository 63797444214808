//File contains display constants

interface StringCollection {
  readonly [index: string]: string;
}

//html colors for charts; keep in sync with style sheet
export const colors: StringCollection = {
  error: '#FAA',
  noncompliant: '#FB6',
  unknown: '#D0E8FF',
  compliant: '#CFC',
};

export const descriptionByFactCode: StringCollection = {
  RUNTIME: 'Runtime',
  REPO: 'Repository',
  WINACCT: 'Account type',
  RUNERR: 'Error',
  ADSITE: 'Site',
  CONFIG: 'Configuration',
  OSNAME: 'OS name',
  OSVER: 'OS ver',
  BITARCH: 'Architecture',
  MFR: 'Manufacturer',
  MODEL: 'Model',
  BOARDSER: 'Serial number',
  BIOSVER: 'BIOS version',
  DNSNAME: "Dns name", 
  COMPUTERNAME: "Computer name", 
  OSARCH: "OS architecture",
  COMPLIANCESTATUS: "State",
  COMPLIANCEJUSTIFICATION: "Justification"
};

export const filterTypes = {
  CONFIG: 1,
  ADSITE: 2,
  MFR: 3,
  MODEL: 4,
  SUBJECT: 5,
  COMPUTER: 6
};
import { useSelector } from "react-redux";
import useAppNavigation from "system/hooks/use-app-navigation";
import IRootState from "system/types/store/IRootState";

const PageTitle = () => {
  const navigate = useAppNavigation();
  const { pageTitle, subTitle, navigateOnTitleClick, navigateOnSubTitleClick } = useSelector(
    (state: IRootState) => state.main);

  if (!pageTitle) {
    return <></>;
  }

  return (
    <div className='dialog-title-wrapper'>
      <h3
        onClick={navigateOnTitleClick ? () => { navigate(navigateOnTitleClick) } : undefined}
        style={{ cursor: navigateOnTitleClick ? 'pointer' : '' }}
      >{pageTitle}</h3>
      <h6
        onClick={navigateOnSubTitleClick ? () => { navigate(navigateOnSubTitleClick) } : undefined}
        style={{ cursor: navigateOnSubTitleClick ? 'pointer' : '' }}
      >{subTitle}&nbsp;</h6>
    </div>
  );
}

export default PageTitle;
import AppLink from "components/ui/AppLink";
import { CheckIcon, CircleDeleteIcon, InfoIcon, QuestionIcon, RefreshIcon, WarningIcon } from "components/ui/Icons";
import SortableTableHeader from "components/ui/SortableTableHeader";
import { getSorter, toDate, toFormatedDate, toLocalISOString, toLocale } from "system/libraries/utilities";
import { SubjectStatusSummary, SubjectStatusTime, SubjectStatusTimeReason } from "system/types/wireTypes";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingPage from "./system/LoadingPage";
import IAppBreadcrumb from "system/types/interfaces/IAppBreadcrumb";
import ActionButtons from "components/ui/ActionButtons";
import EndpointFilter from "components/ui/EndpointFilter";
import ExportToCsvButton from "components/ui/ExportToCsvButton";
import {
  useUrlSearch,
  useMultiFilters,
  usePageNavigation,
  usePageTitle,
  useSearchField,
  useBreadcrumbs,
  useApi,
  useChartRange,
} from "system/hooks";
import TheMainChart from "components/ui/TheMainChart";

const localToLocale = (x: number) => (x > 0 ? toLocale(x) : "-");

const SubjectByReasonPage = () => {
  const api = useApi();
  const { subject } = useParams();
  const { navigateToEndpointsByReason } = usePageNavigation();
  const [data, setData] = useState<SubjectStatusTimeReason[] | null>(null);
  const { sortDesc, sortField } = useSearchField();
  const { machineFilterSet, multiFilterSearchString } = useMultiFilters();
  const [chartData, setChartData] = useState<SubjectStatusTime[] | null>(null);

  const [selectedDateFormatted, setSelectedDateFormatted] = useState<string | null>(null);

  const urlSearch = useUrlSearch();
  const dates = useChartRange();

  const getSelectedDateString = useCallback((): string | null => {
    const paramDate = urlSearch.get("selectedDate");
    if (paramDate) {
      return paramDate;
    }

    if (dates?.length) {
      const resultDate = toLocalISOString(dates[dates.length - 1]);
      return resultDate;
    }

    return null;
  }, [urlSearch, dates]);

  const time = useMemo(() => getSelectedDateString(), [getSelectedDateString]);

  useEffect(() => {
    if (time) {
      setSelectedDateFormatted(toFormatedDate(time.replace("Z", "")));
    }
  }, [time]);

  const refreshHandler = () => {
    setData(null);
    loadSubjectStatusReasonData();
    loadMainChart();
  };

  usePageTitle({
    title: subject ?? "",
    subTitle: `Subject`,
  });

  useBreadcrumbs(
    useMemo<IAppBreadcrumb[]>(() => {
      let result: IAppBreadcrumb[] = [];

      if (time) {
        result.push({
          title: "Time",
          name: toFormatedDate(time),
          url: `/?selectedDate=${time}`,
        });
      }

      if (subject) {
        result.push({
          title: "Subject",
          name: subject,
        });
      }

      return result;
    }, [time, subject])
  );

  const onClickHandler = (message?: string | null, status?: number | null, amount: number | null = null): void => {
    if (!message && status == null) {
      return;
    }

    if (amount == null || amount > 0) {
      navigateToEndpointsByReason(status ?? null, subject, time, multiFilterSearchString, message);
    }
  };

  const loadSubjectStatusReasonData = useCallback(() => {
    if (!subject || !time || !machineFilterSet) {
      return;
    }

    api.summary
      .subjectStatusReason({
        filters: machineFilterSet,
        fromTime: new Date(time),
        subject,
      })
      .then((result) => {
        if (!result?.isSuccess) {
          console.warn(result?.failMessage);
          return;
        }

        setData(result.byReason);
      });
  }, [api, subject, time, machineFilterSet]);

  const loadMainChart = useCallback(() => {
    if (!dates) {
      setChartData(null);
      return;
    }

    api.summary
      .subjectStatus({
        breakoutOption: 0,
        filters: machineFilterSet,
        subject,
        graphBy: "subject",
        referenceTimes: dates.map((x: Date) => x.toISOString()),
      })
      .then((data: SubjectStatusSummary | null) => {
        if (data?.isSuccess && data?.byBreakoutValue && data.byBreakoutValue.length > 0) {
          setChartData(data?.byBreakoutValue[0].byTime);
        } else {
          setChartData(null);
        }
      });
  }, [api.summary, dates, machineFilterSet, subject]);

  useEffect(() => {
    if (!time || !machineFilterSet || !dates) {
      return;
    }

    loadSubjectStatusReasonData();
    loadMainChart();
  }, [dates, loadMainChart, loadSubjectStatusReasonData, machineFilterSet, time]);

  const sortedData = useMemo<SubjectStatusTimeReason[] | null>(() => {
    if (data === null) {
      return null;
    }

    switch (sortField) {
      case "statusMessage":
        return [...data].sort(getSorter("packageMessage", sortDesc));
      case "error":
        return [...data].sort(getSorter("nError", sortDesc));
      case "unknown":
        return [...data].sort(getSorter("nUnknown", sortDesc));
      case "nonCompliant":
        return [...data].sort(getSorter("nNoncompliant", sortDesc));
      case "compliantAfterReboot":
        return [...data].sort(getSorter("nCompliantAfterReboot", sortDesc));
      case "compliant":
        return [...data].sort(getSorter("nCompliant", sortDesc));
      default:
        return [...data]
          .sort(getSorter("nUnknown", true))
          .sort(getSorter("nCompliant", true))
          .sort(getSorter("nCompliantAfterReboot", true))
          .sort(getSorter("nNoncompliant", true))
          .sort(getSorter("nError", true));
    }
  }, [data, sortField, sortDesc]);

  const exportData = useMemo(
    () => ({
      fileName: `Aiden ${subject} Subject ${toDate(time ?? new Date())}`,
      source: sortedData?.map((x) => ({
        StatusMessage: x.packageMessage,
        NotReporting: x.nUnknown,
        HasRecommendations: x.nNoncompliant,
        UpToDatePendingReboot: x.nCompliantAfterReboot,
        UpToDate: x.nCompliant,
      })),
    }),
    [sortedData, subject, time]
  );

  const totalCounts = useMemo(() => {
    const nError = sortedData?.map((x) => x.nError).reduce((x, y) => x + y, 0) ?? 0;
    const nUnknown = sortedData?.map((x) => x.nUnknown).reduce((x, y) => x + y, 0) ?? 0;
    const nNoncompliant = sortedData?.map((x) => x.nNoncompliant).reduce((x, y) => x + y, 0) ?? 0;
    const nCompliantAfterReboot = sortedData?.map((x) => x.nCompliantAfterReboot).reduce((x, y) => x + y, 0) ?? 0;
    const nCompliant = sortedData?.map((x) => x.nCompliant).reduce((x, y) => x + y, 0) ?? 0;
    return {
      nError,
      nUnknown,
      nNoncompliant,
      nCompliantAfterReboot,
      nCompliant,
    };
  }, [sortedData]);

  return sortedData !== null ? (
    <>
      <ActionButtons onRefresh={refreshHandler}>
        <ExportToCsvButton items={[exportData]} />
      </ActionButtons>
      <EndpointFilter />

      {chartData ? (
        <div className="main-chart">
          <div className="card">
            <div className="card-body">
              <TheMainChart data={chartData} selectedGraphBy="subject" />

              {selectedDateFormatted && (
                <div className="summary-number">
                  <div className="d-flex justify-content-between gap-3 align-items-center">
                    <p className="mb-0">
                      <span className="endpoint-head">
                        Endpoints by Status Message&nbsp;
                        <InfoIcon text="A list of endpoints in which a package or AidenBot have evaluated a subject with a given message." />
                      </span>
                    </p>
                    <p className="d-flex flex-column flex-md-row gap-1 gap-md-2 align-items-md-center mb-0">
                      <span className="lh-sm"> Selected date and time</span>
                      <span className="number lh-sm">{selectedDateFormatted}</span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <LoadingPage />
      )}

      <div style={{ marginTop: "10px" }}>
        <div className="machine-table scroll" style={{ maxHeight: "65vh" }}>
          <table className="table table-dialog tb-endpoint">
            <thead className="sticky-top">
              <tr>
                <th className="fit ps-2" scope="col">
                  <SortableTableHeader className="mb-0" title="Status Message" property="statusMessage" />
                </th>
                <th className="status-column text-end">
                  <SortableTableHeader
                    className="text-failed"
                    title={totalCounts.nError.toString()}
                    icon={<CircleDeleteIcon />}
                    property="error"
                    style={{ fontSize: "20px", whiteSpace: "nowrap" }}
                    onClick={() => onClickHandler(null, 20)}
                    disabled={!totalCounts.nError}
                  />
                </th>
                <th className="status-column text-end">
                  <SortableTableHeader
                    className="text-not-reporting"
                    title={totalCounts.nUnknown.toString()}
                    icon={<QuestionIcon />}
                    property="unknown"
                    style={{ fontSize: "20px", whiteSpace: "nowrap" }}
                    onClick={() => onClickHandler(null, 0)}
                    toolTip="Not reporting"
                    disabled={!totalCounts.nUnknown}
                  />
                </th>
                <th className="status-column text-end">
                  <SortableTableHeader
                    className="text-recommended"
                    title={totalCounts.nNoncompliant.toString()}
                    icon={<WarningIcon />}
                    property="nonCompliant"
                    style={{ fontSize: "20px", whiteSpace: "nowrap" }}
                    onClick={() => onClickHandler(null, 5)}
                    toolTip="Has recommendations"
                    disabled={!totalCounts.nNoncompliant}
                  />
                </th>
                <th className="status-column text-end">
                  <SortableTableHeader
                    className="text-pending-reboot"
                    title={totalCounts.nCompliantAfterReboot.toString()}
                    icon={<RefreshIcon />}
                    property="compliantAfterReboot"
                    style={{ fontSize: "20px", whiteSpace: "nowrap" }}
                    onClick={() => onClickHandler(null, 8)}
                    toolTip="Compliant pending reboot"
                    disabled={!totalCounts.nCompliantAfterReboot}
                  />
                </th>
                <th className="status-column text-end">
                  <SortableTableHeader
                    className="text-up-to-date"
                    title={totalCounts.nCompliant.toString()}
                    icon={<CheckIcon />}
                    property="compliant"
                    style={{ fontSize: "20px", whiteSpace: "nowrap" }}
                    onClick={() => onClickHandler(null, 9)}
                    toolTip="Compliant"
                    disabled={!totalCounts.nCompliant}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedData?.map((x, index) => (
                <tr key={index}>
                  <td className="fit ps-2" style={{ minWidth: "180px" }}>
                    <AppLink onClick={() => onClickHandler(x.packageMessage)}>{x.packageMessage}</AppLink>
                  </td>
                  <td className="text-end">
                    <AppLink
                      onClick={() => onClickHandler(x.packageMessage, 20, x.nError)}
                      disabled={!x.nError}
                      className="tb-en text-failed"
                    >
                      {localToLocale(x.nError)}
                    </AppLink>
                  </td>
                  <td className="text-end">
                    <AppLink
                      onClick={() => onClickHandler(x.packageMessage, 0, x.nUnknown)}
                      disabled={!x.nUnknown}
                      className="tb-en text-not-reporting"
                    >
                      {localToLocale(x.nUnknown)}
                    </AppLink>
                  </td>
                  <td className="text-end">
                    <AppLink
                      onClick={() => onClickHandler(x.packageMessage, 5, x.nNoncompliant)}
                      disabled={!x.nNoncompliant}
                      className="tb-en text-recommended"
                    >
                      {localToLocale(x.nNoncompliant)}
                    </AppLink>
                  </td>
                  <td className="text-end">
                    <AppLink
                      onClick={() => onClickHandler(x.packageMessage, 8, x.nCompliantAfterReboot)}
                      disabled={!x.nCompliantAfterReboot}
                      className="tb-en text-pending-reboot"
                    >
                      {localToLocale(x.nCompliantAfterReboot)}
                    </AppLink>
                  </td>
                  <td className="text-end">
                    <AppLink
                      onClick={() => onClickHandler(x.packageMessage, 9, x.nCompliant)}
                      disabled={!x.nCompliant}
                      className="tb-en text-up-to-date"
                    >
                      {localToLocale(x.nCompliant)}
                    </AppLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  ) : (
    <LoadingPage />
  );
};

export default SubjectByReasonPage;

import { Modal, Icon, DefaultButton, PrimaryButton } from "@fluentui/react";
import { ReactNode } from "react";

type ModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onSubmit: () => void;
  onCancel: () => void;
  titleText: string;
  children?: ReactNode;
  isInvalid?: boolean;
};

export const AidenModal = ({ isOpen, onDismiss, onSubmit, onCancel, titleText, children, isInvalid = false }: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onDismiss={(ev) => {
        if (ev && isOpen && ev.type === "keydown" && (ev as unknown as KeyboardEvent).code === "Escape") {
          return;
        }
        onDismiss();
      }}
      isBlocking={true}
      containerClassName="aidenModal"
      allowTouchBodyScroll={true}
    >
      <div className="aidenModal__header">
        <h4 className="aidenModal__header--title">{titleText}</h4>
        <Icon className="aidenModal__header--icon" iconName="Cancel" onClick={onCancel} />
      </div>
      <div className="aidenModal__body">{children}</div>
      <div className="uploadModal__footer">
        <DefaultButton className="uploadModal__footer--cancel" text="Cancel" onClick={onCancel} />
        <PrimaryButton className="uploadModal__footer--confirm" text="Create folder" onClick={onSubmit} disabled={isInvalid} />
      </div>
    </Modal>
  );
};

import { TabPanel } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField, ThemeProvider } from "@mui/material";
import { muiTheme } from "system/styles/muiTheme";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { Icon } from "@fluentui/react";
import { SiteInfo } from "system/types/wireTypes";
import { useApi } from "system/hooks";
import { getSorter } from "system/libraries/utilities";
import SiteForm from "./SiteForm";
import { ConfirmationDialog, SuccessMessage } from "components/ui";

export const SiteTab = () => {
  const [siteList, setSiteList] = useState<SiteInfo[]>([]);
  const [site, setSite] = useState<SiteInfo | null>(null);

  //Customer details Dialog
  const [siteDialogVisible, setSiteDialogVisible] = useState(false);
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [successMessageTitle, setSuccessMessageTitle] = useState("");

  //Search
  const [searchText, setSearchText] = useState("");

  const api = useApi();

  const loadSites = async () => {
    const response = await api.admin.sites();
    setSiteList(response.items ?? []);
  };

  useEffect(() => {
    loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Columns for table in customer
  const customerCol = [
    { field: "siteName", headerName: "Names", minWidth: 200, flex: 1 },
    {
      field: "endpoints",
      headerName: "Endpoints (actual/licensed)",
      minWidth: 150,
      renderCell: (params: GridCellParams) => `${params.row?.actualMachineCount ?? 0} / ${params.row?.licensedMachineCount ?? 0}`,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 200,
      renderCell: (params: GridCellParams) => (
        <>
          <Button variant="contained" onClick={() => startEditSite(params.row)}>
            Edit
            <div className="edit-icon">
              <Icon iconName="edit" />
            </div>
          </Button>
          {false ? (
            <Button
              variant="contained"
              onClick={() => {
                setSite(params.row);
                setConfirmationDialogVisible(true);
              }}
            >
              Delete
            </Button>
          ) : null}
        </>
      ),
    },
  ];

  // Rows for Customer using search bar
  const customerRows = useMemo(() => {
    return [...siteList].sort(getSorter("siteName")).map((site, index) => ({
      id: site.siteId,
      siteId: site.siteId,
      siteName: site.siteName,
      licensedMachineCount: site.licensedMachineCount,
      actualMachineCount: site.actualMachineCount,
    }));
  }, [siteList]);

  const filteredCustRows = useMemo(() => {
    if (!searchText) return customerRows;
    return customerRows.filter((row) => row.siteName.toLowerCase().includes(searchText.toLowerCase()));
  }, [customerRows, searchText]);

  const handleDialogClose = () => {
    setSiteDialogVisible(false);
  };
  const handleDialogCloseEvents = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleDialogClose();
    }
  };
  const handleSiteFormSuccess = () => {
    setSuccessMessageTitle("Site changes saved!");
    handleDialogClose();
    setSuccessMessageVisible(true);
    loadSites();
    setSite(null);
  };
  const startEditSite = (site: SiteInfo) => {
    const siteToEdit = siteList.find((s) => s.siteId === site.siteId);

    if (!siteToEdit) return;
    setSite(siteToEdit);
    setSiteDialogVisible(true);
  };

  return (
    <>
      <TabPanel value="sites">
        <ThemeProvider theme={muiTheme}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "5px" }}>
            <Button className="admin-buttons" variant="contained" onClick={() => setSiteDialogVisible(true)}>
              Create Customer
              <div className="customers-icon">
                <Icon iconName="peopleAdd" />
              </div>
            </Button>
            <TextField
              label="Search"
              variant="filled"
              size="small"
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
              sx={{ width: "300px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ height: "640px", width: "100%" }}>
            <DataGrid
              rows={filteredCustRows}
              columns={customerCol}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 20, 50, 100]}
              disableRowSelectionOnClick
              sx={{
                "& .MuiDataGrid-cell": {
                  borderBottom: "1px solid #424242",
                  backgroundColor: "#3c3c3c",
                  color: "#ffffff",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#1e1e1e",
                  borderBottom: "1px solid #424242",
                  color: "white",
                },
                "& .MuiDataGrid-footerContainer": {
                  backgroundColor: "#1e1e1e",
                  borderTop: "1px solid #424242",
                  color: "white",
                  justifyContent: "center",
                },
                "& .MuiTablePagination-toolbar": {
                  color: "white",
                  alignItems: "baseline",
                  paddingTop: "10px",
                },
              }}
            />
          </div>
        </ThemeProvider>
      </TabPanel>

      <Dialog
        open={siteDialogVisible}
        onClose={handleDialogCloseEvents}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: "#3c3c3c",
            zIndex: 1400,
            maxHeight: "80vh",
            overflow: "auto",
          },
        }}
      >
        <Box mb={1}>
          <DialogTitle sx={{ color: "#ffffff" }}> Customer Details </DialogTitle>
        </Box>
        <DialogContent>
          <SiteForm site={site} done={handleSiteFormSuccess} />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            onClick={() => {
              setConfirmationDialogVisible(true);
            }}
            variant="contained"
            sx={{
              backgroundColor: "#5f6368",
              borderColor: "#5f6368",
              color: "#ffffff",
              marginRight: "8px",
              "&:hover": {
                backgroundColor: "#4a4e54",
                borderColor: "#4a4e54",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            form="site-form"
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#1a73e8",
              borderColor: "#1a73e8",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#1669c1",
                borderColor: "#1669c1",
              },
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        title="Site Modified"
        subText={`Are you sure you want to cancel the operation?`}
        isVisible={confirmationDialogVisible}
        onConfirm={() => {
          setConfirmationDialogVisible(false);
          setSiteDialogVisible(false);
          setSite(null);
        }}
        onCancel={() => {
          setConfirmationDialogVisible(false);
        }}
      />
      <SuccessMessage
        isVisible={successMessageVisible}
        onConfirm={() => {
          setSuccessMessageVisible(false);
        }}
        title={successMessageTitle}
        message={"The site has been updated successfully."}
      />
    </>
  );
};

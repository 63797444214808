import { Icon } from "@fluentui/react";

interface SuccessMessageProps {
  title: string;
  message: string;
  isVisible: boolean;
  onConfirm: () => void;
}

const SuccessMessage = ({ title, message, isVisible, onConfirm }: SuccessMessageProps) => {
  return (
    <div className="successMessage" style={{ display: isVisible ? "flex" : "none" }}>
      <div className="successMessage__content">
        <div className="successMessage__header">
          <h4 className="successMessage__title">{title}</h4>
        </div>
        <div className="successMessage__body">
          <Icon className="successMessage__body--icon" iconName="Accept" />
          <p className="successMessage__body--message">{message}</p>
        </div>
        <div className="successMessage__footer">
          <button type="button" className="successMessage__footer--button" onClick={onConfirm}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;

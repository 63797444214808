import { DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, PrimaryButton } from "@fluentui/react";

interface ConfirmDialogProps {
  title?: string;
  subText?: string;
  isVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationDialog = ({
  onConfirm,
  onCancel,
  isVisible,
  title = "Are you sure?",
  subText = "Are you sure you want to cancel the operation.",
}: ConfirmDialogProps) => {
  const dialogContentProps: IDialogContentProps = {
    type: DialogType.normal,
    title,
    subText,
    styles: {
      title: {
        color: "white",
      },
      subText: {
        color: "#A19F9D",
      },
    },
  };

  return (
    <Dialog
      hidden={!isVisible}
      onDismiss={onCancel}
      dialogContentProps={dialogContentProps}
      modalProps={{
        isBlocking: true,
        styles: {
          main: { maxWidth: 450, backgroundColor: "#323130", color: "white" },
        },
      }}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={onConfirm}
          text="Yes, Continue"
          className="dialog__primary--button"
        />
        <DefaultButton onClick={onCancel} text="No" className="dialog__secondary--button" />
      </DialogFooter>
    </Dialog>
  );
};

export default ConfirmationDialog;

import { useMemo, useState } from "react";
import authentication from "system/libraries/authentication";
import useHttpRequest from "./use-http-request";
import HttpMethods from "system/types/enums/HttpMethods";
import { IDocument } from "system/types/interfaces";
import { ValueResponse } from "system/types/wireTypes";

const useApiDocument = () => {
  const [auth] = useState(authentication);

  const apiUrl = useMemo<string>(() => (process.env.REACT_APP_BACKEND_URL ?? "") + "/api/v1.0/", []);

  const httpRequest = useHttpRequest(apiUrl, auth);

  return useMemo(
    () => ({
      fetchDocumentsByCustomer: async (customerName: string): Promise<ValueResponse<IDocument[]>> => {
        const requestResult = await httpRequest(HttpMethods.get, `document/${customerName}`);

        return requestResult;
      },
      createFolder: async (customerName: string, folderName: string, currentPath: string): Promise<ValueResponse<string>> => {
        return await httpRequest(HttpMethods.post, `document/${customerName}/create-folder`, {
          folderName,
          currentPath,
        });
      },
    }),
    [httpRequest]
  );
};

export default useApiDocument;

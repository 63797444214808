import React, { Suspense, ReactElement, ComponentType } from "react";
import { LoadingPage } from "components/pages/system";

const Loadable =
  <P extends object>(Component: ComponentType<P>): React.FC<P> =>
  (props: P): ReactElement =>
    (
      <Suspense fallback={<LoadingPage />}>
        <Component {...props} />
      </Suspense>
    );

export default Loadable;

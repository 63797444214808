import {
  Announced,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  TextField,
  Text,
  IRenderFunction,
  IDetailsHeaderProps,
  IDetailsRowStyles,
  DetailsHeader,
  DetailsRow,
  IDetailsRowProps,
  IDetailsHeaderStyles,
  IDetailsColumnRenderTooltipProps,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";

import { CSSProperties, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import fluentUiClasses, { fluentUiDarkTheme, fluentUiDetailsListStyles, fluentUiBoldStyle } from "system/styles/fluent-ui-classes";

import { ConnectionErrorPage, LoadingPage } from "./system";
import { getSorter } from "system/libraries/utilities";
import { useApiDocument, useConfiguration, usePageTitle } from "system/hooks";
import { SiteInfo, ValueResponse } from "system/types/wireTypes";
import { IDocument } from "system/types/interfaces";
import { FileIcon, ActionButtons, FileUploadModal, AidenModal, SearchDropdown } from "components/ui";
import { format } from "date-fns";

const commonFolder: SiteInfo = {
  siteId: -1,
  siteName: "Common Customer Documents",
  shortName: process.env.REACT_APP_COMMON_DOCUMENTS_FOLDER ?? "common-customer-documents",
  oldDeleteDays: 0,
  decommissionDays: 0,
  licensedMachineCount: 0,
  licenseDays: 0,
  validDays: 0,
  actualMachineCount: 0,
  isFileModuleEnabled: true,
  isSubjectStatusEnabled: true,
  customerShowLicenseFlag: 0,
  askAidenAccess: 0,
};

const MAX_FOLDER_DEPTH = parseInt(process.env.REACT_APP_MAX_FOLDER_DEPTH || "3", 10);

const DocumentsPage = () => {
  const [documents, setDocuments] = useState<IDocument[] | null>(null);
  const [currentPath, setCurrentPath] = useState<string | null>(null);

  const [selectedCustomer, setSelectedCustomer] = useState<SiteInfo | null>(commonFolder);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
  const [documentSearch, setDocumentSearch] = useState("");
  const [sortedColumnField, setSortedColumnField] = useState("name");
  const [isSortedDescending, setIsSortedDescending] = useState(false);
  const [isFailedToLoad, setIsFailedToLoad] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { tenants, isGlobalAdmin } = useConfiguration();

  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [folderError, setFolderError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [responseError, setResponseError] = useState<string | null>(null);

  usePageTitle({
    title: "Documents",
    subTitle: isGlobalAdmin ? "Aiden Admin" : "Customer",
  });

  const pathDepth = currentPath ? currentPath.split("/").filter(Boolean).length : 0;
  const canCreateNewFolder = pathDepth < MAX_FOLDER_DEPTH;

  const apiDocument = useApiDocument();

  const loadData = useCallback(
    (path = "") => {
      if (selectedCustomer === null) {
        return;
      }
      setResponseError(null);

      setIsDocumentsLoading(true);
      setIsLoaded(false);
      setIsFailedToLoad(false);

      const fullPath = path ? `${selectedCustomer.shortName}/${path}` : selectedCustomer.shortName;
      const requestDocuments = apiDocument.fetchDocumentsByCustomer(fullPath);

      requestDocuments
        .then((data: ValueResponse<IDocument[]>) => {
          if (!data.isSuccess) {
            setDocuments([]);
            setResponseError(data.failMessage);
          } else {
            setDocuments(data.value?.filter((d) => d.name.trim() !== "") ?? []);
          }
          setIsDocumentsLoading(false);
          setIsLoaded(true);
        })
        .catch(() => {
          setIsFailedToLoad(true);
          setIsLoaded(false);
        });
    },
    [selectedCustomer, apiDocument]
  );

  const sortedTenants: SiteInfo[] = useMemo(() => {
    if (!tenants?.length) {
      return [];
    }

    const sorted = [...tenants].sort(getSorter("siteName"));
    sorted.unshift(commonFolder);
    return sorted;
  }, [tenants]);

  const navigateToFolder = useCallback(
    (folderName: string) => {
      setCurrentPath((prevCurrentPath) => {
        const newPath = prevCurrentPath ? `${prevCurrentPath}${folderName}/` : `${folderName}/`;
        loadData(newPath);
        return newPath;
      });
    },
    [loadData]
  );

  const navigateToPath = useCallback(
    (path) => {
      setCurrentPath(path);
      loadData(path);
    },
    [loadData]
  );

  const pathSegments = useMemo(() => {
    const segments = currentPath ? currentPath.split("/").filter(Boolean) : [];
    let pathSoFar = "";
    return segments.map((segment) => {
      pathSoFar += `${segment}/`;
      return {
        name: segment,
        path: pathSoFar,
      };
    });
  }, [currentPath]);

  const filteredDocumentsList = useMemo<IDocument[]>(() => {
    if (!documents || documents.length === 0) {
      return [];
    }

    const folders = documents.filter((doc) => doc.isFolder);
    const files = documents.filter((doc) => !doc.isFolder);

    const sortedFolders = folders
      .filter((x) => x.name.toLowerCase().indexOf(documentSearch.toLowerCase().trim()) !== -1)
      .sort(getSorter(sortedColumnField, isSortedDescending));

    const sortedFiles = files
      .filter((x) => x.name.toLowerCase().indexOf(documentSearch.toLowerCase().trim()) !== -1)
      .sort(getSorter(sortedColumnField, isSortedDescending));

    return [...sortedFolders, ...sortedFiles];
  }, [documents, sortedColumnField, isSortedDescending, documentSearch]);

  const handleCreateNewFolder = useCallback(async () => {
    if (!selectedCustomer) {
      return;
    }

    if (newFolderName.trim().length === 0) {
      setFolderError("Folder name cannot be empty");
      return;
    }

    if (newFolderName.length > 255) {
      setFolderError("The folder name cannot be longer than 255 characters");
      return;
    }

    const existingFolder = filteredDocumentsList?.find(
      (d) => d.isFolder && d.name.toLowerCase().trim() === newFolderName.toLowerCase().trim()
    );
    if (existingFolder) {
      setFolderError("Folder already exists in the current path");
      return;
    }

    const result = await apiDocument.createFolder(selectedCustomer.shortName, newFolderName.trim(), currentPath || "");
    if (result.isSuccess) {
      loadData(currentPath);
      setNewFolderName("");
      setFolderError("");
    } else {
      setFolderError(result.failMessage ?? "");
    }
    setIsCreateFolderModalOpen(false);
  }, [apiDocument, currentPath, filteredDocumentsList, loadData, newFolderName, selectedCustomer]);

  const onColumnClickHandler = useCallback(
    (_: any, column: IColumn) => {
      if (sortedColumnField === column.fieldName) {
        setIsSortedDescending(!isSortedDescending);
        return;
      }

      setSortedColumnField(column.fieldName ?? sortedColumnField);
      setIsSortedDescending(false);
    },
    [sortedColumnField, isSortedDescending, setSortedColumnField, setIsSortedDescending]
  );

  const onItemInvoked = useCallback(
    (item: IDocument): void => {
      if (item.isFolder) {
        navigateToFolder(item.name);
      } else {
        window.open(item.url, "_blank");
      }
    },
    [navigateToFolder]
  );

  const columns = useMemo<IColumn[]>(() => {
    const initialColumns: IColumn[] = [
      {
        key: "icon",
        name: "Icon",
        className: fluentUiClasses.iconCell,
        iconClassName: fluentUiClasses.iconHeader,
        ariaLabel: "Column operations for File type, Press to sort on File type",
        iconName: "Page",
        isIconOnly: true,
        fieldName: "icon",
        minWidth: 22,
        maxWidth: 28,
        onRender: (item: IDocument) => {
          return <FileIcon fileType={item.isFolder ? "folder" : item.contentType} />;
        },
      },
      {
        key: "name",
        name: "File Name",
        fieldName: "name",
        minWidth: 100,
        maxWidth: 450,
        isRowHeader: true,
        isResizable: true,
        isSorted: sortedColumnField === "name",
        isSortedDescending: isSortedDescending && sortedColumnField === "name",
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: onColumnClickHandler,
        data: "string",
        onRender: (item: IDocument) => {
          return (
            <div onClick={() => onItemInvoked(item)} className="documentsLink">
              <span className="text-balanced">{item.name}</span>
            </div>
          );
        },
        isPadded: true,
      },

      {
        key: "type",
        name: "File Type",
        fieldName: "shortContentType",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSorted: sortedColumnField === "shortContentType",
        isSortedDescending: isSortedDescending && sortedColumnField === "shortContentType",
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: onColumnClickHandler,
        data: "string",
        onRender: (item: IDocument) => {
          return <span className="text-balanced">{item.shortContentType}</span>;
        },
        isPadded: true,
      },
      {
        key: "size",
        name: "File Size",
        fieldName: "sizeInBytes",
        minWidth: 70,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSorted: sortedColumnField === "sizeInBytes",
        isSortedDescending: isSortedDescending && sortedColumnField === "sizeInBytes",
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: onColumnClickHandler,
        data: "string",
        onRender: (item: IDocument) => {
          return <span className="text-balanced">{item.isFolder ? "" : item.size}</span>;
        },
        isPadded: true,
      },
      {
        key: "date",
        name: "Date created",
        fieldName: "createdOn",
        minWidth: 130,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onRender: (item: IDocument) => {
          return <span className="text-balanced">{item.createdOn ? format(new Date(item.createdOn), "Pp") : ""}</span>;
        },
        isSorted: sortedColumnField === "createdOn",
        isSortedDescending: isSortedDescending && sortedColumnField === "createdOn",
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: onColumnClickHandler,
        data: "string",

        isPadded: true,
      },
    ];

    return initialColumns;
  }, [sortedColumnField, isSortedDescending, onColumnClickHandler, onItemInvoked]);

  const refreshHandler = useCallback(() => {
    loadData(currentPath);
  }, [currentPath, loadData]);

  const onRenderColumnHeaderTooltipHandler = useCallback((props?: IDetailsColumnRenderTooltipProps) => {
    const customStyles: CSSProperties = {
      color: fluentUiDarkTheme.palette.black,
      backgroundColor: fluentUiDarkTheme.palette.blackTranslucent40,
    };

    if (props) {
      return (
        <span className={props.hostClassName} style={customStyles}>
          {props.children}
        </span>
      );
    }

    return null;
  }, []);

  const onRenderDetailsHeaderHandler = useCallback<IRenderFunction<IDetailsHeaderProps>>(
    (props?: IDetailsHeaderProps) => {
      const customStyles: Partial<IDetailsHeaderStyles> = {};
      if (props) {
        customStyles.root = {
          backgroundColor: fluentUiDarkTheme.palette.blackTranslucent40,
          color: fluentUiDarkTheme.palette.black,
        };

        return (
          <DetailsHeader
            {...props}
            styles={customStyles}
            onRenderColumnHeaderTooltip={onRenderColumnHeaderTooltipHandler}
            theme={fluentUiDarkTheme}
          />
        );
      }

      return null;
    },
    [onRenderColumnHeaderTooltipHandler]
  );

  const onRenderRowHandler = useCallback((props?: IDetailsRowProps) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      customStyles.root = {
        backgroundColor: fluentUiDarkTheme.palette.blackTranslucent40,
      };

      if (props.item.isFolder) {
        customStyles.root = { ...customStyles.root, cursor: "pointer" };
      }

      customStyles.isRowHeader = { ...customStyles.root };
      return <DetailsRow {...props} styles={customStyles} theme={fluentUiDarkTheme} />;
    }

    return null;
  }, []);

  const onCustomerSelectHandler = useCallback(
    (event: React.ChangeEvent<{}>, newValue: { id: number; label: string } | null) => {
      setIsLoaded(false);
      setCurrentPath(null);
      const customer = sortedTenants.find((x) => x.siteId === newValue?.id);
      if (customer && customer !== selectedCustomer) {
        setSelectedCustomer(customer);
      } else {
        loadData();
      }
    },
    [loadData, selectedCustomer, sortedTenants]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <div style={{ color: "var(--text-color)" }}>
        <ActionButtons
          items={[
            {
              title: "Refresh",
              action: refreshHandler,
              icon: "Refresh",
            },
            {
              title: "New Folder",
              action: () => setIsCreateFolderModalOpen(true),
              hidden: (!isGlobalAdmin && selectedCustomer?.siteId === commonFolder.siteId) || !canCreateNewFolder,
              icon: "FabricNewFolder",
            },
            {
              title: "Upload File",
              action: () => setIsModalOpen(true),
              icon: "CloudUpload",
              hidden: !isGlobalAdmin && selectedCustomer?.siteId === commonFolder.siteId,
            },
          ]}
        />
        {isFailedToLoad ? (
          <ConnectionErrorPage />
        ) : (
          <div className="card-body">
            <div className={fluentUiClasses.controlWrapper + " documents__filters"}>
              <TextField
                label="Search Files:"
                onChange={(_, value?: string) => {
                  setDocumentSearch(value ?? "");
                }}
                styles={{
                  root: {
                    maxWidth: "300px",
                    color: "var(--title-color)",
                  },
                }}
                disabled={isDocumentsLoading}
                theme={fluentUiDarkTheme}
              />
              {sortedTenants && (
                <SearchDropdown
                  label="Owner"
                  value={
                    selectedCustomer
                      ? { id: selectedCustomer.siteId, label: selectedCustomer.siteName }
                      : { id: commonFolder.siteId, label: commonFolder.siteName }
                  }
                  onChange={onCustomerSelectHandler}
                  options={sortedTenants.map((x: SiteInfo) => ({
                    id: x.siteId,
                    label: x.siteName,
                  }))}
                  getOptionLabel={(option) => option.label}
                  getOptionSelected={(option, value) => option.id === value.id}
                />
              )}
              <Announced message={`Number of items after filter applied: ${filteredDocumentsList.length}.`} />
            </div>
            {responseError && (
              <MessageBar
                className="ms-motion-fadeIn"
                messageBarType={MessageBarType.error}
                isMultiline={false}
                dismissButtonAriaLabel="Close"
              >
                {responseError}
              </MessageBar>
            )}

            <div className="documents__breadcrumb">
              <span className={`documents__breadcrumb-item ${!currentPath && "active"}`} onClick={() => navigateToPath("")}>
                {selectedCustomer?.siteName || "My files"}
              </span>
              {pathSegments.map((segment, index) => (
                <Fragment key={index}>
                  <span className="documents__breadcrumb-separator"> {">"} </span>
                  <span
                    className={`documents__breadcrumb-item ${index === pathSegments.length - 1 && "active"}`}
                    onClick={() => navigateToPath(segment.path)}
                  >
                    {segment.name}
                  </span>
                </Fragment>
              ))}
            </div>

            {isLoaded && filteredDocumentsList ? (
              <>
                <DetailsList
                  items={filteredDocumentsList}
                  compact={false}
                  columns={columns}
                  selectionMode={SelectionMode.none}
                  className="text-balanced"
                  setKey="none"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  styles={fluentUiDetailsListStyles}
                  onRenderDetailsHeader={onRenderDetailsHeaderHandler}
                  onShouldVirtualize={() => false}
                  onRenderRow={onRenderRowHandler}
                  onItemInvoked={onItemInvoked}
                  theme={fluentUiDarkTheme}
                />
                <div className={fluentUiClasses.controlWrapper} style={{ marginTop: "10px" }}>
                  <Text variant="large" styles={fluentUiBoldStyle}>
                    {`All Files Count: ${documents?.filter((d) => !d.isFolder).length ?? 0}`}
                  </Text>
                </div>
              </>
            ) : (
              <LoadingPage />
            )}
          </div>
        )}
      </div>
      <FileUploadModal
        isOpen={isModalOpen}
        onSuccess={refreshHandler}
        onDismiss={() => {
          setIsModalOpen(false);
        }}
        clientName={isGlobalAdmin ? selectedCustomer?.siteName : undefined}
        clientShortName={selectedCustomer?.shortName ?? ""}
        folderPath={currentPath ?? ""}
      />

      <AidenModal
        isOpen={isCreateFolderModalOpen}
        onDismiss={() => {
          setNewFolderName("");
          setFolderError("");
          setIsCreateFolderModalOpen(false);
        }}
        onSubmit={handleCreateNewFolder}
        onCancel={() => {
          setNewFolderName("");
          setFolderError("");
          setIsCreateFolderModalOpen(false);
        }}
        titleText={"Create New Folder"}
      >
        <input
          type="text"
          value={newFolderName}
          className="aidenModal__body--input"
          max={255}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleCreateNewFolder();
            }
          }}
          onChange={(event) => {
            const { value } = event.target;
            const alphanumericRegex = /^[a-z0-9 ]+$/i;

            if (alphanumericRegex.test(value) || value === "") {
              setNewFolderName(event.target.value);
              setFolderError("");
            } else {
              setFolderError("Please only use letters and numbers");
            }
          }}
          placeholder="Enter folder name"
        />

        <span className="aidenModal__body--error">{folderError}</span>
      </AidenModal>
    </>
  );
};

export default DocumentsPage;

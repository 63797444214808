import { createSlice } from "@reduxjs/toolkit";
import configState from "./config-state";
import configReducers from "./config-reducers";

const configSlice = createSlice({
  name: 'config',
  initialState: configState,
  reducers: configReducers
});

export const configActions = configSlice.actions;

export default configSlice;
// PrivacyPopup.tsx
import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControlLabel, Checkbox, Typography } from "@mui/material";

interface PrivacyPopupProps {
  onAccept: () => void;
}

const PrivacyPopup: React.FC<PrivacyPopupProps> = ({ onAccept }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleAccept = () => {
    if (isChecked) {
      onAccept();
    }
  };

  return (
    <Dialog open={true} maxWidth="md">
      <DialogTitle>Privacy Guidelines</DialogTitle>
      <DialogContent>
        <Typography>
          Your privacy is important to us. Please read and accept our privacy guidelines before using AskAiden.{" "}
          <a href={process.env.REACT_APP_PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />}
          label="I have read and agree to Aiden's Privacy Policy"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept} color="primary" variant="contained" disabled={!isChecked}>
          <b>Accept</b>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrivacyPopup;

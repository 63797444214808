import { useMemo, useState } from "react";
import { IChat, IConversation, IUserfeedback, ISupportTicketResponse, IPrivacyCheck } from "system/types/interfaces/IAskAiden";
import HttpMethods from "system/types/enums/HttpMethods";
import useHttpRequest from "./use-http-request";
import authentication from "system/libraries/authentication";

const useAskAidenApi = () => {
  const [auth] = useState(authentication);

  const apiUrl = useMemo<string>(() => (process.env.REACT_APP_API_ASKAIDEN ?? "") + "/api/v1.0/", []);

  const httpRequest = useHttpRequest(apiUrl, auth);

  return useMemo(
    () => ({
      chat: {
        prompts: async (): Promise<IChat> => {
          return await httpRequest(HttpMethods.get, "chat/staticPrompts");
        },
        conversation: async (requestData: object): Promise<IConversation> => {
          return await httpRequest(HttpMethods.post, "chat/userQuery", requestData);
        },
        feedback: async (userFeedback: object): Promise<IUserfeedback> => {
          return await httpRequest(HttpMethods.post, "chat/userSatisfaction", userFeedback);
        },
        supportTicket: async (ticketData: object): Promise<ISupportTicketResponse> => {
          return await httpRequest(HttpMethods.post, "chat/supportTicket", ticketData);
        },
        privacyCheck: async(userEmail: string):Promise<IPrivacyCheck> =>{
          return await httpRequest(HttpMethods.get,`privacy-policy?email=${userEmail}`,)
        },
        privacyAcceptance: async(userData : object):Promise<IPrivacyCheck> =>{
          return await httpRequest(HttpMethods.put,"privacy-policy",userData)
        }
      },
    }),
    [httpRequest]
  );
};

export default useAskAidenApi;

import AccessDeniedPage from "components/pages/system/AccessDeniedPage";
import ConnectionErrorPage from "components/pages/system/ConnectionErrorPage";
import LoadingPage from "components/pages/system/LoadingPage";
import { useEffect, useState } from "react";
import PageState from "system/types/enums/PageState";
import MainRouter from "./MainRouter";
import useConfigure from "system/hooks/use-configure";
import { Sidebar, TheBreadcrumbs, TheHeader, PageTitle } from "components/ui";

const MainLayout = () => {
  const [pageState, setPageState] = useState<PageState>(PageState.Loading);
  const configure = useConfigure();

  useEffect(() => {
    setPageState(PageState.Loading);
    configure().then((result) => {
      switch (result) {
        case true:
          setPageState(PageState.Loaded);
          break;
        case false:
          setPageState(PageState.AccessDenied);
          break;
        default:
          setPageState(PageState.ConnectionError);
          break;
      }
    });
  }, [configure, setPageState]);

  let componentToLoad = null;
  switch (pageState) {
    case PageState.Loading:
      componentToLoad = <LoadingPage />;
      break;
    case PageState.AccessDenied:
      componentToLoad = <AccessDeniedPage />;
      break;
    case PageState.ConnectionError:
      componentToLoad = <ConnectionErrorPage />;
      break;
    case PageState.Loaded:
      componentToLoad = <MainRouter />;
  }

  return (
    <>
      <TheHeader />
      <div className="content">
        <Sidebar />
        <div className="content__main">
          <TheBreadcrumbs />
          <PageTitle />
          {componentToLoad}
        </div>
      </div>
    </>
  );
};

export default MainLayout;

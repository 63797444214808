// theme.js

import { createTheme } from "@mui/material/styles";

export const darkTheme = createTheme({
  // Dark theme configurations
  typography: {
    fontFamily: [
      'Segoe UI',
    ].join(','),
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#0078D4",
      light: "#004478",
    },
    secondary: {
      main: "#1976D2",
      light: "#004878",
    },
    background: {
      default: "#201F1E", // Updated background color for the dark theme
    },
    text: {
      primary: "#FFFFFF", // Text color for the dark theme
    },
  },
});

import { defaultChartTimespanDays } from "components/ui/EndpointChart";
import { useEffect, useMemo, useState } from "react";
import useUrlSearch from "./use-url-search";

const useChartRange = () => {
  const [dateFromParam, setDateFromParam] = useState<string>();
  const [dateToParam, setDateToParam] = useState<string>();
  const searchParams = useUrlSearch();

  useEffect(() => {
    const dateFromParamUrlValue = searchParams.get('rangeFrom');
    if(dateFromParam !== dateFromParamUrlValue){
      setDateFromParam(dateFromParamUrlValue);
    }

    const dateToParamUrlValue = searchParams.get('rangeTo');
    if(dateToParam !== dateToParamUrlValue){
      setDateToParam(dateToParamUrlValue);
    }
  }, [dateFromParam, setDateFromParam, dateToParam, setDateToParam, searchParams]);

  return useMemo<Date[] | null>(() => {
    if(dateFromParam === undefined || dateToParam === undefined){
      return null;
    }
    
    const fromUrl = dateFromParam && dateToParam;
    const currentDate = new Date();
    const endTime = fromUrl
      ? Date.parse(dateToParam)
      : currentDate.getTime();

    const startTime = fromUrl
      ? Date.parse(dateFromParam)
      : currentDate.setDate(currentDate.getDate() - defaultChartTimespanDays)

    const datePoints: Date[] = [];
    const step = (endTime - startTime) / 15;
    for (let timeItem = startTime; timeItem <= endTime; timeItem += step) {
      datePoints.push(new Date(timeItem));
    }

    return datePoints;
  }, [dateFromParam, dateToParam]);
};

export default useChartRange;
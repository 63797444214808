import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import useUrlSearch from "system/hooks/use-url-search";
import ISortableTableHeaderProps from "system/types/interfaces/ISortableTableHeaderProps";
import AppLink from "./AppLink";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import { Tooltip } from "react-bootstrap";

const SortableTableHeader = ({ title, className, property, icon, style, onClick, toolTip, disabled }: ISortableTableHeaderProps) => {
  const location = useLocation();
  const { setMany: setManySearch } = useUrlSearch();
  const queryParams = new URLSearchParams(location.search);
  const sortField = queryParams.get('sort');
  const sortDesc = queryParams.get('desc') === '1';

  const clickHandler = useCallback(() => {
    if (!property) {
      return;
    }

    if (sortField === property && sortDesc) {
      setManySearch({
        sort: null,
        desc: null
      });
    } else if (sortField === property) {
      setManySearch({
        sort: property,
        desc: '1'
      });
    } else {
      setManySearch({
        sort: property,
        desc: null
      });
    }
  }, [setManySearch, property, sortField, sortDesc]);

  const iconClassName = !!property
    ? (sortField === property
      ? (sortDesc ? 'clickable bi bi-arrow-down' : 'clickable bi bi-arrow-up')
      : 'clickable bi bi-arrow-down-up')
    : null;

  const spanClassName = className
    ? className
    : '';

  const linkStyle = {
    ...style,
    display: 'inline'
  }

  const content = <span
    className={spanClassName}
    style={style}
  >
    <AppLink onClick={(disabled ? () => {} : (onClick ?? clickHandler))} style={linkStyle} disabled={disabled} className={spanClassName}>{icon}{icon && ' '}{title} </AppLink>
    {iconClassName && <i className={iconClassName} onClick={clickHandler}></i>}
  </span>;

  return (toolTip
    ? <OverlayTrigger
      key='top'
      placement='top'
      overlay={<Tooltip id={`tooltip-top`}>{toolTip}</Tooltip>}
    >{content}</OverlayTrigger>
    : content);
}

export default SortableTableHeader;
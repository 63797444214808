// AskAidenCard.js

import React, { useCallback, useEffect, useState, useRef } from "react";
import parse from 'html-react-parser'
import { darkTheme } from "./theme";
import {
  Box,
  TextField,
  Stack,
  Button,
  Typography,
  Avatar,
  Paper,
  IconButton,
  ThemeProvider,
  InputAdornment,
  Menu,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import SendIcon from "@mui/icons-material/Send";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { aidenBot, profile, activeUp, hoverUp, activeDown, hoverDown } from "./assets";
import PrivacyPopup from "./privacyPopup";
import { useApiAskAiden } from "system/hooks";
import useConfiguration from "system/hooks/use-configuration";
import { LoadingPage } from "components/pages/system";
import AskAidenAccess from "system/types/enums/AskAidenAccess";
import { useSelector } from "react-redux";
import IRootState from "system/types/store/IRootState";
import { IMessageType } from "system/types/interfaces";
import "./styles/ask-aiden.css"; // Import the CSS file
import {CitationReferencesComponent} from "./CitationReferencesComponent"

const AskAidenCard = () => {
  const { userInfo } = useSelector((state: IRootState) => state.config);
  // State hooks
  const [messages, setMessages] = useState<IMessageType[]>([]);
  const [thumbsDownMessages, setThumbsDownMessages] = useState<{ msgId: number; text: string }[]>([]);
  const [searchTerm, setSearhTerm] = useState<string>("");
  const [isSending, setIsSending] = useState<boolean>(false);
  const [freshdeskMessageDisplayed, setFreshdeskMessageDisplayed] = useState<number>(0);
  const [networkError, setNetworkError] = useState<{ text: string; showResend: boolean } | null>(null);
  const [hasInputAccess, setInputAccess] = useState<boolean>(false);
  const [showPrivacyPopup, setShowPrivacyPopup] = useState<boolean>(false);
  const [chatId, setChatID] = useState<number | null>(null);
  const [userConcern, setUserConcern] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const userEmail = userInfo?.email as string;

  // Refs
  const chatBoxRef = useRef<HTMLDivElement>(null);

  // Custom hooks
  const api = useApiAskAiden();
  const { currentTenantId, isAskAidenEnabled } = useConfiguration();

  const generateDefaultMessageText = useCallback((inputAccess: boolean) => {
    if (inputAccess) {
      return `Hello, and welcome to AskAiden (Beta). I’m an intelligent chatbot assistant trained on Aiden resources to answer questions on how I can help you improve digital end-user experience, cyber hygiene, and efficiency. 
              Feel free to type your question below. 
              Alternatively, you can choose a topic from the below prompts. For more options, click the drop-down to view more. 
              If you wish to clear the conversation and start over, click the 'Start Over' button in the upper right-hand corner of this window. 
              I’m still in training, so please use the Thumbs Up/Down buttons to provide feedback which will help me to improve my answers. 
              For my responses that need improvement, you’ll have the option to raise a support ticket with the ability to provide more feedback, and an expert will reach out to you.`;
    } else {
      return `Hello, and welcome to AskAiden (Beta). I’m an intelligent chatbot assistant trained on Aiden resources to answer questions on how I can help you improve digital end-user experience, cyber hygiene, and efficiency. 
              I’m still in training, so please use the Thumbs Up/Down buttons to provide feedback which will help me to improve my answers. 
              For my responses that need improvement, you’ll have the option to raise a support ticket with the ability to provide more feedback, and an expert will reach out to you. 
              What’s on your mind? 
              To start a conversation, please choose a topic from the below prompts. For more options, click the drop-down to view more. 
              If you wish to clear the conversation and start over, click the 'Start Over' button in the upper right-hand corner of this window. 
              I’m still in training, so please use the Thumbs Up/Down buttons to provide feedback which will help me to improve my answers. 
              For my responses that need improvement, you’ll have the option to raise a support ticket with the ability to provide more feedback, and an expert will reach out to you.`;
    }
  }, []);

  // Function to open the Menu
  const handleMenuOpen = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const target = event.currentTarget;
    if (target instanceof SVGSVGElement) {
      setAnchorEl(target);
    }
  };

  // Function to close the Menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Function to fetch random questions list from an API
  const randomQuestionsList = useCallback(async () => {
    try {
      const apiResponse = await api.chat.prompts();
      return apiResponse?.data || [];
    } catch (e) {
      return [];
    }
  }, [api]);

  // Function to handle privacy acceptance
  const handlePrivacyAccept = async () => {
    const userData = {
      email: userEmail,
      isPrivacyPolicyAccepted: true,
    };
    const apiResponse = await api.chat.privacyAcceptance(userData);
    if (apiResponse) setShowPrivacyPopup(false);
  };

  // Function to add default message with random questions to the chat
  const addDefaultMessage = useCallback(async () => {
    const defaultMessageText = generateDefaultMessageText(hasInputAccess);
    const questionsList = await randomQuestionsList();
    const questions = questionsList.map((data) => {
      return data.Question;
    });
    const updatedDefaultMessage: IMessageType = {
      id: 1,
      text: defaultMessageText,
      sender: "bot",
      buttons: questions.map((question, index) => ({
        id: index + 1,
        text: question.toString(),
      })),
    };
    setPageLoading(false);
    setMessages([updatedDefaultMessage]);
  }, [generateDefaultMessageText, hasInputAccess, randomQuestionsList, setPageLoading, setMessages]);

  // Function to copy a message to the clipboard
  const handleCopyToClipboard = (message: IMessageType) => {
    const citations = message.citations
    let clipboardText = message.text
    if (citations) {
      let citationReferencesClipboard = "Learn More: \n";
      citations.forEach((citation, index)=> {
        const citationHTML = `<sup class='citation-superscript'>${index + 1}</sup>`
        clipboardText = clipboardText.replaceAll(citationHTML, `[${index + 1}]`)
        citationReferencesClipboard += `${index + 1}. ${citation.url}\n`
      })

      clipboardText += `\n\n${citationReferencesClipboard}`
    }
    navigator.clipboard.writeText(clipboardText);
    const updatedMessages = messages.map((msg) => (msg.id === message.id ? { ...msg, isCopied: true } : msg));
    setMessages(updatedMessages);
  };

  const replaceCitationPlaceHoldersWithHTML = (assistantResponse: string, citationLength: number) => {
    for (let i = 1; i <= citationLength; i++) {
      assistantResponse = assistantResponse.replaceAll(`[doc${i}]`, `<sup class='citation-superscript'>${i}</sup>`)
    }
    return assistantResponse
  }

  // Function to handle user input and send a message to the API
  const handleSend = async (userMessage: string, resend = false) => {
    if (userMessage.trim() !== "") {
      setIsSending(true);
      handleMenuClose();
      setShowLoading(true);

      try {
        // Prepare user message
        if (!resend) {
          const newUserMessage: IMessageType = {
            id: messages.length + 1,
            text: userMessage,
            sender: "user",
          };
          setMessages((prevMessages: IMessageType[]) => [...prevMessages, newUserMessage]);
        }

        for (const msg of messages) {
          if (msg.sender === "freshdesk") {
            msg.freshdeskButton = true;
          }
        }

        const tenantId = currentTenantId?.toString();

        // Prepare data for API request
        const requestData = {
          chatId: chatId,
          customerId: tenantId,
          prompt: userMessage,
        };

        // Make API request
        const apiResponse = await api.chat.conversation(requestData);

        // Extract the assistant's response from the API
        let assistantResponse = apiResponse?.data[0]?.assistantMessage
        const citations = apiResponse?.citations
        assistantResponse = replaceCitationPlaceHoldersWithHTML(assistantResponse, citations.length)
        const messageId = messages.length + 2
        citations.forEach((citation, index) => {
          citation.citationId = `${messageId}-${index + 1}`
        })
        
        const completionId = apiResponse?.data[0]?.completionId?.toString();

        const chatNewId = apiResponse?.data[0]?.chatId;
        setChatID(chatNewId);

        // Prepare bot message with the assistant's response
        const botMessage: IMessageType = {
          id: messageId,
          text: assistantResponse.toString(),
          sender: "bot",
          completionId,
          citations: citations,
          isCopied: false,
          thumbs: {
            up: false,
            down: false,
          },
        };
        setShowLoading(false);
        setMessages((prevMessages: IMessageType[]) => [...prevMessages, botMessage]);

        const questionsList = await randomQuestionsList();
        const questions = questionsList.map((data) => {
          return data.Question;
        });

        const nextQuestionStarter: IMessageType = {
          id: messages.length + 2,
          text: "Anything else I can help you with today?",
          sender: "nextQuestion",
          buttons: questions.map((question, index) => ({
            id: index + 1,
            text: question.toString(), // Ensure text is of type string
          })),
        };
        setMessages((prevMessages: IMessageType[]) => [...prevMessages, nextQuestionStarter]);
        setSearhTerm("");
      } catch (error) {
        console.error("Error sending message to the API:", error);
        // Handle network error
        setNetworkError({
          text: "Network error occurred. Unable to send the message.",
          showResend: true,
        });
        setSearhTerm("");
      } finally {
        setIsSending(false);
      }
    }
  };

  // Function to handle input change in the search bar
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearhTerm(event.target.value);
  };

  // Function to clear the chat and add a default message
  const handleClear = async () => {
    setMessages([]);
    await addDefaultMessage();
    setChatID(null);
    setNetworkError(null);
    setUserConcern("");
  };

  // Function to handle thumbs down for a bot message
  const handleThumbsDown = async (messageId: number) => {
    const thumbsDownMessage = messages.find((message) => message.sender === "bot" && message.id === messageId);
    if (thumbsDownMessage) {
      const newDownMessage = {
        msgId: messageId,
        text: thumbsDownMessage?.text,
      };

      thumbsDownMessage.thumbs = { up: false, down: true };

      setThumbsDownMessages((thumbsDownMessages) => [...thumbsDownMessages, newDownMessage]);

      const updatedMessages = messages.map((msg) => (msg.id === messageId ? { ...msg, thumbs: { up: false, down: true } } : msg));

      setMessages(updatedMessages);

      const userFeedback = {
        completionId: thumbsDownMessage.completionId,
        userSatisfaction: -1,
      };

      await api.chat.feedback(userFeedback);
    }
  };

  // Function to handle thumbs up for a bot message
  const handleThumbsUp = async (messageId: number) => {
    const messgeToUpdate = messages.find((msg) => msg.id === messageId);
    if (messgeToUpdate) {
      const updatedMessages = messages.map((msg) =>
        msg.id === messageId ? { ...msg, thumbs: { ...msg.thumbs, up: true, down: false } } : msg
      );

      const downMessage = thumbsDownMessages.find((msg) => msg.msgId === messageId);
      if (downMessage) {
        const removeMessage = thumbsDownMessages.filter((msg) => msg.msgId !== messageId);
        setThumbsDownMessages(removeMessage);
      }

      // Update the state with the modified array
      setMessages(updatedMessages);

      const userFeedback = {
        completionId: messgeToUpdate.completionId,
        userSatisfaction: 1,
      };

      await api.chat.feedback(userFeedback);
    }
  };

  // Function to handle "Yes" button click for Freshdesk integration
  const handleFreshDeskYesButton = async (messageId: number) => {
    // Handle the action when the "Yes" button is clicked
    if (thumbsDownMessages.length >= 3) {
      setMessages((prevMessages) => [...prevMessages]);
      setIsSending(true);
      const freshdesk = await freshdeskResponse(messageId);
      if (freshdesk === true) {
        setThumbsDownMessages([]);
      }
      setIsSending(false);
    }
  };

  // Function to integrate with Freshdesk and raise a support ticket
  const freshdeskResponse = async (messageId: number) => {
    try {
      const lastBotMessage = messages
        .slice()
        .reverse()
        .find((message) => message.sender === "bot");

      const completionId = lastBotMessage ? lastBotMessage.completionId : null;
      const freshdeskMessageUpdate = messages.map((msg) => (msg.id === messageId ? { ...msg, freshdeskButton: true } : msg));

      // Prepare data for API request
      const ticketData = {
        chatId,
        completionId,
        name: userEmail,
        email: userEmail,
        notes: userConcern,
      };

      // Make API request
      const apiResponse = await api.chat.supportTicket(ticketData);

      if (apiResponse.statusCode === 200) {
        // Success case
        setMessages(freshdeskMessageUpdate);

        const freshdeskMessageReply: IMessageType = {
          id: messages.length + 1,
          text: `Thank you for raising a support ticket. Our team will get back to you shortly.`,
          sender: "nextQuestion",
          userInput: userConcern,
        };

        const questionsList = await randomQuestionsList();
        const questions = questionsList.map((data) => data.Question);

        const nextQuestionStarter: IMessageType = {
          id: messages.length + 2,
          text: "Anything else I can help you with today?",
          sender: "nextQuestion",
          buttons: questions.map((question, index) => ({
            id: index + 1,
            text: question.toString(),
          })),
        };

        setMessages((prevMessages: IMessageType[]) => [...prevMessages, freshdeskMessageReply, nextQuestionStarter]);
        setUserConcern("");
        return true;
      } else {
        // Handle other status codes
        const errorMessage = "Technical error. Please retry or try again after a while.";
        const errorBotMessage: IMessageType = {
          id: messages.length + 2,
          text: errorMessage,
          sender: "nextQuestion",
        };
        const questionsList = await randomQuestionsList();
        const questions = questionsList.map((data) => data.Question);

        const nextQuestionStarter: IMessageType = {
          id: messages.length + 2,
          text: "Anything else I can help you with today?",
          sender: "nextQuestion",
          buttons: questions.map((question, index) => ({
            id: index + 1,
            text: question.toString(),
          })),
        };

        setMessages((prevMessages: IMessageType[]) => [...prevMessages, errorBotMessage, nextQuestionStarter]);
      }
    } catch (error) {
      // Handle network or unexpected errors
      console.error("Error making API request:", error);
      const errorMessage = "Technical error. Please retry or try again after a while.";
      const errorBotMessage: IMessageType = {
        id: messages.length + 2,
        text: errorMessage,
        sender: "nextQuestion",
      };
      setMessages((prevMessages: IMessageType[]) => [...prevMessages, errorBotMessage]);
      return false;
    }
  };

  // Function to handle "No" button click for Freshdesk integration
  const handleFreshDeskNoButton = async (messageId: number) => {
    setIsSending(true);
    const freshdeskMessageUpdate = messages.map((msg) => (msg.id === messageId ? { ...msg, freshdeskButton: true } : msg));
    setMessages(freshdeskMessageUpdate);

    const questionsList = await randomQuestionsList();
    const questions = questionsList.map((data) => {
      return data.Question;
    });

    const nextQuestionStarter: IMessageType = {
      id: messages.length + 2,
      text: "Anything else I can help you with today?",
      sender: "nextQuestion",
      buttons: questions.map((question, index) => ({
        id: index + 1,
        text: question.toString(),
      })),
    };

    setMessages((prevMessages: IMessageType[]) => [...prevMessages, nextQuestionStarter]);
    setUserConcern("");
    setIsSending(false);
  };

  // Function to handle input change for user concerns
  const handleInputConcern = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setUserConcern(event.target.value);
  };

  // Effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        let defaultMessageText = generateDefaultMessageText(false);

        if (isAskAidenEnabled === AskAidenAccess.FullAccess) {
          setInputAccess(true);
          defaultMessageText = generateDefaultMessageText(true);
        }

        const questionsList = await randomQuestionsList();
        const questions = questionsList.map((data) => data.Question);

        const updatedDefaultMessage: IMessageType = {
          id: 1,
          text: defaultMessageText,
          sender: "bot",
          buttons: questions.map((question, index) => ({
            id: index + 1,
            text: question.toString(),
          })),
        };

        setPageLoading(false);
        setMessages([updatedDefaultMessage]);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [isAskAidenEnabled, randomQuestionsList, generateDefaultMessageText, setPageLoading, setMessages]);

  useEffect(() => {
    const botThumbsDownMessages = messages.filter(
      (message) =>
        message.sender === "bot" &&
        message.thumbs?.down === true &&
        thumbsDownMessages.some((thumbsDownMessage) => thumbsDownMessage.msgId === message.id)
    );

    const downCount = botThumbsDownMessages.length;
    const additionalFreshdeskMessages = Math.max(0, downCount - 2);

    if (downCount < 3) {
      setFreshdeskMessageDisplayed(0);
    }

    if (additionalFreshdeskMessages > freshdeskMessageDisplayed) {
      const updatedMessages = messages.map((msg) => (msg.sender === "freshdesk" ? { ...msg, freshdeskButton: true } : msg));

      const freshdeskMessage: IMessageType = {
        id: messages.length + 1,
        text: `You have shown dissatisfaction in my previous responses. Would you like to raise a support ticket?\n\nMessages:\n${botThumbsDownMessages
          .map((message, index) => `${index + 1}. ${message.text}`)
          .join("\n")}`,
        sender: "freshdesk",
        freshdeskButton: false,
      };

      setMessages([...updatedMessages, freshdeskMessage]);
      setFreshdeskMessageDisplayed(additionalFreshdeskMessages);
    }
  }, [thumbsDownMessages, messages, freshdeskMessageDisplayed]);

  useEffect(() => {
    // Scroll to the bottom of the chat box when messages are updated
    if (chatBoxRef.current && chatBoxRef.current.lastChild) {
      // Check if there are more than 2 thumbs down messages from the bot
      const downCount = messages.filter(
        (message) =>
          message.sender === "bot" &&
          message.thumbs?.down === true &&
          thumbsDownMessages.some((thumbsDownMessage) => thumbsDownMessage.msgId === message.id)
      ).length;

      const isButtonClick =
        document.activeElement && (document.activeElement.tagName === "BUTTON" || document.activeElement.tagName === "ICONBUTTON");

      // Find the last message index that meets the condition
      let lastIndex = -1;
      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i].sender === "bot" || messages[i].sender === "user" || messages[i].sender === "freshdesk") {
          lastIndex = i;
          break;
        }
      }

      // Check if loading is shown or a button is clicked multiple times
      if ((!isButtonClick || downCount > 2) && lastIndex !== -1) {
        const lastMessage = messages[lastIndex];
        if (lastMessage.sender === "bot") {
          const messageElement = chatBoxRef.current.children[lastIndex];
          if (messageElement) {
            messageElement.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        } else {
          // Scroll to the bottom for messages not from the user
          chatBoxRef.current.scrollTo({ top: chatBoxRef.current.scrollHeight, behavior: "smooth" });
        }
      }
    }
  }, [messages, thumbsDownMessages]);

  useEffect(() => {
    const policyAcceptanceCheck = async (userEmail: string) => {
      try {
        const apiResponse = await api.chat.privacyCheck(userEmail);
        if (apiResponse.data.privacyPolicyAccepted === true) {
          setShowPrivacyPopup(false);
        } else {
          setShowPrivacyPopup(true);
        }
      } catch (e) {
        setShowPrivacyPopup(true);
      }
    };

    policyAcceptanceCheck(userEmail);
  }, [api, userEmail]);

  // JSX structure
  return (
    <ThemeProvider theme={darkTheme}>
      {showPrivacyPopup && <PrivacyPopup onAccept={handlePrivacyAccept} />}
      {!pageLoading ? (
        <>
          <Stack className="Stack" direction="row" spacing={1}>
            <Typography
              style={{
                fontSize: "28px",
                fontWeight: 400,
                color: "#fff",
              }}
            >
              <h3>AskAiden</h3>
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button variant="contained" style={{ backgroundColor: "#76C6ED", color: "white" }} disabled={isSending} onClick={handleClear}>
                Start Over
              </Button>
            </Stack>
          </Stack>
          <br />
          <Box className="MainBox">
            <Box
              ref={chatBoxRef}
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                p: 2,
                maxHeight: "70vh",
                position: "relative",
              }}
            >
              {messages.map((message) => (
                <Box
                  key={`${message.id}_${message.sender}`}
                  sx={{
                    display: "flex",
                    justifyContent:
                      message.sender === "bot" ||
                      message.sender === "freshdesk" ||
                      message.sender === "nextQuestion" ||
                      message.sender === "promptSelection"
                        ? "flex-start"
                        : "flex-end",
                    mb: 2,
                    marginLeft:
                      message.sender === "freshdesk" || message.sender === "nextQuestion" || message.sender === "promptSelection"
                        ? "40px"
                        : "0",
                  }}
                >
                  {message.sender === "freshdesk" && (
                    <Box className="FreshdeskBox">
                      <Paper
                        variant="outlined"
                        sx={{
                          p: 2,
                          backgroundColor: "transparent",
                          borderRadius: "6px",
                          border: "1px solid",
                          borderColor: "#76C6ED",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <Typography variant="body1" sx={{ color: "#C8C6C4", lineHeight: 1.8 }}>
                            {message?.text?.split("\n").map((line, index) => (
                              <React.Fragment key={index}>
                                {parse(line)}
                                <br />
                              </React.Fragment>
                            ))}
                          </Typography>
                        </div>
                        <br />
                        <Box sx={{ marginTop: "5px" }}>
                          <InputLabel shrink={true} htmlFor="concerns-input">
                            <span style={{ color: "#FFF", fontWeight: "500", fontSize: "20px" }}>
                              <b>{message.freshdeskButton ? message.userInput : "Type your concerns here"}</b>
                            </span>
                          </InputLabel>
                          <TextField
                            id="concerns-input"
                            variant="outlined"
                            multiline
                            rows={4}
                            value={message.freshdeskButton ? message.userInput : userConcern}
                            margin="normal"
                            fullWidth
                            disabled={message.freshdeskButton}
                            autoComplete="new-password"
                            onChange={(e) => handleInputConcern(e)}
                          />
                        </Box>
                      </Paper>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            display: "flex",
                            borderRadius: "2px",
                            gap: "10px",
                            backgroundColor: "#eff6fc",
                            color: "black",
                            marginRight: "10px",
                          }}
                          disabled={message.freshdeskButton || isSending}
                          onClick={() => handleFreshDeskYesButton(message.id)}
                        >
                          Submit
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            display: "flex",
                            borderRadius: "2px",
                            gap: "10px",
                            backgroundColor: "#eff6fc",
                            color: "black",
                            marginRight: "10px",
                          }}
                          disabled={message.freshdeskButton || isSending}
                          onClick={() => handleFreshDeskNoButton(message.id)}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {message.sender === "nextQuestion" && (
                    <Box className="FreshdeskBox">
                      <Paper
                        variant="outlined"
                        sx={{
                          p: 2,
                          backgroundColor: "transparent",
                          borderRadius: "6px",
                          border: "1px solid",
                          borderColor: "#76C6ED",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <Typography variant="body1" sx={{ color: "#C8C6C4", lineHeight: 1.8 }}>
                            {message?.text?.split("\n").map((line, index) => (
                              <React.Fragment key={index}>
                                {parse(line)}
                                <br />
                              </React.Fragment>
                            ))}
                          </Typography>
                        </div>
                      </Paper>
                      <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" sx={{ marginTop: "10px" }}>
                        {message.buttons &&
                          message.buttons.length > 0 &&
                          message.buttons.slice(0, 3).map((button) => (
                            <Button
                              key={button.id}
                              variant="contained"
                              sx={{
                                display: "flex",
                                borderRadius: "2px",
                                gap: "10px",
                                backgroundColor: "#eff6fc",
                                color: "black",
                                marginRight: "10px",
                                textTransform: "none",
                              }}
                              onClick={() => {
                                handleSend(button.text);
                              }}
                              disabled={isSending}
                            >
                              {button.text}
                            </Button>
                          ))}
                        {message.buttons && message.buttons.length > 3 && (
                          <>
                            <ArrowDropDownIcon
                              color="inherit"
                              sx={{
                                fontSize: 40,
                                backgroundColor: "#eff6fc",
                                "&:hover": {
                                  backgroundColor: "#0078D4",
                                  cursor: "pointer",
                                },
                              }}
                              onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                                console.log("menu");
                                handleMenuOpen(e);
                              }}
                            />
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleMenuClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              autoFocus={false}
                            >
                              {message.buttons.slice(3).map((button) => (
                                <MenuItem
                                  key={button.id}
                                  disabled={isSending}
                                  onClick={() => handleSend(button.text)}
                                  style={{ width: "35vw", textWrap: "wrap" }}
                                >
                                  {button.text}
                                </MenuItem>
                              ))}
                            </Menu>
                          </>
                        )}
                      </Stack>
                    </Box>
                  )}
                  {message.sender === "promptSelection" && (
                    <Box className="FreshdeskBox">
                      <Paper
                        variant="outlined"
                        sx={{
                          p: 2,
                          backgroundColor: "transparent",
                          borderRadius: "6px",
                          border: "1px solid",
                          borderColor: "#76C6ED",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <Typography component="div" variant="body1" sx={{ color: "#C8C6C4", lineHeight: 1.8}}>
                            {message?.text?.split("\n").map((line, index) => (
                              <React.Fragment key={index}>
                                {parse(line)}
                                <br />
                                <CitationReferencesComponent citations={message.citations}/>
                              </React.Fragment>
                            ))}
                          </Typography>
                        </div>
                      </Paper>
                    </Box>
                  )}
                  {message.sender !== "freshdesk" && message.sender !== "nextQuestion" && message.sender !== "promptSelection" && (
                    <Box className="FreshdeskBox">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: message.sender === "bot" ? "row" : "row-reverse",
                          alignItems: "flex-start",
                        }}
                      >
                        <Avatar
                          sx={{
                            bgcolor: message.sender === "bot" ? "primary.main" : "black",
                            width: "30px",
                            height: "30px",
                            marginTop: "5px",
                            marginRight: "5px",
                          }}
                        >
                          <img
                            src={message.sender === "bot" ? aidenBot : profile}
                            alt={message.sender === "bot" ? "Bot Profile" : "User Profile"}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Avatar>
                        <Paper
                          variant="outlined"
                          sx={{
                            p: 2,
                            ml: message.sender === "bot" ? 1 : 0,
                            mr: message.sender === "bot" ? 0 : 1,
                            backgroundColor: "transparent",
                            borderRadius: "6px",
                            border: "1px solid",
                            borderColor: message.sender === "bot" ? "#76C6ED" : "#72F1A9",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <Typography component="div" variant="body1" sx={{ color: "#C8C6C4", lineHeight: 1.8}}>
                              <strong>
                                <span style={{ color: "#FFFFFF" }}>{message.sender === "bot" ? "Aiden AI" : "You"}</span>
                              </strong>
                              <br />
                              {message?.text?.split("\n").map((line, index) => (
                                <React.Fragment key={index}>
                                  {parse(line)}
                                  <br />
                                </React.Fragment>
                              ))}
                              {message.sender === "bot" ? <CitationReferencesComponent citations={message.citations}/> : <></>}
                            </Typography>
                          </div>
                          {message.sender === "bot" && message.id !== 1 && (
                            <div className="iconButtonWrapper">
                              {message.isCopied ? (
                                <IconButton color="inherit" className="doneIcon">
                                  <DoneRoundedIcon />
                                </IconButton>
                              ) : (
                                <IconButton color="inherit" className="copyIcon" onClick={() => handleCopyToClipboard(message)}>
                                  <ContentCopyRoundedIcon />
                                </IconButton>
                              )}
                              <IconButton
                                color="inherit"
                                onClick={() => {
                                  handleThumbsUp(message.id);
                                }}
                              >
                                <img
                                  src={message?.thumbs?.up === true ? activeUp : hoverUp}
                                  alt="thumbs Icon"
                                  style={{ width: "20px", height: "20px" }}
                                />
                              </IconButton>
                              <IconButton
                                color="inherit"
                                onClick={() => {
                                  handleThumbsDown(message.id);
                                }}
                              >
                                <img
                                  src={message?.thumbs?.down === true ? activeDown : hoverDown}
                                  alt="thumbs Icon"
                                  style={{ width: "20px", height: "20px" }}
                                />
                              </IconButton>
                            </div>
                          )}
                        </Paper>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          marginTop: "10px",
                          marginLeft: "42px",
                        }}
                      >
                        {message.sender === "bot" && message.id === 1 && (
                          <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                            {message.buttons &&
                              message.buttons.length > 0 &&
                              message.buttons.slice(0, 3).map((button) => (
                                <Button
                                  key={button.id}
                                  variant="contained"
                                  sx={{
                                    display: "flex",
                                    borderRadius: "2px",
                                    gap: "10px",
                                    backgroundColor: "#eff6fc",
                                    color: "black",
                                    marginRight: "10px",
                                    textTransform: "none",
                                  }}
                                  onClick={() => {
                                    handleSend(button.text);
                                  }}
                                  disabled={isSending}
                                >
                                  {button.text}
                                </Button>
                              ))}
                            {message.buttons && message.buttons.length > 3 && (
                              <>
                                <ArrowDropDownIcon
                                  color="inherit"
                                  sx={{
                                    fontSize: 40,
                                    backgroundColor: "#eff6fc",
                                    "&:hover": {
                                      backgroundColor: "#0078D4",
                                      cursor: "pointer",
                                    },
                                  }}
                                  onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                                    handleMenuOpen(e);
                                  }}
                                />
                                <Menu
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleMenuClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                  autoFocus={false}
                                >
                                  {message.buttons.slice(3).map((button) => (
                                    <MenuItem
                                      key={button.id}
                                      disabled={isSending}
                                      onClick={() => handleSend(button.text)}
                                      style={{ width: "35vw", textWrap: "wrap" }}
                                    >
                                      {button.text}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </>
                            )}
                          </Stack>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
              {!networkError && showLoading && (
                <Box
                  className="FreshdeskBox"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "primary.main",
                      width: "30px",
                      height: "30px",
                      marginTop: "5px",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src={aidenBot}
                      alt={"Bot Profile"}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Avatar>
                  <ThreeDots visible={true} height="50" width="50" color="#fff" radius="10" ariaLabel="three-dots-loading" />
                </Box>
              )}
            </Box>
            {/* Display network error message */}
            {networkError && (
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "background.default",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                {networkError.text}
                <br />
                {networkError.showResend && (
                  <Button
                    variant="contained"
                    className="Button"
                    onClick={() => {
                      handleSend(messages[messages.length - 1]?.text || "", true);
                      setNetworkError(null); // Clear the error after resend
                    }}
                    disabled={isSending}
                  >
                    Regenrate
                  </Button>
                )}
              </Box>
            )}
            <Box sx={{ p: 2, backgroundColor: "background.default" }}>
              {hasInputAccess && (
                <TextField
                  className="inputTextField"
                  size="small"
                  fullWidth
                  placeholder="Enter here..."
                  variant="outlined"
                  autoComplete="new-password"
                  value={searchTerm}
                  onChange={(e) => handleInputChange(e)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleSend(searchTerm);
                    }
                  }}
                  disabled={isSending}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className="inputButton"
                          onClick={() => handleSend(searchTerm)}
                          disabled={isSending || searchTerm.trim() === ""}
                        >
                          <SendIcon className="inputButton" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: "placeholderText",
                  }}
                />
              )}
            </Box>
          </Box>
        </>
      ) : (
        <LoadingPage />
      )}
    </ThemeProvider>
  );
};

export default AskAidenCard;

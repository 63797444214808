import { TabPanel } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ThemeProvider, Tooltip } from "@mui/material";
import { Icon } from "@fluentui/react/lib/Icon";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import { muiTheme } from "system/styles/muiTheme";
import { useEffect, useState } from "react";
import { AccessEntryInfo } from "system/types/wireTypes";
import { getSorter } from "system/libraries/utilities";
import { useApi } from "system/hooks";
import AccessForm from "./AccessForm";
import { ConfirmationDialog, SuccessMessage } from "components/ui";

export const AccessTab = () => {
  const [membershipLoading, setMembershipLoading] = useState(true);
  // Access state
  const [accessList, setAccessList] = useState<AccessEntryInfo[]>([]);

  //Grant Access Dialog
  const [accessDialogVisible, setAccessDialogVisible] = useState(false);
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [successMessageTitle, setSuccessMessageTitle] = useState("");

  const [userSelected, setUserSelected] = useState<AccessEntryInfo | null>(null);

  const api = useApi();

  const loadAccessList = async () => {
    setMembershipLoading(true);
    const response = await api.access.getAcl();
    setAccessList((response ?? []).sort(getSorter("role")).sort(getSorter("name")));
    setMembershipLoading(false);
  };

  useEffect(() => {
    loadAccessList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Columns for table in Access
  const accessCol = [
    { field: "name", headerName: "Name", flex: 1, minWidth: 150 },
    { field: "email", headerName: "Email", flex: 2, minWidth: 200 },
    { field: "role", headerName: "Role", flex: 1, minWidth: 150 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params: GridCellParams) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              setUserSelected(params.row);
              setConfirmationDialogVisible(true);
            }}
            style={{ backgroundColor: "#d54c52", color: "white" }}
          >
            Revoke
            <div className="delete-icon">
              <Icon iconName="delete" />
            </div>
          </Button>
        );
      },
    },
  ];

  const accessRows = [...accessList].sort(getSorter("name")).map((entry, index) => ({
    id: `${entry.userId}_${entry.role}`, // Keep the unique id as is
    userId: entry.userId, // Include userId explicitly
    groupId: entry.groupId, // Include groupId if needed
    name: entry.name,
    email: entry.email,
    role: entry.role,
  }));

  const handleAccessDialogClose = () => {
    setAccessDialogVisible(false);
  };

  const handleAccessDialogCloseEvents = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleAccessDialogClose();
    }
  };

  const handleConfirmRevokeAccess = async () => {
    if (!userSelected) return;

    const result = await api.access.revoke(userSelected);

    if (result.isSuccess) {
      setAccessList((oldList) => {
        return oldList.filter((entry) => !(entry.userId === userSelected?.userId && entry.groupId === userSelected?.groupId));
      });
      setUserSelected(null);
      setConfirmationDialogVisible(false);
      setSuccessMessageTitle("Access revoked successfully");
      handleAccessDialogClose();
      setSuccessMessageVisible(true);
    }
  };

  const handleAccessFormSuccess = (user: AccessEntryInfo) => {
    setSuccessMessageTitle("Access granted successfully");
    handleAccessDialogClose();
    setSuccessMessageVisible(true);
    setAccessList((oldList) => {
      return [...oldList, user];
    });
  };

  return (
    <>
      <TabPanel value="users">
        <div className="admin-buttons">
          <Button className="grantAccess" variant="contained" onClick={() => setAccessDialogVisible(true)}>
            Grant Access
            <div className="permissions-icon">
              <Icon iconName="Permissions" />
            </div>
          </Button>
          &nbsp;
          <Button className="refresh" variant="outlined" onClick={() => loadAccessList()}>
            Refresh
            <div className="refresh-icon">
              <Icon iconName="refresh" />
            </div>
          </Button>
          <div className="hint-container">
            <Tooltip title="Permission to each email address that should have access." placement="top-end">
              <div className="hint-icon">
                <IconButton aria-label="hint">
                  <Icon iconName="info" style={{ color: "white" }} />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        </div>

        {membershipLoading ? (
          <div>Loading</div>
        ) : (
          <ThemeProvider theme={muiTheme}>
            <div style={{ height: "640px", width: "100%" }}>
              <DataGrid
                rows={accessRows}
                columns={accessCol}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 20, 50, 100]}
                disableRowSelectionOnClick
                sx={{
                  "& .MuiDataGrid-cell": {
                    borderBottom: "1px solid #424242",
                    backgroundColor: "#3c3c3c",
                    color: "#ffffff",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#1e1e1e",
                    borderBottom: "1px solid #424242",
                    color: "white",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    backgroundColor: "#1e1e1e",
                    borderTop: "1px solid #424242",
                    color: "white",
                    justifyContent: "center",
                  },
                  "& .MuiTablePagination-toolbar": {
                    color: "white",
                    alignItems: "baseline",
                    paddingTop: "10px",
                  },
                }}
              />
            </div>
          </ThemeProvider>
        )}
      </TabPanel>
      <Dialog
        open={accessDialogVisible}
        onClose={handleAccessDialogCloseEvents}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: "#3c3c3c",
            zIndex: 1400,
            maxHeight: "80vh",
            overflow: "auto",
          },
        }}
      >
        <Box mb={1}>
          <DialogTitle sx={{ color: "#ffffff" }}> Access Details </DialogTitle>
        </Box>
        <DialogContent>
          <AccessForm done={handleAccessFormSuccess} />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            onClick={handleAccessDialogClose}
            variant="contained"
            sx={{
              backgroundColor: "#5f6368",
              borderColor: "#5f6368",
              color: "#ffffff",
              marginRight: "8px",
              "&:hover": {
                backgroundColor: "#4a4e54",
                borderColor: "#4a4e54",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            form="AccessForm"
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#1a73e8",
              borderColor: "#1a73e8",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#1669c1",
                borderColor: "#1669c1",
              },
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        title="Revoke Access"
        subText={`Are you sure you want to revoke ${userSelected?.role} access from ${userSelected?.name}?`}
        isVisible={confirmationDialogVisible}
        onConfirm={handleConfirmRevokeAccess}
        onCancel={() => {
          setConfirmationDialogVisible(false);
          setUserSelected(null);
        }}
      />
      <SuccessMessage
        isVisible={successMessageVisible}
        onConfirm={() => {
          setSuccessMessageVisible(false);
        }}
        title={successMessageTitle}
        message={"Your changes have been saved."}
      />
    </>
  );
};

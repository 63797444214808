import React, { useCallback, useMemo, useState } from "react";
import { Icon } from "@fluentui/react/lib/Icon";
import useAppNavigation from "system/hooks/use-app-navigation";
import { useConfiguration, useResponsive } from "system/hooks";
import { useLocation } from "react-router-dom";
import { registerIcons } from "@fluentui/style-utilities";
import { AskAidenIcon } from "components/modules/ask-aiden/assets";
import AskAidenAccess from "system/types/enums/AskAidenAccess";
import { Tooltip } from "@mui/material";

interface IMenuItem {
  key: string;
  title: string;
  icon: string;
  route?: string;
  url?: string;
  visible: boolean;
}

// Registering custom icon to Fluent icons
registerIcons({
  icons: {
    AskAiden: <img src={AskAidenIcon} alt="AskAidenIcon" style={{ width: 21, height: 31.5 }} />,
  },
});

export const Sidebar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { isGlobalAdmin, isFileModuleEnabled, isAskAidenEnabled, hasAdminPermission } = useConfiguration();
  const { toggleMobileSidebarVisibility, isMobileSidebarVisible } = useResponsive();

  const navigate = useAppNavigation();
  const location = useLocation();

  const menuItems = useMemo<IMenuItem[]>(() => {
    const allItems: IMenuItem[] = [
      {
        key: "dashboard",
        title: "Dashboard",
        icon: "Home",
        route: "/dashboard",
        visible: true,
      },
      {
        key: "packages",
        title: "Packages",
        icon: "AllApps",
        route: "/packages",
        visible: isGlobalAdmin,
      },
      {
        key: "documents",
        title: "Documents",
        icon: "DocLibrary",
        route: "/documents",
        visible: isFileModuleEnabled,
      },
      {
        key: "support",
        title: "Support",
        icon: "QandA",
        url: process.env.REACT_APP_SUPPORT_URL,
        visible: !!process.env.REACT_APP_SUPPORT_URL,
      },
      {
        key: "askAiden",
        title: "AskAiden",
        icon: "AskAiden",
        route: "/askaiden",
        visible: isAskAidenEnabled !== AskAidenAccess.NoAccess,
      },
      {
        key: "Admin",
        title: "Admin",
        icon: "admin",
        route: "/admin",
        visible: hasAdminPermission,
      },
    ];

    return allItems.filter((x) => x.visible);
  }, [isGlobalAdmin, isFileModuleEnabled, isAskAidenEnabled, hasAdminPermission]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleItemClick = (item: IMenuItem) => {
    if (item.route) {
      navigate(item.route);
    } else if (item.url) {
      window.open(item.url, "_blank");
    }
  };

  const getActiveItemIndex = useCallback(() => {
    const activeIndex = menuItems.findIndex((item) => {
      if (!item.route) return false;

      return location.pathname.includes(item.route);
    });
    return activeIndex >= 0 ? activeIndex : 0;
  }, [menuItems, location.pathname]);

  const activeItemIndex = useMemo(() => getActiveItemIndex(), [getActiveItemIndex]);

  const isActive = useCallback(
    (index: number) => {
      return index === activeItemIndex;
    },
    [activeItemIndex]
  );

  return (
    <>
      <div className={`sidebar ${collapsed ? "sidebar--collapsed" : "sidebar--expanded"}`}>
        <Icon
          iconName={collapsed ? "DoubleChevronRight12" : "DoubleChevronLeft12"}
          className="sidebar__toggle-button"
          onClick={toggleCollapsed}
        />
        <ul className="sidebar__items">
          {menuItems.map((item, index) => (
            <li
              key={item.key}
              className={`sidebar__item ${isActive(index) ? "sidebar__item--active" : ""}`}
              onClick={() => handleItemClick(item)}
            >
              {!collapsed ? (
                <span>
                  <Icon iconName={item.icon} className="sidebar__item--icon" />
                </span>
              ) : (
                <Tooltip title={item.title} placement="right">
                  <span>
                    <Icon iconName={item.icon} className="sidebar__item--icon" />
                  </span>
                </Tooltip>
              )}
              {!collapsed && <span className="sidebar__item--title">{item.title}</span>}
            </li>
          ))}
        </ul>
      </div>

      {/* Mobile sidebar */}
      <div className={`mobile-sidebar ${isMobileSidebarVisible ? "mobile-sidebar--visible" : ""}`}>
        <ul className="mobile-sidebar__items">
          {menuItems &&
            menuItems.map((item, index) => (
              <li
                key={item.key}
                className={`mobile-sidebar__item ${isActive(index) ? "mobile-sidebar__item--active" : ""}`}
                onClick={() => {
                  handleItemClick(item);
                  toggleMobileSidebarVisibility();
                }}
              >
                <Icon iconName={item.icon} className="mobile-sidebar__item--icon" />
                <span className="mobile-sidebar__item--title">{item.title}</span>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

import { FormikErrors, useFormik } from "formik";
import { useState } from "react";
import { Button } from "react-bootstrap";
import useApi from "system/hooks/use-api";
import { toLocalFilenameDateString, toLocalISOString } from "system/libraries/utilities";

interface IReport {
  reportName: string;
  content: string;
};

interface IReportFrom {
  dateTime: string;
};

const AdminReports = () => {
  const api = useApi();
  const [generatedReports, setGeneratedReports] = useState<IReport[]>([]);
  const form = useFormik<IReportFrom>({
    initialValues: {
      dateTime: toLocalISOString(new Date(new Date().setHours(0, 0, 0, 0)))
    },
    validate: values => {
      const errors: FormikErrors<IReportFrom> = {};
      try {
        new Date(values.dateTime);
      } catch {
        errors.dateTime = "Correct date time value is required";
      }

      return errors;
    },
    onSubmit: async values => {
      const fileBlob = await api.report.get(new Date(values.dateTime));
      if (fileBlob && fileBlob.size > 0) {
        setGeneratedReports([
          {
            reportName: `report-${toLocalFilenameDateString(new Date(values.dateTime))}.csv`,
            content: window.URL.createObjectURL(fileBlob)
          },
          ...generatedReports
        ]);
      }
    }
  });

  return (
    <div>
      <form className="a-form" onSubmit={form.handleSubmit}>
        <label>Date</label>
        <input
          name="dateTime"
          type="datetime-local"
          value={form.values.dateTime}
          onChange={form.handleChange}
          style={{ width: '300px' }}
        />
        {form.errors.dateTime && <span className="warning">{form.errors.dateTime}</span>}
        <div className="button-bar">
          <Button disabled={form.isSubmitting} onClick={() => form.submitForm()}>Submit</Button>
          {generatedReports.length > 0 && <Button variant="secondary" onClick={() => setGeneratedReports([])}>Clear reports</Button>}
        </div>
      </form>
      {generatedReports.map((x, i) => <div key={i}>
        <a role="button" href={x.content} download={x.reportName}>{x.reportName}</a>
      </div>)}
    </div>
  );
}

export default AdminReports;
import { addDays, format, parse } from "date-fns";
import MachineFact from "system/types/interfaces/MachineFact";
import { MachineFilterSet, MultiFilter, SubjectStatus, SubjectStatusAtTime, SubjectStatusTime } from "system/types/wireTypes";
import { filterTypes } from "system/libraries/constants";

export const getFactValue = (facts: MachineFact[] | undefined | null, code: string | undefined | null): string | null => {
  if (!facts || !code) {
    return null;
  }

  const filteredResult = facts.filter((x) => x.code === code);
  if (filteredResult.length < 1) {
    return null;
  }

  return filteredResult[0].value;
};

export const getFactDescription = (facts: MachineFact[] | undefined | null, code: string | undefined | null): string | null => {
  if (!facts || !code) {
    return null;
  }

  const filteredResult = facts.filter((x) => x.code === code);
  if (filteredResult.length < 1) {
    return null;
  }

  return filteredResult[0].description;
};

//convert date to format required by input controls
export function dateToInputControlFormat(d: Date) {
  return format(d, "yyyy-MM-dd");
}

export const encodeUriString = (s?: string | null): string => {
  if (!s) {
    return "";
  }

  return encodeURIComponent(s.replace(":", "%3A"));
};

export const decodeUriString = (s?: string | null): string | null => {
  if (!s) {
    return null;
  }

  return s.replace("%2F", "/").replace("%3A", ":");
};

//convert value from a date input control to a local date at midnight
export function inputControlToDate(s: string) {
  return parse(s, "yyyy-MM-dd", new Date());
}

//convert date range to array of strings with API formatting, 15 entries
export function dateRangeToAllTimesArray(fromDate: Date, toDate: Date): string[] {
  //build out 15 intermediate dates in millis
  const millis = [];
  const fromMillis = fromDate.getTime();
  const duration = toDate.getTime() - fromMillis;
  const span = duration / 14;
  for (let i = 0; i < 15; ++i) millis.push(fromMillis + i * span);

  //convert to API formatted times
  const times = millis.map((n) => new Date(n).toISOString());
  return times;
}

export function filterTypeToString(type: number): string {
  if (type === 1) return "Configuration";
  if (type === 2) return "Site";
  if (type === 3) return "Manufacturer";
  if (type === 4) return "Model";
  if (type === 5) return "Subject";
  if (type === 6) return "Computer";
  return "?";
}

export function stringToFilterType(type: string): number {
  for (let i = 0; i < 10; ++i) {
    if (filterTypeToString(i) === type) {
      return i;
    }
  }

  return 0;
}

export function ComplianceStatusToString(status: number | string | null, singular = false): string {
  const complianceStatus = typeof status === "string" ? +status : status;
  if (complianceStatus === 9) return "Compliant";
  if (complianceStatus === 8) return "Compliant pending reboot";
  if (complianceStatus === -1) return "Not reporting";
  if (complianceStatus === 5) return singular ? "Recommended" : "Has recommendations";
  if (complianceStatus === 1) return "Failed";
  if (complianceStatus === 20) return "Critical";
  return "?";
}

//convert array of filters (as used in the UI for selecting filters) to the format required by API data loading calls
export function multiFiltersToAPIFilters(filters: MultiFilter[]): MachineFilterSet {
  return {
    configurationName: filters.find((f) => f.filterType === filterTypes.CONFIG)?.filterValue ?? null,
    adSite: filters.find((f) => f.filterType === filterTypes.ADSITE)?.filterValue ?? null,
    hardwareMfr: filters.find((f) => f.filterType === filterTypes.MFR)?.filterValue ?? null,
    hardwareModel: filters.find((f) => f.filterType === filterTypes.MODEL)?.filterValue ?? null,
    subject: filters.find((f) => f.filterType === filterTypes.SUBJECT)?.filterValue ?? null,
    computerName: filters.find((f) => f.filterType === filterTypes.COMPUTER)?.filterValue ?? null,
  };
}

//convert a set of filters to a readable string
export function multiFitlersToDisplay(filters: MultiFilter[]): string {
  if (!filters.length) return "(none)";
  return filters.map((f) => `${f.filterValue} (${filterTypeToString(f.filterType)})`).join(", ");
}

//sorting comparison function that puts the worst counts at the top
export function badnessComparer(a: SubjectStatusTime, b: SubjectStatusTime): number {
  const abad = a.nNoncompliant + a.nError,
    bbad = b.nNoncompliant + b.nError;
  const aunk = a.nUnknown,
    bunk = b.nUnknown;
  if (abad > bbad) return -1;
  if (abad < bbad) return 1;
  if (aunk > bunk) return -1;
  if (aunk < bunk) return 1;
  return 0;
}

//convert PackageRan code to display string
export function packageRanToDisplay(code: number): string {
  if (code === 3) return "Success (complete)";
  else if (code === 2) return "Success to reboot";
  else if (code === 1) return "Attempted and failed";
  return "Not attempted";
}

//convert assessmentStatus code to display string
export function assessmentStatusToDisplay(code: number): string {
  if (code === 1) return "Not Evaluated";
  else if (code === 2) return "Not Applicable";
  else if (code === 3) return "Optional";
  else if (code === 4) return "Recommended";
  else if (code === 5) return "Mandatory";
  return "Error checking assessment";
}

//sorting comparison function for PackageRan field; sorts in this order: 1, 2, 3, 0
export function packageRanComparer(a: number, b: number) {
  //treat 0 as 4 so it sorts by worst outcome first
  const a2 = a === 0 ? 4 : a;
  const b2 = b === 0 ? 4 : b;
  return a2 - b2;
}

export function stateComparer(leftState: number, rightState: number) {
  const left = leftState === 7 ? 20 : leftState;
  const right = rightState === 7 ? 20 : rightState;
  return left - right;
}

function subjectComparer(a: SubjectStatus, b: SubjectStatus): number {
  if (!a.subject || !b.subject) return 0;
  return a.subject.localeCompare(b.subject);
}

function subjectBadnessComparer(a: SubjectStatus, b: SubjectStatus): number {
  const comp = packageRanComparer(a.packageRan, b.packageRan);
  if (comp !== 0) return comp;
  if (!a.subject || !b.subject) return 0;
  return a.subject.localeCompare(b.subject);
}

function subjectComputerNameComparer(a: SubjectStatus, b: SubjectStatus): number {
  const comp = packageRanComparer(a.packageRan, b.packageRan);
  if (comp !== 0) return comp;
  if (!a.computerName || !b.computerName) return 0;
  return a.computerName.localeCompare(b.computerName);
}

function computerNameAndStateComparer(a: SubjectStatusAtTime, b: SubjectStatusAtTime): number {
  const comp = stateComparer(a.complianceStatus, b.complianceStatus);
  if (comp !== 0) return comp;
  if (!a.computerName || !b.computerName) return 0;
  return a.computerName.localeCompare(b.computerName);
}

function subjectAndStateComparer(a: SubjectStatusAtTime, b: SubjectStatusAtTime): number {
  const comp = stateComparer(a.complianceStatus, b.complianceStatus);
  if (comp !== 0) return comp;
  if (!a.subject || !b.subject) return 0;
  return a.subject.localeCompare(b.subject);
}

export function copyAndSortSubj(sortByColNo: number, rows: SubjectStatusAtTime[]): SubjectStatusAtTime[] {
  const sortedRows = [...rows];
  if (sortByColNo === 0) sortedRows.sort(subjectComparer);
  else if (sortByColNo === 1) sortedRows.sort(subjectBadnessComparer);
  else if (sortByColNo === 2) sortedRows.sort((a, b) => computerNameAndStateComparer(a, b));
  else if (sortByColNo === 3) sortedRows.sort((a, b) => subjectAndStateComparer(a, b));
  else if (sortByColNo === 4) sortedRows.sort(subjectComputerNameComparer);
  return sortedRows;
}

function errorComparer(a: SubjectStatusTime, b: SubjectStatusTime, isAscending: boolean): number {
  if (isAscending) return a.nError - b.nError;

  return b.nError - a.nError;
}

function unknownComparer(a: SubjectStatusTime, b: SubjectStatusTime, isAscending: boolean): number {
  if (isAscending) return a.nUnknown - b.nUnknown;

  return b.nUnknown - a.nUnknown;
}

function noncompliantComparer(a: SubjectStatusTime, b: SubjectStatusTime, isAscending: boolean): number {
  if (isAscending) return a.nNoncompliant - b.nNoncompliant;

  return b.nNoncompliant - a.nNoncompliant;
}

function compliantAfterRebootComparer(a: SubjectStatusTime, b: SubjectStatusTime, isAscending: boolean): number {
  if (isAscending) return a.nCompliantAfterReboot - b.nCompliantAfterReboot;

  return b.nCompliantAfterReboot - a.nCompliantAfterReboot;
}

function compliantComparer(a: SubjectStatusTime, b: SubjectStatusTime, isAscending: boolean): number {
  if (isAscending) return a.nCompliant - b.nCompliant;

  return b.nCompliant - a.nCompliant;
}

export function toLocalFilenameDateString(date: Date): string {
  let result = date.getFullYear().toString();
  result += (date.getMonth() + 1).toString().padStart(2, "0");
  result += date.getDate().toString().padStart(2, "0");
  result += "-";
  result += date.toTimeString().substring(0, 8).replaceAll(":", "");
  return result;
}

export function toLocalISOString(date: Date) {
  let result = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
  return result.substring(0, result.indexOf("."));
}

export function toLocalISOStrings(dates: Date[]): string[] {
  if (!dates) {
    return [];
  }

  return dates.map((date: Date) => toLocalISOString(date) + "Z");
}

export function copyAndSortSubjectStatusTime<T extends SubjectStatusTime>(
  sortByColNo: number,
  rows: T[],
  isAscending: boolean,
  baseComparer: (left: T, right: T, isAscending: boolean) => number
): T[] {
  const sortedRows = [...rows];
  if (sortByColNo === 0) sortedRows.sort((a, b) => baseComparer(a, b, isAscending));
  else if (sortByColNo === 1)
    sortedRows.sort((a, b) => {
      const result = errorComparer(a, b, isAscending);
      return result === 0 ? baseComparer(a, b, isAscending) : result;
    });
  else if (sortByColNo === 2)
    sortedRows.sort((a, b) => {
      const result = unknownComparer(a, b, isAscending);
      return result === 0 ? baseComparer(a, b, isAscending) : result;
    });
  else if (sortByColNo === 3)
    sortedRows.sort((a, b) => {
      const result = noncompliantComparer(a, b, isAscending);
      return result === 0 ? baseComparer(a, b, isAscending) : result;
    });
  else if (sortByColNo === 4)
    sortedRows.sort((a, b) => {
      const result = compliantAfterRebootComparer(a, b, isAscending);
      return result === 0 ? baseComparer(a, b, isAscending) : result;
    });
  else if (sortByColNo === 5)
    sortedRows.sort((a, b) => {
      const result = compliantComparer(a, b, isAscending);
      return result === 0 ? baseComparer(a, b, isAscending) : result;
    });
  else sortedRows.sort(badnessComparer);
  return sortedRows;
}

export function isFactUnknown(facts: MachineFact[] | undefined | null, name: string): boolean {
  if (!facts) {
    return false;
  }

  return facts.find((x) => x.code === name)?.isUnknown ?? false;
}

const statusDictionary: { [name: string]: number } = {
  failed: 1,
  critical: 20,
  notReporting: 0,
  hasRecommendations: 5,
  pendingReboot: 8,
  compliant: 9,
};

export function statusToString(status: number): string | null {
  for (const name in statusDictionary) {
    if (statusDictionary[name] === status) {
      return name;
    }
  }

  return null;
}

export function statusToNumber(status?: string | null): number | null {
  if (!status) {
    return null;
  }

  for (const name in statusDictionary) {
    if (name.toLocaleLowerCase() === status.toLocaleLowerCase()) {
      return statusDictionary[name];
    }
  }

  return null;
}

export function camelPad(str: string): string {
  return str
    .replace(/([A-Z]+)([A-Z][a-z])/g, " $1 $2")
    .replace(/([a-z\d])([A-Z])/g, "$1 $2")
    .replace(/([a-zA-Z])(\d)/g, "$1 $2")
    .replace(/^./, function (str: string) {
      return str.toUpperCase();
    })
    .trim();
}

//get displayable permission set
/*export function permissionsToDisplay(permissions: string): string {
  const words: string[] = [];
  if (permissions.includes('V')) words.push('View');
  if (permissions.includes('U')) words.push('Manage users');
  if (permissions.includes('C')) words.push('Configure site');
  if (permissions.includes('G')) words.push('Multi-site admin');
  return words.join(', ');
}*/

export function toLocale(value: number) {
  if (!Number.isInteger(value)) return "";

  return value.toLocaleString("en-US");
}

export function toFormatedDate(date: string): string {
  if (!date) {
    return "";
  }

  try {
    return format(new Date(date), "yyyy-MM-dd HH:mm");
  } catch {
    return "";
  }
}

export function toDate(date?: string | Date): string {
  if (!date) {
    return "";
  }

  try {
    return format(new Date(date), "yyyy-MM-dd");
  } catch {
    return "";
  }
}

export const normalizeString = (s?: string): string => {
  if (!s) {
    return "";
  }

  const result = s.replace(/([A-Z])/g, " $1").trim();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

function sorterFunction(field: string, desc: boolean, left: any, right: any) {
  const leftValue = isNaN(left[field]) ? left[field].toString().toLowerCase() : left[field];
  const rightValue = isNaN(right[field]) ? right[field].toString().toLowerCase() : right[field];

  if (leftValue < rightValue || (leftValue === null && rightValue !== null)) {
    return desc ? 1 : -1;
  }

  if (leftValue > rightValue || (leftValue !== null && rightValue === null)) {
    return desc ? -1 : 1;
  }

  return 0;
}

export function getSorter(field: string, descending: boolean = false) {
  return sorterFunction.bind(null, field, descending);
}

export interface DefaultTime {
  tomorrow: Date;
  startTime: Date;
  times: string[];
}

export function getDefaultTimes(): DefaultTime {
  const tomorrow = new Date();
  const startTime = addDays(tomorrow, -90);
  const defaultTimes = dateRangeToAllTimesArray(startTime, tomorrow);
  return {
    tomorrow,
    startTime,
    times: defaultTimes,
  } as DefaultTime;
}

import { CommandBarButton } from "@fluentui/react";
import TimespanButton from "./TimespanButton";

interface IButtonProps {
  title: string;
  icon: string;
  action: () => void;
  hidden?: boolean;
}

interface IProps {
  timespanButton?: boolean;
  items?: IButtonProps[];
  onRefresh?: () => void;
  children?: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
}

const ActionButtons = ({ timespanButton, items, onRefresh, children }: IProps) => {

  return (
    <div className="bdr-bottom">
      {onRefresh && <CommandBarButton iconProps={{ iconName: "Refresh" }} text="Refresh" onClick={onRefresh} />}
      {items?.map(b => <CommandBarButton key={b.title} hidden={b.hidden} iconProps={{ iconName: b.icon }} text={b.title} onClick={b.action} />)}
      {timespanButton && <TimespanButton />}
      {children}
    </div>
  );
}

export default ActionButtons;
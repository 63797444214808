import { filterTypeToString } from "system/libraries/utilities";
import { memo, useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import EndpointFilterSelectBlock from "./EndpointFilterSelectBlock";
import EndpointFilterSelected from "./EndpointFilterSelected";
import { useDispatch, useSelector } from "react-redux";
import IRootState from "system/types/store/IRootState";
import { mainActions } from "system/store/slices/main-slice";
import { IMultiFilter } from "system/types/interfaces";
import { useConfiguration, usePageNavigation, useApi, useMultiFilters, useUrlSearch } from "system/hooks";
import { CustomerLicenseDisplayOption } from "system/types/enums/CustomerLicenseDisplayOption";
let filtersUpdateRefresh: NodeJS.Timeout;

const EndpointFilter = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const { navigateToEndpointPage } = usePageNavigation();
  const [hideSelectBlocks] = useState<number[]>([5, 6]);
  const [newValues, setNewValues] = useState<{ type: number; value: string | null }[]>([]);
  const [moreFiltersVisible, setMoreFiltersVisible] = useState(false);
  const [filtersRefresh, setFiltersRefresh] = useState<boolean>(false);
  const { filters, lastFilterSet, licensesCount } = useSelector((state: IRootState) => state.main);
  const { currentMultiFilters, setMultiFilter, factsToFilter, clearFilters } = useMultiFilters();
  const { currentTenant } = useConfiguration();

  const urlSearch = useUrlSearch();

  const quickSelectionChangeHandler = async (selection: IMultiFilter[]) => {
    // Typeahead must return exactly 1 element
    if (selection.length !== 1) {
      return;
    }

    const newFilter = selection[0];

    // Check if single filter is a machine name filter
    if (newFilter.filterType === 6 && !currentMultiFilters.filter((x) => x.filterValue).length && newFilter.filterValue) {
      const machineIdResponse = await api.machine.getIdByName(newFilter.filterValue);
      if (machineIdResponse.isSuccess && machineIdResponse.value && +machineIdResponse.value > 0) {
        navigateToEndpointPage(machineIdResponse.value);
        return;
      }
    }

    setMultiFilter(newFilter.filterType, newFilter.filterValue ?? "");
  };

  const setFilter = (type: number, value: string) => {
    let result: { type: number; value: string | null }[] = [...newValues];
    let modified = false;
    for (const val of result) {
      if (val.type === type) {
        val.value = value;
        modified = true;
      }
    }

    if (!modified) {
      result.push({ type, value });
    }

    setNewValues(result);
  };

  const getFilterValue = (type: number): string => {
    for (const val of newValues) {
      if (val.type === type) {
        return val.value ?? "";
      }
    }

    return "";
  };

  const applyFiltersHandler = () => {
    for (const val of newValues) {
      setMultiFilter(val.type, val.value);
    }

    setMoreFiltersVisible(false);
  };

  const clearFilterHandler = (type: number) => {
    setMultiFilter(type, null);
  };

  useEffect(() => {
    const result: { type: number; value: string | null }[] = [];
    for (const val of currentMultiFilters) {
      result.push({
        type: val.filterType,
        value: val.filterValue,
      });
    }

    setNewValues(result);
  }, [currentMultiFilters, moreFiltersVisible]);

  const licenseCountText = useMemo<string>(() => {
    if (!licensesCount) {
      return "";
    }

    let baseText = `${licensesCount.actualLicensed} total`;
    if (currentTenant?.customerShowLicenseFlag !== CustomerLicenseDisplayOption.DoNotShowLicenseCount) {
      baseText += ` / ${licensesCount.availableLicenses} licensed endpoints`;
    } else {
      baseText += " endpoints";
    }

    const selectedDateUrlValue = urlSearch.get("selectedDate");
    const selectedDate = selectedDateUrlValue ? new Date(selectedDateUrlValue).setHours(0, 0, 0, 0) : 0;
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const isDifferentDateSelected = selectedDate && selectedDate < currentDate;

    const hasFiltersApplied = currentMultiFilters.some((x) => x.filterValue);
    if (hasFiltersApplied || isDifferentDateSelected) {
      return `${licensesCount.subjectsFiltered} filtered / ${baseText}`;
    }

    return baseText;
  }, [currentMultiFilters, currentTenant?.customerShowLicenseFlag, licensesCount, urlSearch]);

  // Get filters every 10 minutes
  useEffect(() => {
    if (filters?.length && new Date().getTime() - lastFilterSet < 600000) {
      return;
    }

    api.metric.filters().then((result) => {
      if (result?.isSuccess && result.items?.length) {
        dispatch(mainActions.setFilters(result.items));
      } else {
        console.warn(result?.failMessage);
      }

      filtersUpdateRefresh = setTimeout(() => {
        setFiltersRefresh(!filtersRefresh);
      }, 600000);
    });

    return () => {
      clearTimeout(filtersUpdateRefresh);
    };
  }, [api, dispatch, filters, lastFilterSet, filtersRefresh, setFiltersRefresh]);

  return filters ? (
    <div style={{ marginBottom: "10px" }}>
      <div className="d-flex rmt-20 flex-wrap gap-1 items-center align-items-center" style={{ marginTop: "25px" }}>
        <div className="search">
          <Typeahead
            id="quick-filter"
            selected={[]}
            onChange={quickSelectionChangeHandler}
            options={filters ?? []}
            labelKey={(x: IMultiFilter) => `${x.filterValue} (${filterTypeToString(x.filterType)})`}
            align="left"
            filterBy={["filterValue"]}
            highlightOnlyResult={true}
            placeholder="Quick filter"
          />
          <i className="bi bi-search"></i>
        </div>
        <div className="btn-more-filter">
          <OverlayTrigger
            placement="bottom"
            show={moreFiltersVisible}
            overlay={
              <Popover id="popover-basic">
                <Popover.Body>
                  {Object.values(factsToFilter)
                    .filter((x) => hideSelectBlocks.indexOf(x) === -1)
                    .map((type, i) => (
                      <EndpointFilterSelectBlock
                        key={i}
                        type={type}
                        value={getFilterValue(type)}
                        options={filters.filter((x) => x.filterType === type)}
                        onValueChange={setFilter}
                      />
                    ))}
                  <div className="row mb-2">
                    <div className="d-flex justify-content-between">
                      <button type="button" className="btn btn-primary" onClick={() => applyFiltersHandler()}>
                        Apply
                      </button>
                      <button type="button" className="btn btn-default" onClick={() => setMoreFiltersVisible(false)}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <button type="button" className="btn btn-custom ml-5" onClick={() => setMoreFiltersVisible(!moreFiltersVisible)}>
              <i className="bi bi-filter"></i> Add Filter
            </button>
          </OverlayTrigger>
        </div>
        <div
          className="d-flex flex-wrap pl-15 gap-1 flex-grow-1 justify-content-end"
          style={{
            color: "var(--title-color)",
          }}
        >
          {licenseCountText}
        </div>
      </div>
      <div className="selected-filters">
        {currentMultiFilters
          .filter((x) => x.filterValue)
          .map((x) => (
            <EndpointFilterSelected key={x.filterType} type={x.filterType} value={x.filterValue} clear={clearFilterHandler} />
          ))}
        {currentMultiFilters.filter((x) => x.filterValue).length > 0 && (
          <span className="close-button">
            <a
              href="/"
              onClick={(event) => {
                clearFilters();
                event.preventDefault();
              }}
            >
              Clear Filters
            </a>
          </span>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default memo(EndpointFilter);

const LoadingPage = (props: any) => {

  return (
    <>
      <div style={{ 'width': '100%', 'textAlign': 'center' }}>
        <div style={{ 'color': '#f7f7f7', 'fontSize': '20pt', 'marginTop': '130px' }}>
          <img alt="logo" src="/icon.png" style={{ 'height': '50px', 'width': '50px' }} />
          <p>Please wait...</p>
        </div>
      </div>
    </>
  );
}

export default LoadingPage;
interface IProps {
  onClick: () => void;
  children: any;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
}

const AppLink = ({ onClick, children, style, className, disabled }: IProps) => {
  const linkStyle = {
    ...style,
    display: 'inline',
    cursor: (disabled ? 'auto' : 'pointer'),
    textDecorationLine: (disabled ? 'none' : 'auto')
  }

  return (
    <a
      href="/"
      onClick={e => { onClick(); e.preventDefault(); }}
      style={linkStyle}
      className={className}>{children}</a>);
}

export default AppLink;
import { ChangeEvent, useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import useAppNavigation from 'system/hooks/use-app-navigation';

export const supportedPageSizes = [10, 20, 30, 40, 50, 100, 500];
export const defaultPageSize = 500; // 30;
export const pageRangeCurrentToEdge = 5;

let timeoutId: NodeJS.Timeout | null = null;

interface IPagerProps {
  current: number;
  totalItems: number;
  pageSize?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (size: number) => void;
}

const PageSelector = ({ current, totalItems, pageSize, onPageChange, onPageSizeChange }: IPagerProps) => {
  const [inputPage, setInputPage] = useState(0);
  const location = useLocation();
  const navigate = useAppNavigation();
  const queryParams = new URLSearchParams(location.search);
  const actualPageSize = !!pageSize && pageSize > 0 ? pageSize : defaultPageSize;
  const total = Math.ceil(totalItems / actualPageSize);
  const pagesBefore: number[] = [];
  const pagesAfter: number[] = [];

  useEffect(() => {
    setInputPage(current);
  }, [current]);

  if (current > 1) {
    pagesBefore.push(1);
    for (let i = pageRangeCurrentToEdge - 1; i > 0; --i) {
      if ((current - i) > 1) {
        pagesBefore.push(current - i);
      }
    }
  }

  if (total > current) {
    for (let i = 1; i < pageRangeCurrentToEdge; ++i) {
      if ((current + i) >= total) {
        break;
      }

      pagesAfter.push(current + i)
    }

    pagesAfter.push(total);
  }

  const pageChangeHandler = (page: number) => {
    const toPage = (page < 1 || page > total) ? 1 : page;
    if (onPageChange) {
      onPageChange(toPage);
    }

    queryParams.set('page', toPage.toString());
    navigate(`?${queryParams.toString()}`);
  }

  const pageInputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = +e.target.value;
    if (inputValue < 1 || inputValue > total) {
      return;
    }

    setInputPage(inputValue);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      pageChangeHandler(+e.target.value);
    }, 700);
  };

  const pageSizeChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    var newPageSize = +e.target.value;
    if (onPageSizeChange) {
      onPageSizeChange(newPageSize);
    }

    queryParams.set('pageSize', newPageSize === defaultPageSize ? '' : newPageSize.toString());
    navigate(`?${queryParams.toString()}`);
  }

  if (current > total) {
    pageChangeHandler(total);
  }

  return (
    <>
      <div style={{ padding: '0', textAlign: 'center', height: '30px', verticalAlign: 'top' }}>
        {pagesBefore.map((p: number) => <span
          key={p}
          onClick={() => { pageChangeHandler(p) }}
          style={{ padding: '3px', cursor: 'pointer', color: '#fefefe' }}
        >{p}</span>)}
        <input
          type="number"
          value={inputPage}
          onChange={pageInputChangeHandler}
          style={{
            textAlign: 'center',
            width: '60px',
            height: '26px',
            paddingLeft: '10px',
            margin: '0 10px'
          }}
        />
        {pagesAfter.map((p: number) => <span
          key={p}
          onClick={() => { pageChangeHandler(p) }}
          style={{ padding: '3px', cursor: 'pointer', color: '#fefefe' }}
        >{p}</span>)}
        <select
          value={pageSize}
          onChange={pageSizeChangeHandler}
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
            margin: '0 10px',
            width: '70px',
            height: '26px',
            paddingLeft: '10px',
            paddingTop: '0',
            paddingBottom: '0'
          }}>
          {supportedPageSizes.map(i => <option key={i} value={i}>{i}</option>)}
        </select>
      </div>
    </>
  );
}

export default PageSelector;
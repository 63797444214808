import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useParams } from "react-router-dom";
import usePageNavigation from "system/hooks/use-page-navigation";
import useUrlSearch from "system/hooks/use-url-search";
import IMachineHistoryEntry from "system/types/interfaces/IMachineHistoryEntry";

export const defaultChartTimespanDays = 90;

class ChartDataItem {
  public x: number;
  public y: number;

  constructor(entry: IMachineHistoryEntry, statuses: string[]) {
    this.x = new Date(entry.dateTime).getTime();
    this.y = statuses.indexOf(entry.status) !== -1 ? 1 : 0;
  }
}

interface IChartProps {
  data: IMachineHistoryEntry[];
};

const EndpointChart = ({ data }: IChartProps) => {
  const { navigateToEndpointPage } = usePageNavigation();
  const { machineId } = useParams();
  const [options, setOptions] = useState<ApexOptions | undefined>(undefined);
  const [series, setSeries] = useState<ApexOptions['series'] | undefined>(undefined);
  const displayChart = !!options && !!series;
  const urlSearch = useUrlSearch();

  useEffect(() => {
    const chartOptions: ApexOptions = {
      chart: {
        id: 'assetDistribution',
        fontFamily: 'var(--font-family)',
        animations: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        height: 5,
        type: 'area',
        stacked: true,
        events: {
          click: (e: any, chart?: any, options?: any): void => {
            if (!options || options.dataPointIndex < 0) {
              return;
            }

            navigateToEndpointPage(machineId, data[options.dataPointIndex].dateTime, urlSearch.getSearchString());
          }
        }
      },
      fill: {
        opacity: 1,
        type: 'solid'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 0.9,
        curve: 'stepline'
      },
      tooltip: {
        enabledOnSeries: []
      },
      legend: {
        show: true
      },
      yaxis: {
        min: 0,
        max: 1,
        labels: {
          show: false,
          formatter: (value: any) => isNaN(value) ? '' : Math.round(Number(value)).toString()
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            day: 'MMM dd'
          }
        }
      },
      theme: {
        mode: 'dark'
      }
    };
    setOptions(chartOptions);

    setSeries([
      {
        name: "Critical",
        data: data.map(entry => new ChartDataItem(entry, ['1','20'])),
        color: '#f3796c'
      },
      {
        name: "Not reporting",
        data: data.map(entry => new ChartDataItem(entry, ['0'])),
        color: '#bfbdbb'
      },
      {
        name: "Has recommendations",
        data: data.map(entry => new ChartDataItem(entry, ['5'])),
        color: '#ffa031'
      },
      {
        name: "Compliant pending reboot",
        data: data.map(entry => new ChartDataItem(entry, ['8'])),
        color: '#42abdd'
      },
      {
        name: "Compliant",
        data: data.map(entry => new ChartDataItem(entry, ['7', '9'])),
        color: '#89d35d'
      }
    ]);
  }, [setOptions, setSeries, navigateToEndpointPage, data, machineId, urlSearch]);

  return (
    <div className="main-chart">
      <div className="card">
        {displayChart && <ReactApexChart
          height={95}
          options={options}
          series={series}
          type="area"
        />}
      </div>
    </div>
  );
}

export default EndpointChart;
import { createSlice } from "@reduxjs/toolkit";
import mainReducers from "./main-reducers";
import mainState from "./main-state";

const mainSlice = createSlice({
  name: 'main',
  initialState: mainState,
  reducers: mainReducers
});

export const mainActions = mainSlice.actions;

export default mainSlice;
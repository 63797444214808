
import { ICitation } from "system/types/interfaces"

interface IProps {
  citations?: ICitation[]
}

export const CitationReferencesComponent = ({ citations }: IProps) => {

  if (!citations || citations.length < 1) {
    return <></>
  }

  return <div className="citations-area">
    <span className="learn-more-text">Learn More:</span>
    <div className='citation-references-container'>
      {
        citations.map(
          (citation, index) => (
            <a className='citation-reference'
              title={citation.displayText}
              key={citation.citationId}
              rel="noreferrer"
              target="_blank"
              href={citation.url}>
              {index + 1}. {citation.displayText}
            </a>
          )
        )
      }
    </div>
  </div>


}
import { CommandButton, IContextualMenuProps } from "@fluentui/react";
import { toLocalISOString } from 'system/libraries/utilities';
import { useEffect, useState, useCallback } from "react";
import useUrlSearch from "system/hooks/use-url-search";
import { defaultChartTimespanDays } from "./EndpointChart";
import TimespanRangeSelect from "./TimespanRangeSelect";

const TimespanButton = () => {
  const { setMany: setManySearchParams, get: getSearchParam } = useUrlSearch();
  const [dateRange, setDateRange] = useState<{ from: Date; to: Date } | null>(null);

  const setUrlRange = useCallback((from?: Date, to?: Date) => {
    if (!from || !to) {
      setManySearchParams({
        rangeFrom: null,
        rangeTo: null
      });

      return;
    }

    const fromString = toLocalISOString(from);
    const toString = toLocalISOString(to);

    setManySearchParams({
      rangeFrom: fromString,
      rangeTo: toString
    });
  }, [setManySearchParams]);

  const setDaysRange = useCallback((days?: number, urlIsEmpty: boolean = false) => {
    const daysToSelect = days ?? defaultChartTimespanDays;
    const to = new Date();
    to.setHours(23, 59, 59, 0);
    const from = new Date(to);
    from.setDate(from.getDate() - daysToSelect);
    from.setHours(0, 0, 0, 0);
    setDateRange({ from: from, to: new Date() });
    if (daysToSelect === defaultChartTimespanDays) {
      if (!urlIsEmpty) {
        setUrlRange();
      }
    } else {
      setUrlRange(from, to);
    }
  }, [setUrlRange]);

  const setTimespanRange = useCallback((from: Date, to: Date, updateUrl: boolean = true) => {
    setDateRange({ from: from, to: to });
    if (updateUrl) {
      setUrlRange(from, to);
    }
  }, [setDateRange, setUrlRange]);

  useEffect(() => {
    const fromDateString = getSearchParam('rangeFrom');
    const toDateString = getSearchParam('rangeTo');

    if (fromDateString && toDateString) {
      setTimespanRange(new Date(fromDateString), new Date(toDateString), false)
    } else {
      setDaysRange(undefined, !fromDateString && !toDateString);
    }
  }, [setDaysRange, getSearchParam, setTimespanRange]);

  if (!dateRange) {
    return <></>;
  }

  const menuItems: IContextualMenuProps = {
    items: [
      {
        key: 'lastDay',
        text: 'Day',
        onClick: () => setDaysRange(1)
      },
      {
        key: 'lastWeek',
        text: 'Week',
        onClick: () => setDaysRange(7)
      },
      {
        key: 'lastMonth',
        text: 'Month',
        onClick: () => setDaysRange(30)
      },
      {
        key: 'lastQuarter',
        text: 'Quarter',
        onClick: () => setDaysRange()
      },
      {
        key: 'lastYear',
        text: 'Year',
        onClick: () => setDaysRange(365)
      },
      {
        key: 'customDates',
        text: 'Custom dates',
        subMenuProps: {
          items: [
            {
              key: 'dates',
              text: 'dates',
              onRender: () => (
                <TimespanRangeSelect
                  from={dateRange.from}
                  to={dateRange.to}
                  onChange={setTimespanRange}
                />
              ),
            }
          ],
        },
      },
    ]
  };

  return (
    <CommandButton iconProps={{ iconName: 'Calendar' }} text="Timespan" menuProps={menuItems} />
  );
}

export default TimespanButton;
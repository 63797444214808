import { Icon } from "@fluentui/react";
import { TabPanel } from "@mui/lab";
import { Button, ThemeProvider } from "@mui/material";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useApi } from "system/hooks";
import { muiTheme } from "system/styles/muiTheme";
import { v4 as generateUuid } from "uuid";
import { UploadKeyInfo } from "system/types/wireTypes";

export const UploadKeyTab = () => {
  //uploadkey state
  const [uploadKeys, setUploadKeys] = useState<UploadKeyInfo[]>([]);

  const api = useApi();

  //Columns for table in User Keys
  const uploadKeysCol = [
    { field: "key23", headerName: "Key", flex: 1, minWidth: 200 },
    { field: "created", headerName: "Created", flex: 1, minWidth: 200 },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="contained"
          onClick={() => deleteUploadKey(params.row.key23)}
          style={{ backgroundColor: "#d54c52", color: "white" }}
        >
          Delete
          <div className="delete-icon">
            <Icon iconName="delete" />
          </div>
        </Button>
      ),
    },
  ];

  // Rows for User Keys
  const uploadKeysRows = uploadKeys.map((ukey, index) => ({
    id: ukey.key23,
    key23: ukey.key23,
    created: ukey.createdAtDisplay,
  }));

  //uploadkey functions

  const loadUploadKeys = async () => {
    const response = await api.admin.uploadKeys();
    setUploadKeys(response.items ?? []);
  };
  const createUploadKey = async () => {
    const response = await api.admin.addUploadKey(generateUuid());
    if (response.isSuccess) loadUploadKeys();
  };
  const deleteUploadKey = async (key23: string | null) => {
    if (!key23) return;
    const response = await api.admin.deleteUploadKey(key23);
    if (response.isSuccess) loadUploadKeys();
  };

  useEffect(() => {
    loadUploadKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TabPanel value="uploadKeys">
        <div className="upload-key">
          <Button className="admin-buttons" variant="contained" onClick={() => createUploadKey()}>
            Create Upload Key
            <div className="upload-icon">
              <Icon iconName="upload" />
            </div>
          </Button>
        </div>
        <ThemeProvider theme={muiTheme}>
          <div style={{ height: "640px", width: "100%" }}>
            <DataGrid
              rows={uploadKeysRows}
              columns={uploadKeysCol}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 20, 50, 100]}
              disableRowSelectionOnClick
              sx={{
                "& .MuiDataGrid-cell": {
                  borderBottom: "1px solid #424242",
                  backgroundColor: "#3c3c3c",
                  color: "#ffffff",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#1e1e1e",
                  borderBottom: "1px solid #424242",
                  color: "white",
                },
                "& .MuiDataGrid-footerContainer": {
                  backgroundColor: "#1e1e1e",
                  borderTop: "1px solid #424242",
                  color: "white",
                  justifyContent: "center",
                },
                "& .MuiTablePagination-toolbar": {
                  color: "white",
                  alignItems: "baseline",
                  paddingTop: "10px",
                },
              }}
            />
          </div>
        </ThemeProvider>
      </TabPanel>
    </>
  );
};

import { useEffect, useState } from "react";
import useUrlSearch from "./use-url-search";

const useSearchField = () => {
  const [sortField, setSortField] = useState<string>('');
  const [sortDesc, setSortDesc] = useState<boolean>(false);
  const urlSearch = useUrlSearch();

  useEffect(() => {
    const sortFieldValue = urlSearch.get('sort');
    if (sortField !== sortFieldValue) {
      setSortField(sortFieldValue);
    }

    const sortDescValue = urlSearch.get('desc') === '1';
    if (sortDesc !== sortDescValue) {
      setSortDesc(sortDescValue);
    }
  }, [urlSearch, sortField, setSortField, sortDesc, setSortDesc]);

  return { sortField, sortDesc };
}

export default useSearchField;
import { filterTypeToString } from 'system/libraries/utilities';

interface IProps {
  type: number;
  value: string | null | undefined;
  clear?: (type: number) => void;
};

const EndpointFilterSelected = ({ type, value, clear }: IProps) => {
  return (<span className="selected-filter">
    <span> {filterTypeToString(type)}: </span>
    <span className="text-bold"> {value} </span>
    {clear && <span className="close-button">
      <a href="/" onClick={event => { clear(type); event.preventDefault(); }}> <i className="bi bi-x-lg"></i></a>
    </span>}
  </span>);
};

export default EndpointFilterSelected;
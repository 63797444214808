import { FontWeights, IDetailsListStyles, IDropdownStyles, ITextStyles, createTheme, mergeStyleSets } from "@fluentui/react";

export const fluentUiDarkTheme = createTheme({
  palette: {
    themePrimary: "#5ebce9",
    themeLighterAlt: "#040809",
    themeLighter: "#0f1e25",
    themeLight: "#1c3846",
    themeTertiary: "#38718b",
    themeSecondary: "#52a5cc",
    themeDarkAlt: "#6cc2ea",
    themeDark: "#81cbee",
    themeDarker: "#a1d8f2",
    neutralLighterAlt: "#262523",
    neutralLighter: "#2f2d2c",
    neutralLight: "#3d3b39",
    neutralQuaternaryAlt: "#464442",
    neutralQuaternary: "#4d4b49",
    neutralTertiaryAlt: "#6b6966",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "#f4f4f4",
    black: "#f8f8f8",
    white: "#1b1a19",
  },
});

export const fluentUiControlStyles = {
  root: {
    margin: "0 30px 20px 0",
    maxWidth: "300px",
    color: "var(--title-color)",
  },

  color: "var(--title-color)",
};

export const fluentUiGridStyles: Partial<IDetailsListStyles> = {
  root: {
    overflowX: "hidden",
    selectors: {
      "& [role=grid]": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        height: "55vh",
      },
    },
  },
  headerWrapper: {
    flex: "0 0 auto",
  },
  contentWrapper: {
    flex: "1 1 auto",
    overflowX: "hidden",
    overflowY: "auto",
    maxWidth: "100%",
  },
};

export const fluentUiDetailsListStyles: Partial<IDetailsListStyles> = {
  root: {
    selectors: {
      '& [data-automationid="DetailsList"]': {
        overflowX: "auto", // Enable horizontal scrolling for the root container
        width: "auto",
      },
    },
  },
  headerWrapper: {
    selectors: {
      ".ms-DetailsHeader": {
        width: "auto",
        borderTop: "none", // Remove the gray bar above the header
        selectors: {
          ".ms-DetailsHeader-cell": {
            color: "#ffffff", // White text for headers
            fontSize: "14px", // Font size for headers
          },
          ".ms-DetailsHeader-cellTitle": {
            fontWeight: "600", // Semi-bold font style for column titles
          },
          ".ms-DetailsHeader-cellName": {
            color: "#ffffff", // White text for header text
          },
          ".ms-DetailsHeader-sortIcon": {
            color: "#ffffff", // White text for sort icons
          },
          ".ms-DetailsHeader-filterChevron": {
            color: "#ffffff", // White text for filter icons
          },
        },
      },
    },
  },
  contentWrapper: {
    selectors: {
      ".ms-DetailsRow": {
        width: "auto",
        backgroundColor: "#1f1f1f", // Background for rows
        borderColor: "#323130", // Border color for rows
        selectors: {
          "&:hover": {
            backgroundColor: "#464775", // Background color when hovering over rows
          },
        },
      },
      ".ms-DetailsRow-cell": {
        width: "auto",
        color: "#ffffff", // White text for cells
        fontSize: "14px", // Font size for cells
        selectors: {
          "&:hover": {
            color: "#ffffff", // Maintain white text when hovering over cells
          },
        },
      },
      ".ms-List-cell": {
        borderBottom: "1px solid #323130", // Bottom border to separate rows
      },
    },
  },
  // Additional custom styles if needed
};

export const fluentUiBoldStyle: Partial<ITextStyles> = {
  root: {
    fontWeight: FontWeights.semibold,
    color: "var(--title-color)",
  },
};

export const fluentUiDropdownStyle: Partial<IDropdownStyles> = {
  root: {
    width: 300,
    color: "var(--title-color)",
  },
  dropdownItems: {
    maxHeight: 400,
  },
  dropdownItem: {
    ":hover": {
      backgroundColor: fluentUiDarkTheme.palette.blackTranslucent40,
      color: "var(--text-color)",
    },
    backgroundColor: fluentUiDarkTheme.palette.blackTranslucent40,
    color: "var(--title-color)",
  },
};

export const fluentUiTitleDropdownStyle: Partial<IDropdownStyles> = {
  root: {
    color: "var(--title-color)",
    selectors: {
     
      ":hover .ms-Dropdown-title": {
        borderColor: fluentUiDarkTheme.palette.blackTranslucent40,
        color: "var(--title-color)",
      },
      ".ms-Dropdown-title": {
        borderColor: fluentUiDarkTheme.palette.blackTranslucent40,
        color: "var(--text-color)",
        fontSize: "20px",
        padding: "10px 30px 10px 20px",
        height: "auto",
      },
      ".ms-Dropdown-caretDownWrapper": {
        display: "block",
        padding: "13px 0px 10px 0px",
      },
    },
  },
  dropdownItem: {
    ":hover": {
      backgroundColor: fluentUiDarkTheme.palette.blackTranslucent40,
      color: "var(--title-color)",
      fontSize: "16px",
    },
    backgroundColor: fluentUiDarkTheme.palette.blackTranslucent40,
    color: "var(--title-color)",
    fontSize: "16px",
  },
  dropdownItemSelected: {
    backgroundColor: fluentUiDarkTheme.palette.blackTranslucent40,
    color: "var(--title-color)",
    fontSize: "16px",
  },
};

const fluentUiClasses = mergeStyleSets({
  controlWrapper: {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: "20px",
  },
  selectionDetails: {
    marginBottom: "20px",
  },
  iconHeader: {
    padding: 0,
    fontSize: "16px",
  },
  iconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px",
  },
});

export default fluentUiClasses;

import { descriptionByFactCode } from 'system/libraries/constants';
import MachineFact from "system/types/interfaces/MachineFact";

interface IProps {
  facts: MachineFact[];
  code: string;
}

const EndpointDetailElement = ({ facts, code }: IProps) => {
  if (!facts) {
    return <></>;
  }

  return (
    <tr>
      <td><span className="endpoint-fact-code">{descriptionByFactCode[code]}</span></td>
      <td><span className="endpoint-fact-value ms-2">: {facts.find(f => f.code === code)?.value}</span></td>
    </tr>
  );
}

export default EndpointDetailElement;
import { useSelector } from "react-redux";
import useAppNavigation from "system/hooks/use-app-navigation";
import IAppBreadcrumb from "system/types/interfaces/IAppBreadcrumb";
import IRootState from "system/types/store/IRootState";

const TheBreadcrumbs = () => {
  const navigate = useAppNavigation();
  const breadcrumbs = useSelector((state: IRootState) => state.main.breadcrumbs);

  if (!breadcrumbs?.length) {
    return (<></>);
  }

  const data: IAppBreadcrumb[] = [
    {
      name: 'Home',
      url: '/'
    },
    ...breadcrumbs
  ];

  return (
    <div className="dialog-title" style={{ marginBottom: '10px' }}>
      {data.map((x, i) => {
        let mainClassNames: string[] = [];
        if (!x.url) {
          mainClassNames.push('dialog-title-active');
        }

        if (x.url?.length) {
          mainClassNames.push('clickable');
        }

        const clickHandler = (event: React.MouseEvent) => {
          event.preventDefault();
          if (x.url) {
            navigate(x.url);
          }
        };

        return (<span key={i}>
          {i > 0 && <span><i className='bi bi-chevron-right' style={{ marginRight: '4px' }} /></span>}
          <span className={mainClassNames.join(' ')} onClick={clickHandler}>
            {/*x.title && <span className="dialog-title-label" style={{ marginRight: '4px' }}>{x.title}:</span>*/}
            <span
              className="dialog-title-value"
              style={{ 
                marginLeft: '0',
                color: (x.url ? 'var(--link-color)' : undefined),
                textDecorationLine: 'none'
              }}>{x.name}</span>
          </span>
        </span>);
      })}
    </div>
  );
}

export default TheBreadcrumbs;
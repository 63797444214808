import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { mainActions } from "system/store/slices/main-slice";
import ITitleData from "system/types/interfaces/ITitleData";

const usePageTitle = (titleData: ITitleData) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mainActions.setPageTitle({
      title: (titleData.title ?? ''),
      subTitle: (titleData.subTitle ?? ''),
      navigateOnTitleClick: titleData.navigateOnTitleClick,
      navigateOnSubTitleClick: titleData.navigateOnSubTitleClick
    }));

    return () => {
      dispatch(mainActions.setPageTitle({
        title: '',
        subTitle: '',
        navigateOnTitleClick: undefined,
        navigateOnSubTitleClick: undefined
      }));
    };
  }, [dispatch, titleData]);
};

export default usePageTitle;
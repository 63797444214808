import { useCallback } from "react";
import useAppNavigation from "./use-app-navigation";
import { useSelector } from "react-redux";
import IRootState from "system/types/store/IRootState";
import { encodeUriString, statusToString } from "system/libraries/utilities";
import { SiteInfo } from "system/types/wireTypes";

const usePageNavigation = () => {
  const navigate = useAppNavigation();
  const { tenants, currentTenantId, defaultTenant } = useSelector((state: IRootState) => state.config);

  const navigateToHomePage = useCallback(() => {
    if (tenants?.find((x: SiteInfo) => x.siteId === currentTenantId)) {
      navigate(`/${currentTenantId}`, undefined, true);
    } else if (defaultTenant) {
      navigate(`/${defaultTenant}`, undefined, true);
    } else {
      navigate("/", undefined, true);
    }

    document.getElementById("root")?.scrollTo({
      top: 0,
      left: 0,
    });
  }, [tenants, currentTenantId, defaultTenant, navigate]);

  const getPackagesPageUrl = useCallback(() => {
    return "/packages";
  }, []);

  const getDocumentsPageUrl = useCallback(() => {
    return "/documents";
  }, []);

  const navigateToPackagesPage = useCallback(() => {
    const url = getPackagesPageUrl();
    if (url === null) {
      return;
    }

    navigate(url);
  }, [getPackagesPageUrl, navigate]);

  const navigateToDocumentsPage = useCallback(() => {
    const url = getDocumentsPageUrl();
    if (url === null) {
      return;
    }

    navigate(url);
  }, [getDocumentsPageUrl, navigate]);

  const getEndpointPageUrl = useCallback(
    (machineId?: number | string | null, atTime?: string | null, searchString?: string | null): string | null => {
      if (!machineId || isNaN(+machineId) || +machineId < 1) {
        return null;
      }

      const urlParams = new URLSearchParams();

      if (atTime) {
        urlParams.append("selectedDate", atTime);
      }

      if (searchString) {
        urlParams.append("search", searchString);
      }

      const queryString = urlParams.toString();
      return `/endpoint/${machineId}${queryString ? `?${queryString}` : ""}`;
    },
    []
  );

  const navigateToEndpointPage = useCallback(
    (machineId?: number | string | null, atTime?: string | null, searchString?: string | null) => {
      const url = getEndpointPageUrl(machineId, atTime, searchString);
      if (url === null) {
        return;
      }

      navigate(url);
    },
    [navigate, getEndpointPageUrl]
  );

  const getEndpointReportHistoryPageUrl = useCallback((machineId?: number | string | null): string | null => {
    if (!machineId || isNaN(+machineId) || +machineId < 1) {
      return null;
    }

    return `/endpoint/${machineId}/history`;
  }, []);

  const navigateToEndpointReportHistoryPage = useCallback(
    (machineId?: number | string | null) => {
      const url = getEndpointReportHistoryPageUrl(machineId);
      if (url === null) {
        return;
      }

      navigate(url);
    },
    [navigate, getEndpointReportHistoryPageUrl]
  );

  const navigateToEndpointByStatusPage = useCallback(
    (status?: number | null, selectedDate?: string | null, searchString?: string | null) => {
      if (typeof status !== "number" || !selectedDate) {
        return;
      }

      navigate(`/endpointsbystatus/${statusToString(status)}/${selectedDate}` + (searchString ? `?${searchString}` : ""));
    },
    [navigate]
  );

  const navigateToEndpointSubjectHistoryPage = useCallback(
    (machineId?: number | string | null, time?: string | null, subject?: string | null) => {
      if (!machineId || !time || !subject) {
        return;
      }

      navigate(`/endpoint/${machineId}/${time}/${subject}`);
    },
    [navigate]
  );

  const getSubjectByReasonPageUrl = useCallback(
    (subject?: string | null, selectedDate?: string | null, searchString?: string | null): string | null => {
      if (!subject || !selectedDate) {
        return null;
      }

      return `/subjectbyreason/${subject}?selectedDate=${selectedDate}` + (searchString ? `&${searchString}` : "");
    },
    []
  );

  const navigateToSubjectByReasonPage = useCallback(
    (subject?: string | null, selectedDate?: string | null, searchString?: string | null) => {
      const url = getSubjectByReasonPageUrl(subject, selectedDate, searchString);
      if (!url) {
        return;
      }

      navigate(url);
    },
    [navigate, getSubjectByReasonPageUrl]
  );

  const getEndpointsByReasonPageUrl = useCallback(
    (
      status?: number | null,
      subject?: string | null,
      selectedDate?: string | null,
      searchString?: string | null,
      message?: string | null
    ): string | null => {
      if (!subject || !selectedDate) {
        return null;
      }

      return (
        `/endpointsbyreason/${typeof status === "number" ? statusToString(status) : "all"}/${subject}/${selectedDate}` +
        (message ? `/${encodeUriString(message)}` : "") +
        (searchString ? `?${searchString}` : "")
      );
    },
    []
  );

  const navigateToEndpointsByReason = useCallback(
    (
      status?: number | null,
      subject?: string | null,
      selectedDate?: string | null,
      searchString?: string | null,
      message?: string | null
    ) => {
      const url = getEndpointsByReasonPageUrl(status, subject, selectedDate, searchString, message);
      if (!url) {
        return;
      }

      navigate(url);
    },
    [navigate, getEndpointsByReasonPageUrl]
  );

  return {
    navigateToHomePage,
    getEndpointPageUrl,
    navigateToEndpointPage,
    getEndpointReportHistoryPageUrl,
    navigateToEndpointReportHistoryPage,
    navigateToEndpointSubjectHistoryPage,
    navigateToEndpointByStatusPage,
    getSubjectByReasonPageUrl,
    navigateToSubjectByReasonPage,
    getEndpointsByReasonPageUrl,
    navigateToEndpointsByReason,
    getPackagesPageUrl,
    navigateToPackagesPage,
    navigateToDocumentsPage,
  };
};

export default usePageNavigation;

import { useMemo, useState } from "react";
import useConfiguration from "./use-configuration";
import {
  AccessEntryInfo, AllTimeMetricsResponse, BaseResponse, FilteredTimeArgs, FilteredTimeSubjectArgs,
  ListResponse, MachineAtTime, MachineAtTimesArgs, MachineByStatusArgs, MachineHistoryArgs, MachineSubjectArgs,
  MultiFilter, ScalarResponse, SiteInfo, SubjectPackageArgs, SubjectStatusAtTime, SubjectStatusReasonSummary,
  SubjectStatusSubjectSummary, SubjectStatusSummary, SummarySubjectStatusArgs, TimeBoundMetricsResponse,
  TimeRangeArgs, TimeResponse, UploadKeyInfo, UserInfoResponse, UserToken, ValueResponse
} from 'system/types/wireTypes';
import IMachineDetails from "system/types/interfaces/IMachineDetails";
import IPagedRuntimeHistoryResponse from "system/types/interfaces/IPagedRuntimeHistoryResponse";
import IMachineSubjectHistory from "system/types/interfaces/IMachineSubjectHistory";
import HttpMethods from "system/types/enums/HttpMethods";
import useHttpRequest from "./use-http-request";
import authentication from "system/libraries/authentication";

const useApi = () => {
  const [auth] = useState(authentication);
  const { currentTenant } = useConfiguration();

  const apiUrl = useMemo<string>(() => (
    (process.env.REACT_APP_BACKEND_URL ?? '') + '/api/v1.0/'), []);

  const httpRequest = useHttpRequest(apiUrl, auth);

  return useMemo(() => ({
    auth,
    admin: {
      me: async (): Promise<UserInfoResponse> => {
        return await httpRequest(HttpMethods.get, 'admin/me');
      },
      sites: async (): Promise<ListResponse<SiteInfo>> => {
        return await httpRequest(HttpMethods.get, 'admin/sites');
      },
      deleteSite: async (id: number): Promise<BaseResponse> => {
        return await httpRequest(HttpMethods.delete, `admin/sites/${id}`);
      },
      addEditSite: async (site: SiteInfo): Promise<BaseResponse> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }
        return await httpRequest(HttpMethods.post, `admin/sites?tenantId=${currentTenant.siteId}`, site);
      },
      uploadKeys: async (): Promise<ListResponse<UploadKeyInfo>> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.get, `admin/uploadkeys?tenantId=${currentTenant.siteId}`);
      },
      deleteUploadKey: async (key23: string): Promise<BaseResponse> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.delete, `admin/uploadkeys?key23=${encodeURIComponent(key23)}&tenantId=${currentTenant.siteId}`);
      },
      addUploadKey: async (key23: string): Promise<BaseResponse> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.post, `admin/uploadkeys?key23=${key23}&tenantId=${currentTenant.siteId}`, {});
      },
      login: async (userName: string, password: string): Promise<UserToken> => {
        return await httpRequest(HttpMethods.post, 'token', { userName, password });
      }
    },
    access: {
      getAcl: async (): Promise<AccessEntryInfo[]> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.get, `access/${currentTenant.siteId}`);
      },
      grant: async (info: AccessEntryInfo): Promise<ValueResponse<AccessEntryInfo>> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.post, `access/${currentTenant.siteId}`, info);
      },
      revoke: async (info: AccessEntryInfo): Promise<BaseResponse> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.delete, `access/${currentTenant.siteId}`, info);
      }
    },
    report: {
      get: async (dateTime: Date): Promise<Blob | null> => {
        if (!dateTime) {
          return null;
        }

        return await httpRequest(HttpMethods.get, `report/${dateTime.toISOString()}`, null, true);
      }
    },
    metric: {
      metrics: async (): Promise<AllTimeMetricsResponse | null> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.get, `metric/metrics?tenantId=${currentTenant.siteId}`);
      },
      metricsByDate: async (args: TimeRangeArgs): Promise<TimeBoundMetricsResponse | null> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.post, `metric/metricsbydate?tenantId=${currentTenant.siteId}`, args);
      },
      age: async (): Promise<TimeResponse | null> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.get, `metric/age?tenantId=${currentTenant.siteId}`);
      },
      filters: async (): Promise<ListResponse<MultiFilter> | null> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.get, `metric/filters?tenantId=${currentTenant.siteId}`);
      },
    },
    list: {
      machinesByReason: async (args: SubjectPackageArgs): Promise<ListResponse<SubjectStatusAtTime> | null> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.post, `list/machinesbyreason?tenantId=${currentTenant.siteId}`, args);
      },
      machineDetail: async (args: MachineAtTimesArgs): Promise<ListResponse<MachineAtTime> | null> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.post, `list/machinedetail?tenantId=${currentTenant.siteId}`, args);
      },
      machineSubjectHistory: async (args: MachineSubjectArgs): Promise<ListResponse<SubjectStatusAtTime> | null> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.post, `list/machinesubjecthistory?tenantId=${currentTenant.siteId}`, args);
      },
      machineRunHistory: async (args: MachineHistoryArgs): Promise<ListResponse<MachineAtTime> | null> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.post, `list/machinerunhistory?tenantId=${currentTenant.siteId}`, args);
      },
      machinesByStatus: async (args: MachineByStatusArgs): Promise<ListResponse<MachineAtTime> | null> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.post, `list/machinesbystatus?tenantId=${currentTenant.siteId}`, args);
      },
    },
    machine: {
      getIdByName: async (machineName: string): Promise<ScalarResponse<number>> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.get, `machine/GetIdByName/${currentTenant.siteId}/${machineName}`);
      },
      getMachineDetail: async (machineId: number, time: Date | null | undefined, chartDatePoints: Date[]): Promise<ScalarResponse<IMachineDetails>> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.post, `machine/GetDetails/${currentTenant.siteId}/${machineId}`, {
          time: time ? time.toISOString() : null,
          datePoints: chartDatePoints.map(x => x.toISOString())
        });
      },
      getMachineSubjectHistory: async (machineId: number, time: Date, subject: string): Promise<ScalarResponse<IMachineSubjectHistory>> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.get, `machine/GetMachineSubjectHistory/${currentTenant.siteId}/${machineId}/${time.toISOString()}/${subject}`);
      },
      getRuntimeHistory: async (machineId: number, page: number, pageSize: number, order: number): Promise<IPagedRuntimeHistoryResponse> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.get, `machine/GetRuntimeHistory/${currentTenant.siteId}/${machineId}/${page}/${pageSize}/${order}`);
      }
    },
    summary: {
      subjectStatus: async (args: SummarySubjectStatusArgs): Promise<SubjectStatusSummary | null> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.post, `summary/subjectstatus?tenantId=${currentTenant.siteId}`, args);
      },
      subjectStatusSubject: async (args: FilteredTimeArgs): Promise<SubjectStatusSubjectSummary | null> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.post, `summary/subjectstatussubject?tenantId=${currentTenant.siteId}`, args);
      },
      subjectStatusReason: async (args: FilteredTimeSubjectArgs): Promise<SubjectStatusReasonSummary | null> => {
        if (!currentTenant) {
          throw new Error(`Api request was not sent, no tenant defined.`);
        }

        return await httpRequest(HttpMethods.post, `summary/subjectstatusreason?tenantId=${currentTenant.siteId}`, args);
      },
    },
    document: {
      getSasToken: async (customer: string, fileName: string): Promise<ValueResponse<string>> => {
        return await httpRequest(HttpMethods.get, `document/sastoken/${customer}/${fileName}`)
      }
    }
  }), [auth, currentTenant, httpRequest]);
};

export default useApi;
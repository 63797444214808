import React, { useState } from 'react';
import { filterTypeToString } from 'system/libraries/utilities';
import IMultiFilter from 'system/types/interfaces/IMultiFilter';
import { Checkbox, ListItemText, Select, InputLabel, FormControl, SelectChangeEvent, MenuItem, ThemeProvider } from '@mui/material';
import { muiTheme } from 'system/styles/muiTheme';

interface IProps {
  type: number;
  value?: string;
  onValueChange: (type: number, value: string) => void;
  options: IMultiFilter[];
}

const EndpointFilterSelectBlock = ({ type, value = "", onValueChange, options }: IProps) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(value ? value.split(",") : []);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'string') {
      return;
    }

    if (value.includes('select-all')) {
      handleToggleAll();
    } else {
      setSelectedValues(value);
      onValueChange(type, value.join(","));
    }
  };

  const handleSelectAll = () => {
    const allValues = options.map(option => option.filterValue ?? "");
    setSelectedValues(allValues);
    onValueChange(type, allValues.join(","));
  };

  const handleDeselectAll = () => {
    setSelectedValues([]);
    onValueChange(type, "");
  };

  const handleToggleAll = () => {
    if (selectedValues.length === options.length) {
      handleDeselectAll();
    } else {
      handleSelectAll();
    }
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <div className="row mb-3">
        <label className="col-sm-5 col-form-label">{filterTypeToString(type)}</label>
        <div className="col-sm-7">
          <FormControl fullWidth>
            <InputLabel id={`select-label-${type}`} size="small">Select</InputLabel>
            <Select
              labelId={`select-label-${type}`}
              multiple
              value={selectedValues}
              onChange={handleChange}
              size="small"
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem key="select-all" value="select-all">
                <Checkbox
                  checked={selectedValues.length === options.length}
                  indeterminate={selectedValues.length > 0 && selectedValues.length < options.length}
                />
                <ListItemText primary={selectedValues.length === options.length ? "Deselect All" : "Select All"} />
              </MenuItem>
              {options.map((option) => (
                <MenuItem key={option.filterValue ?? ""} value={option.filterValue ?? ""}>
                  <Checkbox checked={selectedValues.includes(option.filterValue ?? "")} />
                  <ListItemText primary={option.filterValue ?? ""} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default EndpointFilterSelectBlock;

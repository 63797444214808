import { useState, useCallback, useRef } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import { TransferProgressEvent } from "@azure/core-http";
import { FileWithProgress } from "system/types/interfaces";
import useApi from "./use-api";

const useFileUpload = (customer: string, folderPath?: string) => {
  const [filesWithProgress, setFilesWithProgress] = useState<FileWithProgress[]>([]);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string | null>(null);
  const abortControllersRef = useRef<Map<string, AbortController>>(new Map());
  const api = useApi();

  const getBlobSasToken = useCallback(
    async (fileName: string): Promise<string | null> => {
      try {
        const filePath = folderPath && folderPath.length > 0 ? folderPath : fileName;

        const response = await api.document.getSasToken(customer, filePath);
        return response.value;
      } catch (error) {
        console.error("Error fetching SAS token:", error);
        setUploadErrorMessage("Could not fetch SAS token. Please try again later.");
        return null;
      }
    },
    [api.document, customer, folderPath]
  );

  const uploadFileDirectlyToBlob = useCallback(
    async (
      file: File,
      sasUrl: string,
      updateProgressCallback: (progressEvent: TransferProgressEvent) => void,
      abortSignal: AbortSignal
    ) => {
      const blobServiceClient = new BlobServiceClient(sasUrl);
      const containerClient = blobServiceClient.getContainerClient("");
      const blobClient = containerClient.getBlockBlobClient(file.name);

      await blobClient.uploadData(file, {
        blobHTTPHeaders: {
          blobContentType: file.type,
        },
        onProgress: updateProgressCallback,
        abortSignal: abortSignal,
      });
    },
    []
  );

  const uploadFiles = useCallback(async (): Promise<void> => {
    try {
      for (const { file, isCompleted, isUploading } of filesWithProgress) {
        if (isCompleted || isUploading) {
          continue;
        }

        const abortController = new AbortController();
        abortControllersRef.current.set(file.name, abortController);

        const sasUrl = await getBlobSasToken(file.name);
        if (!sasUrl) {
          continue;
        }

        try {
          await uploadFileDirectlyToBlob(
            file,
            sasUrl,
            (progressEvent: TransferProgressEvent) => {
              const progress = Math.round((progressEvent.loadedBytes * 100) / file.size);
              setFilesWithProgress((currentFiles) =>
                currentFiles.map((f) => (f.file.name === file.name ? { ...f, progress, isUploading: true, isRequestCompleted: false } : f))
              );
            },
            abortController.signal
          );

          setFilesWithProgress((currentFiles) =>
            currentFiles.map((f) =>
              f.file.name === file.name ? { ...f, isCompleted: true, isUploading: false, isRequestCompleted: true } : f
            )
          );
        } catch (error) {
          console.error("Error uploading file directly to Blob Storage:", error);
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadErrorMessage("Failed to upload file. Please try again.");
    }
  }, [filesWithProgress, getBlobSasToken, uploadFileDirectlyToBlob]);

  const cancelUpload = useCallback((fileName: string): void => {
    const abortController = abortControllersRef.current.get(fileName);
    if (abortController) {
      abortController.abort();
      abortControllersRef.current.delete(fileName);

      setFilesWithProgress((currentFiles) =>
        currentFiles.map((f) => {
          if (f.file.name === fileName) {
            return {
              ...f,
              isUploading: false,
              progress: 0,
              isRequestCompleted: false,
            };
          }
          return f;
        })
      );
    }
  }, []);

  const addFile = useCallback((file: File) => {
    setFilesWithProgress((prevFiles) => [
      ...prevFiles,
      { file, progress: 0, isCompleted: false, isUploading: false, isRequestCompleted: false },
    ]);
  }, []);

  const removeFile = useCallback((fileName: string) => {
    const abortController = abortControllersRef.current.get(fileName);
    if (abortController) {
      abortController.abort();
      abortControllersRef.current.delete(fileName);
    }

    setFilesWithProgress((currentFiles) => currentFiles.filter((f) => f.file.name !== fileName));
  }, []);

  const resetFiles = useCallback(() => {
    filesWithProgress.forEach(({ file }) => {
      cancelUpload(file.name);
    });
    setFilesWithProgress([]);
  }, [filesWithProgress, cancelUpload]);

  return {
    filesWithProgress,
    uploadErrorMessage,
    setUploadErrorMessage,
    addFile,
    removeFile,
    uploadFiles,
    cancelUpload,
    resetFiles,
  };
};

export default useFileUpload;
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "system/store";
import { mainActions } from "system/store/slices/main-slice";

interface _useResponsive {
  isMobileSidebarVisible: boolean;
  toggleMobileSidebarVisibility: () => void;
}

export const useResponsive = (): _useResponsive => {
  const { isMobileSidebarVisible } = useSelector((state: RootState) => state.main);

  const dispatch = useDispatch();

  const toggleMobileSidebarVisibility = (): void => {
    dispatch(mainActions.setIsMobileSidebarVisible(!isMobileSidebarVisible));
  };

  return {
    isMobileSidebarVisible,
    toggleMobileSidebarVisibility
  };
};

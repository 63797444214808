import { TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import { AccessTab } from "./access/AccessTab";
import { UploadKeyTab } from "./uploadKey/UploadKeyTab";
import { SiteTab } from "./site/SiteTab";
import { useConfiguration } from "system/hooks";
import AdminReports from "./report/AdminReports";

interface AdminTabsProps {
  activeTab: string;
  handleTabChange: (event: React.SyntheticEvent, newValue: string) => void;
}

export const AdminTabs: React.FC<AdminTabsProps> = ({ activeTab, handleTabChange }) => {
  const { hasPermissionU, hasPermissionC, hasPermissionG, hasPermissionR } = useConfiguration();

  return (
    <>
      <TabList onChange={handleTabChange} aria-label="adminTabs">
        {hasPermissionU && <Tab label="Access" value="users" sx={{ color: "white" }} />}
        {hasPermissionC && <Tab label="Upload Keys" value="uploadKeys" sx={{ color: "white" }} />}
        {(hasPermissionC || hasPermissionG) && <Tab label="Customer" value="sites" sx={{ color: "white" }} />}
        {hasPermissionR && <Tab label="Reports" value="reports" sx={{ color: "white" }} />}
      </TabList>

      {hasPermissionU && <AccessTab />}
      {hasPermissionC && <UploadKeyTab />}
      {(hasPermissionC || hasPermissionG) && <SiteTab />}
      {hasPermissionR && (
        <TabPanel value="reports">
          <div className="tab-content">
            <AdminReports />
          </div>
        </TabPanel>
      )}
    </>
  );
};

import { useState } from "react";
import { SiteInfo } from "system/types/wireTypes";
import { CustomerLicenseDisplayOption } from "system/types/enums/CustomerLicenseDisplayOption";
import { FormikErrors, useFormik } from "formik";
import useApi from "system/hooks/use-api";
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import AskAidenAccess from "system/types/enums/AskAidenAccess";

interface IProps {
  site: SiteInfo | null;
  done: () => void;
}

export default function SiteForm({ site, done }: IProps) {
  const api = useApi();
  const [message, setMessage] = useState("");

  const form = useFormik({
    initialValues: {
      siteName: site?.siteName ?? "",
      shortName: site?.shortName ?? "",
      oldDeleteDays: site?.oldDeleteDays ?? 0,
      decommissionDays: site?.decommissionDays ?? 0,
      licensedMachineCount: site?.licensedMachineCount ?? 0,
      licenseDays: site?.licenseDays ?? 0,
      validDays: site?.validDays ?? 0,
      customerShowLicenseFlag: site?.customerShowLicenseFlag ?? "",
      askAidenAccess: site?.askAidenAccess ?? null,
      isFileModuleEnabled: site?.isFileModuleEnabled ?? false,
      isSubjectStatusEnabled: site?.isSubjectStatusEnabled ?? false,
    },
    validate: (values) => {
      const errors: FormikErrors<SiteInfo> = {};
      if (!values.siteName) errors.siteName = "Required";
      else if (values.siteName.length > 100) errors.siteName = "Up to 100 characters";
      else if (values.siteName.length < 2) errors.siteName = "Must be at least 2 characters";
      if (!values.shortName) errors.shortName = "Required";
      else if (values.shortName.length > 64) errors.shortName = "Up to 64 characters";
      else if (values.shortName.length < 2) errors.shortName = "Must be at least 2 characters";
      if (values.oldDeleteDays < 180) errors.oldDeleteDays = "Must be at least 180";
      if (values.decommissionDays < 7) errors.decommissionDays = "Must be at least 7";
      if (values.licensedMachineCount < 10) errors.licensedMachineCount = "Must be at least 10";
      if (values.licenseDays < 14) errors.licenseDays = "Must be at least 14";
      if (values.validDays < 7) errors.validDays = "Must be at least 7";
      return errors;
    },
    onSubmit: async (values) => {
      setMessage(""); // Clear previous messages
      const newSite: SiteInfo = {
        siteId: site?.siteId ?? 0,
        siteName: values.siteName,
        shortName: values.shortName,
        oldDeleteDays: values.oldDeleteDays,
        decommissionDays: values.decommissionDays,
        licensedMachineCount: values.licensedMachineCount,
        licenseDays: values.licenseDays,
        validDays: values.validDays,
        actualMachineCount: 0,
        isFileModuleEnabled: values.isFileModuleEnabled,
        isSubjectStatusEnabled: values.isSubjectStatusEnabled,
        customerShowLicenseFlag: +values.customerShowLicenseFlag,
        askAidenAccess: values.askAidenAccess ?? 0,
      };
      const result = await api.admin.addEditSite(newSite);
      if (result.isSuccess) {
        done();
      } else {
        setMessage(result.failMessage ?? result.title ?? "Error");
      }
      form.setSubmitting(false);
    },
  });

  const commonStyles = {
    color: "#ffffff",
    borderBottomColor: "#aaaaaa",
    fontSize: "0.875rem",
    padding: "6px",
  };

  return (
    <div>
      <form id="site-form" onSubmit={form.handleSubmit}>
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField
            label="Name of Customer"
            name="siteName"
            value={form.values.siteName}
            onChange={form.handleChange}
            error={!!form.errors.siteName}
            helperText={form.errors.siteName}
            variant="standard"
            InputProps={{ style: commonStyles }}
            InputLabelProps={{ style: { color: "#ffffff" } }}
          />

          <TextField
            label="Short Name of Customer"
            name="shortName"
            value={form.values.shortName}
            onChange={form.handleChange}
            error={!!form.errors.shortName}
            helperText={form.errors.shortName}
            variant="standard"
            InputProps={{ style: commonStyles }}
            InputLabelProps={{ style: { color: "#ffffff" } }}
          />

          <TextField
            label="Days to retain data"
            name="oldDeleteDays"
            value={form.values.oldDeleteDays}
            onChange={form.handleChange}
            error={!!form.errors.oldDeleteDays}
            helperText={form.errors.oldDeleteDays}
            variant="standard"
            InputProps={{ style: commonStyles }}
            InputLabelProps={{ style: { color: "#ffffff" } }}
          />

          <TextField
            label="Days of silence which counts as decommissioned machine"
            name="decommissionDays"
            value={form.values.decommissionDays}
            onChange={form.handleChange}
            error={!!form.errors.decommissionDays}
            helperText={form.errors.decommissionDays}
            variant="standard"
            InputProps={{ style: commonStyles }}
            InputLabelProps={{ style: { color: "#ffffff" } }}
          />

          <TextField
            label="Number of machines licensed"
            name="licensedMachineCount"
            value={form.values.licensedMachineCount}
            onChange={form.handleChange}
            error={!!form.errors.licensedMachineCount}
            helperText={form.errors.licensedMachineCount}
            variant="standard"
            InputProps={{ style: commonStyles }}
            InputLabelProps={{ style: { color: "#ffffff" } }}
          />

          <TextField
            label="Days over which machine activity counts towards license"
            name="licenseDays"
            value={form.values.licenseDays}
            onChange={form.handleChange}
            error={!!form.errors.licenseDays}
            helperText={form.errors.licenseDays}
            variant="standard"
            InputProps={{ style: commonStyles }}
            InputLabelProps={{ style: { color: "#ffffff" } }}
          />

          <TextField
            label="Days of silence after which compliance becomes unknown"
            name="validDays"
            value={form.values.validDays}
            onChange={form.handleChange}
            error={!!form.errors.validDays}
            helperText={form.errors.validDays}
            variant="standard"
            InputProps={{ style: commonStyles }}
            InputLabelProps={{ style: { color: "#ffffff" } }}
          />

          <FormControl component="fieldset" style={{ marginTop: "5px", fontSize: "0.875rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.values.isFileModuleEnabled}
                  onChange={form.handleChange}
                  name="isFileModuleEnabled"
                  sx={{ color: "#ffffff" }}
                />
              }
              label="Document Module"
              style={{ color: "#ffffff", fontSize: "0.875rem" }}
            />
          </FormControl>

          <FormControl component="fieldset" style={{ marginTop: "5px", fontSize: "0.875rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.values.isSubjectStatusEnabled}
                  onChange={form.handleChange}
                  name="isSubjectStatusEnabled"
                  sx={{ color: "#ffffff" }}
                />
              }
              label="Subject State Graph"
              style={{ color: "#ffffff", fontSize: "0.875rem" }}
            />
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="customerShowLicenseFlag-label" sx={{ color: "#ffffff", fontSize: "0.875rem" }}>
              Show Customer Licenses
            </InputLabel>
            <Select
              labelId="customerShowLicenseFlag-label"
              id="customerShowLicenseFlag"
              name="customerShowLicenseFlag"
              value={form.values.customerShowLicenseFlag}
              label="Show Customer Licenses"
              onChange={form.handleChange}
              sx={{ color: "#ffffff", fontSize: "0.875rem", ".MuiSelect-icon": { color: "#ffffff" } }}
            >
              <MenuItem value={CustomerLicenseDisplayOption.DoNotShowLicenseCount}>Do Not Show License Count</MenuItem>
              <MenuItem value={CustomerLicenseDisplayOption.ShowLicenseCount}>Show License Count</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="askAidenAccess-label" sx={{ color: "#ffffff", fontSize: "0.875rem" }}>
              AskAiden access level
            </InputLabel>
            <Select
              labelId="askAidenAccess-label"
              id="askAidenAccess"
              name="askAidenAccess"
              value={form.values.askAidenAccess}
              label="AskAiden access level"
              onChange={form.handleChange}
              sx={{ color: "#ffffff", fontSize: "0.875rem", ".MuiSelect-icon": { color: "#ffffff" } }}
            >
              <MenuItem value={AskAidenAccess.NoAccess}>No Access</MenuItem>
              <MenuItem value={AskAidenAccess.PromptsAccess}>Prompts Access</MenuItem>
              <MenuItem value={AskAidenAccess.FullAccess}>Full Access</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <div className="warning">{message}</div>
      </form>
    </div>
  );
}

import { useEffect, useState } from "react";

const TheEnvironmentTitle = () => {
  const [environment, setEnvironment] = useState("");
  useEffect(() => {
    const environment = window.location.hostname.toLowerCase().split(".")[0];
    setEnvironment(environment);
  }, []);

  if (!environment || environment === "aiden") return <></>;

  return <span style={{ verticalAlign: "bottom" }}>{environment}</span>;
};

export default TheEnvironmentTitle;

import { useCallback, useEffect, useMemo, useState } from "react";
import useUrlSearch from "./use-url-search";
import IMultiFilter from "system/types/interfaces/IMultiFilter";
import { filterTypes } from "system/libraries/constants";

const useMultiFilters = () => {
  const searchParams = useUrlSearch();
  const [currentFilters, setCurrentFilters] = useState<{ [name: string]: string | null }>({});

  const [factsToFilter] = useState<{ [name: string]: number }>({
    config: 1,
    adsite: 2,
    mfr: 3,
    model: 4,
    subject: 5,
    computer: 6
  });

  useEffect(() => {
    let filtersChanged = false;
    for (const name in factsToFilter) {
      if (currentFilters[name] !== searchParams.get(name)) {
        filtersChanged = true;
        break;
      }
    }

    if (!filtersChanged) {
      return;
    }

    let result: { [name: string]: string | null } = {};
    for (const name in factsToFilter) {
      result[name] = searchParams.get(name);
    }

    setCurrentFilters(result);
  }, [searchParams, currentFilters, setCurrentFilters, factsToFilter]);

  const typeToFilterName = useCallback(
    (type: number): string | null => {
      for (const name in factsToFilter) {
        if (factsToFilter[name] === type) {
          return name;
        }
      }

      return null;
    },
    [factsToFilter]
  );

  const setMultiFilter = useCallback(
    (type: number, value: string | null | undefined) => {
      for (const name in factsToFilter) {
        if (factsToFilter[name] === type) {
          searchParams.set(name, value ?? null);
          return;
        }
      }
    },
    [searchParams, factsToFilter]
  );

  const clearFilters = useCallback(() => {
    let result: { [name: string]: string | null } = {};
    for (const name in factsToFilter) {
      result[name] = null;
    }

    searchParams.setMany(result);
  }, [searchParams, factsToFilter]);

  const currentMultiFilters = useMemo<IMultiFilter[]>(() => {
    let result: IMultiFilter[] = [];

    for (const name in currentFilters) {
      result.push({
        filterType: factsToFilter[name],
        filterValue: currentFilters[name],
      });
    }

    return result;
  }, [currentFilters, factsToFilter]);

  const multiFilterSearchString = useMemo<string>(
    () =>
      currentMultiFilters
        .filter((x) => x.filterValue)
        .map((x) => `${typeToFilterName(x.filterType)}=${x.filterValue}`)
        .join("&"),
    [currentMultiFilters, typeToFilterName]
  );

  const getMultiFilterValue = useCallback(
    (type: number) => {
      const multiFilterArray = currentMultiFilters.filter((x) => x.filterType === type);
      if (multiFilterArray.length < 1) {
        return null;
      }

      const result = multiFilterArray[0].filterValue;
      return result?.length ? result : null;
    },
    [currentMultiFilters]
  );

  const machineFilterSet = useMemo(
    () =>
      currentMultiFilters?.length
        ? {
            adSite: getMultiFilterValue(filterTypes.ADSITE),
            computerName: getMultiFilterValue(filterTypes.COMPUTER),
            configurationName: getMultiFilterValue(filterTypes.CONFIG),
            hardwareMfr: getMultiFilterValue(filterTypes.MFR),
            hardwareModel: getMultiFilterValue(filterTypes.MODEL),
            subject: getMultiFilterValue(filterTypes.SUBJECT), 
          }
        : null,
    [getMultiFilterValue, currentMultiFilters]
  );
  
  return {
    currentMultiFilters,
    setMultiFilter,
    factsToFilter,
    clearFilters,
    multiFilterSearchString,
    machineFilterSet
  };
};

export default useMultiFilters;

import { Icon } from "@fluentui/react";

interface ErrorMessageProps {
  title: string;
  message: string;
  isVisible: boolean;
  onConfirm: () => void;
}

const ErrorMessage = ({ title, message, isVisible, onConfirm }: ErrorMessageProps) => {
  return (
    <div className="errorMessage" style={{ display: isVisible ? "flex" : "none" }}>
      <div className="errorMessage__content">
        <div className="errorMessage__header">
          <h4 className="errorMessage__title">{title}</h4>
        </div>
        <div className="errorMessage__body">
          <Icon className="errorMessage__body--icon" iconName="Cancel" />
          <p className="errorMessage__body--message">{message}</p>
        </div>
        <div className="errorMessage__footer">
          <button type="button" className="errorMessage__footer--button" onClick={onConfirm}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
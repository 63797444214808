import { Box } from "@mui/material";
import { TabContext } from "@mui/lab";
import { AdminTabs } from "./AdminTabs";
import { useState } from "react";
import { useConfiguration } from "system/hooks";

export default function AdminHome() {
  const { currentTenant, hasPermissionU, hasPermissionC, hasPermissionG } = useConfiguration();

  const [activeTab, setActiveTab] = useState("users"); // Changing this useState value allows which tab to be accessed first.

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  if (!hasPermissionU && !hasPermissionC && !hasPermissionG) return <div>You don't have any administrative permissions.</div>;

  return (
    <div className="admin-home">
      <div className="admin-title">
        <h2>Administration</h2>
        Customer: {currentTenant?.siteName}
      </div>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
        <AdminTabs activeTab={activeTab} handleTabChange={handleTabChange} />
      </TabContext>
    </div>
  );
}

import { AccountInfo } from '@azure/msal-browser';
import { loginRequest } from '../../authConfig';
import IAuth from 'system/types/interfaces/IAuth';

let timeOutReference: NodeJS.Timeout | null = null;

const authentication: IAuth = {
  context: undefined,
  get account(): AccountInfo | void {
    return this.context?.accounts[0]
  },
  async getToken(): Promise<string | void> {
    if (!this.context || !this.account) {
      return;
    }

    const result = await this.context.instance.acquireTokenSilent({
      scopes: loginRequest.scopes,
      account: this.account
    }).catch(e => {
      console.error(e);
      this.context?.instance.loginRedirect({ scopes: loginRequest.scopes });
    });

    return result?.accessToken;
  },
  get isNotInitialized() {
    return this.context?.inProgress !== 'none' || !(this.context?.instance as any).initialized;
  },
  get sessionTimeout(): number {
    var result = parseInt(process.env.REACT_APP_SESSION_TIMEOUT ?? '');
    if (isNaN(result)) {
      // Default timeout = 30 minutes
      result = 30;
    }

    return result;
  },
  sessionIgnoreActions: [
    'metric/metrics',
    'metric/age',
    'metric/filters'
  ],
  shouldIgnoreSessionExtension(action: string): boolean {
    for (const ignoreAction of this.sessionIgnoreActions) {
      if (action.match(new RegExp(`^${ignoreAction}`))) {
        return true;
      }
    }

    return false;
  },
  extendSession(action: string) {
    if (this.shouldIgnoreSessionExtension(action)) {
      return;
    }

    if (timeOutReference) {
      clearTimeout(timeOutReference);
      timeOutReference = null;
    }

    timeOutReference = setTimeout(() => {
      this.logout();
    }, this.sessionTimeout * 60000);
  },
  logout() {
    this.logoutInitialized = true;
    this.context?.instance.logoutRedirect();
  },
  logoutInitialized: false
}

export default authentication;
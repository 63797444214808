import authentication from 'system/libraries/authentication';
import { useEffect } from "react";
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from 'authConfig';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import AuthErrorPage from 'components/pages/system/AuthErrorPage';
import LoadingPage from 'components/pages/system/LoadingPage';
import {
  withAITracking
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import MainLayout from "components/layouts/MainLayout";
import { BrowserRouter } from "react-router-dom";

declare var pendo: any;
var iconsInitialized = false;

function App() {
  useEffect(() => {
    if (iconsInitialized) {
      return;
    }
    iconsInitialized = true;
    initializeIcons();
  });

  authentication.context = useMsal();

  useEffect(() => {
    if (authentication.isNotInitialized) {
      return;
    }

    pendo.initialize({
      visitor: {
        id: authentication.context?.accounts[0]?.localAccountId ?? ''
      },

    });
  });

  if (authentication.logoutInitialized || authentication.isNotInitialized) {
    return <LoadingPage />;
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={AuthErrorPage}
      loadingComponent={LoadingPage}
    >
      <BrowserRouter>
        <MainLayout />
      </BrowserRouter>
    </MsalAuthenticationTemplate>
  );
}

let app: any;
if (process.env.REACT_APP_APP_INSIGHTS_KEY) {
  app = withAITracking(reactPlugin, App);
} else {
  app = App;
}

export default app;
import { Autocomplete as MuiAutocomplete, TextField, Typography, ThemeProvider } from "@mui/material";
import { muiTheme } from "system/styles/muiTheme";

interface OptionType {
  id: number;
  label: string;
}

interface SearchDropdownProps {
  label: string;
  value: OptionType | undefined;
  onChange: (event: React.ChangeEvent<{}>, value: OptionType | null) => void;
  options: OptionType[];
  getOptionLabel: (option: OptionType) => string;
  getOptionSelected: (option: OptionType, value: OptionType) => boolean;
  renderInput?: (params: any) => React.ReactNode; // Define the type based on what you expect to pass here.
}

const SearchDropdown: React.FC<SearchDropdownProps> = ({
  label,
  value,
  onChange,
  options,
  getOptionLabel,
  getOptionSelected,
  renderInput,
}) => {
  return (
    <ThemeProvider theme={muiTheme}>
      <div style={{ width: "300px" }}>
        <Typography className="ms-Label" sx={{ fontSize: "14px", fontFamily: "Segoe UI", fontWeight: 600, padding: "5px 0px" }}>
          {label}
        </Typography>
        <MuiAutocomplete
          disableClearable
          size="small"
          value={value}
          onChange={onChange}
          options={options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={getOptionSelected}
          renderInput={renderInput || ((params) => <TextField {...params} variant="outlined" />)}
        />
      </div>
    </ThemeProvider>
  );
};

export default SearchDropdown;

import IMainState from "system/types/store/IMainState";
import IReducerAction from "system/types/store/IReducerAction";
import ITitleData from "system/types/interfaces/ITitleData";
import IMultiFilter from "system/types/interfaces/IMultiFilter";
import IAppBreadcrumb from "system/types/interfaces/IAppBreadcrumb";
import { ILicenseCount } from "system/types/interfaces";

const mainReducers = {
  setPageTitle(state: IMainState, action: IReducerAction<ITitleData>) {
    state.pageTitle = action.payload.title;
    state.subTitle = action.payload.subTitle;
    state.navigateOnTitleClick = action.payload.navigateOnTitleClick;
    state.navigateOnSubTitleClick = action.payload.navigateOnSubTitleClick;
  },

  setFilters(state: IMainState, action: IReducerAction<IMultiFilter[] | null | undefined>) {
    state.filters = action.payload ?? null;
    state.lastFilterSet = new Date().getTime();
  },

  setBreadcrumbs(state: IMainState, action: IReducerAction<IAppBreadcrumb[] | null | undefined>) {
    state.breadcrumbs = action.payload ?? [];
  },

  setIsMobileSidebarVisible(state: IMainState, action: IReducerAction<boolean>) {
    state.isMobileSidebarVisible = action.payload;
  },

  setLicensesCount: (state: IMainState, action: IReducerAction<ILicenseCount | null>) => {
    state.licensesCount = action.payload;
  },
};

export default mainReducers;

export const getFileSize = (bytes: number): string => {
  if (bytes < 1024) return bytes + " B";

  let kb = roundToTwo(bytes / 1024);
  if (kb < 1024) return kb + " KB";

  let mb = roundToTwo(kb / 1024);
  if (mb < 1024) return mb + " MB";

  let gb = roundToTwo(mb / 1024);
  return gb + " GB";
};

export const getFileExtension = (filename: string) => {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
};

const roundToTwo = (num: number): number => {
  return Number(num.toFixed(2));
};

import usePageTitle from "system/hooks/use-page-title";
import ActionButtons from "components/ui/ActionButtons";
import EndpointFilter from "components/ui/EndpointFilter";
import { InfoIcon } from "components/ui/Icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import useMultiFilters from "system/hooks/use-multi-filters";
import { SubjectStatusSubjectSummary, SubjectStatusSummary, SubjectStatusTime } from "system/types/wireTypes";
import useChartRange from "system/hooks/use-chart-range";
import { toDate, toFormatedDate, toLocalISOString } from "system/libraries/utilities";
import TheMainChart from "components/ui/TheMainChart";
import useUrlSearch from "system/hooks/use-url-search";
import EndpointsBySubjectTable from "components/ui/EndpointsBySubjectTable";
import useConfiguration from "system/hooks/use-configuration";
import AccessDeniedPage from "./system/AccessDeniedPage";
import LoadingPage from "./system/LoadingPage";
import useApi from "system/hooks/use-api";
import ExportToCsvButton from "components/ui/ExportToCsvButton";
import { Dropdown, ResponsiveMode } from "@fluentui/react";
import { fluentUiDarkTheme, fluentUiTitleDropdownStyle } from "system/styles/fluent-ui-classes";
import { useDispatch } from "react-redux";
import { mainActions } from "system/store/slices/main-slice";
import { Loading } from "components/ui";

let chartUpdateRefresh: NodeJS.Timeout;

const MainPage = () => {
  const api = useApi();
  const [selectedDateValue, setSelectedDateValue] = useState<string>("");
  const [selectedDateFormatted, setSelectedDateFormatted] = useState<string | null>(null);
  const { currentTenant } = useConfiguration();
  const [mainChartData, setMainChartData] = useState<SubjectStatusTime[] | null>(null);
  const [endpointsBySubjectData, setEndpointsBySubjectData] = useState<SubjectStatusSubjectSummary | null>(null);
  const [chartRefresh, setChartRefresh] = useState(false);
  const [selectedDateString, setSelectedDateString] = useState<string | null>(null);
  const [loadingMessage, setLoadingMessage] = useState<string>("Loading data...");
  const { machineFilterSet } = useMultiFilters();
  const dates = useChartRange();
  const urlSearch = useUrlSearch();
  const dispatch = useDispatch();

  const selectedGraphBy = useMemo(() => (urlSearch.get("graphBy")?.toLowerCase() === "subject" ? "subject" : "endpoint"), [urlSearch]);

  usePageTitle({
    title: currentTenant?.siteName ?? "",
    subTitle: currentTenant?.siteName?.length ? "Company" : "",
  });

  // Updates chart (scheduled every 10 minutes)
  useEffect(() => {
    if (!machineFilterSet || !dates || !currentTenant) {
      setMainChartData(null);
      setEndpointsBySubjectData(null);
      return;
    }

    const messageTimeouts: NodeJS.Timeout[] = [];

    messageTimeouts.push(
      setTimeout(() => {
        setLoadingMessage("Filtering the results...");
      }, 8000) // 8 seconds and changes the message
    );

    messageTimeouts.push(
      setTimeout(() => {
        setLoadingMessage("Calculating values by Subject...");
      }, 16000) // We change the mssage after 16 seconds
    );

    api.summary
      .subjectStatus({
        breakoutOption: 0,
        filters: machineFilterSet,
        graphBy: selectedGraphBy,
        referenceTimes: dates.map((x) => x.toISOString()),
      })
      .then((data: SubjectStatusSummary | null) => {
        if (data?.isSuccess && data?.byBreakoutValue && data.byBreakoutValue.length > 0) {
          setMainChartData(data?.byBreakoutValue[0].byTime);
          setLoadingMessage(`Getting information for ${currentTenant.actualMachineCount} machines...`);
        } else {
          setMainChartData(null);
        }

        chartUpdateRefresh = setTimeout(() => {
          setChartRefresh(!chartRefresh);
        }, 600000);
      });

    return () => {
      clearTimeout(chartUpdateRefresh);
    };
  }, [api, chartRefresh, machineFilterSet, dates, currentTenant, selectedGraphBy]);

  useEffect(() => {
    const selectedDateUrlValue = urlSearch.get("selectedDate");

    if (selectedDateUrlValue !== selectedDateValue && selectedDateUrlValue) {
      setSelectedDateValue(selectedDateUrlValue);
      setSelectedDateString(selectedDateUrlValue);
      return;
    }

    if (selectedDateValue?.length) {
      setSelectedDateString(selectedDateValue);
      return;
    }

    if (!dates?.length) {
      setSelectedDateString(null);
      return;
    }

    const resultDate = toLocalISOString(dates[dates.length - 1]);
    setSelectedDateValue(resultDate);
    setSelectedDateString(resultDate);
  }, [selectedDateValue, dates, urlSearch]);

  const updateEndpointsBySubjectTable = useCallback(
    async (maxRetries = 2) => {
      if (!selectedDateString || !machineFilterSet || !dates) {
        return;
      }

      setEndpointsBySubjectData(null);

      let attempts = 0;
      while (attempts <= maxRetries) {
        try {
          const data = await api.summary.subjectStatusSubject({
            filters: machineFilterSet,
            fromTime: selectedDateString ? new Date(selectedDateString) : null,
          });

          if (data?.isSuccess && data?.bySubject?.length && data?.bySubject?.length > 0) {
            setEndpointsBySubjectData(data);
            setSelectedDateFormatted(toFormatedDate(selectedDateString!.replace("Z", "")));
            return;
          } else {
            setSelectedDateFormatted(null);
          }

          break;
        } catch (error) {
          attempts++;
          console.error(`Attepmt ${attempts} failed.`, error);

          if (attempts > maxRetries) {
            console.error("Max retry reached! We could not get any data.");
            setSelectedDateFormatted(null);
          }
        }
      }
    },
    [api.summary, dates, machineFilterSet, selectedDateString]
  );

  // Updates Endpoints by subject table
  useEffect(() => {
    updateEndpointsBySubjectTable();
  }, [updateEndpointsBySubjectTable]);

  const exportData = useMemo(() => {
    return {
      fileName: `Aiden Endpoints by Subject ${toDate(selectedDateValue)}`,
      source:
        endpointsBySubjectData?.bySubject?.map((x) => ({
          Subject: x.subject,
          FailedToEvaluate: x.nError,
          NotReporting: x.nUnknown,
          HasRecommendations: x.nNoncompliant,
          UpToDatePendingReboot: x.nCompliantAfterReboot,
          UpToDate: x.nCompliant,
        })) ?? [],
    };
  }, [endpointsBySubjectData, selectedDateValue]);

  useEffect(() => {
    if (!endpointsBySubjectData || !currentTenant) {
      return;
    }

    const licensesCount = {
      actualLicensed: currentTenant?.actualMachineCount ?? 0,
      availableLicenses: currentTenant?.licensedMachineCount ?? 0,
      subjectsFiltered: !endpointsBySubjectData?.statusTime
        ? 0
        : endpointsBySubjectData.statusTime.nError +
          endpointsBySubjectData.statusTime.nUnknown +
          endpointsBySubjectData.statusTime.nNoncompliant +
          endpointsBySubjectData.statusTime.nCompliant +
          endpointsBySubjectData.statusTime.nCompliantAfterReboot,
    };

    dispatch(mainActions.setLicensesCount(licensesCount));
  }, [currentTenant, dispatch, endpointsBySubjectData]);

  const refreshHandler = useCallback(() => {
    setMainChartData(null);
    setEndpointsBySubjectData(null);
    setChartRefresh(!chartRefresh);
    updateEndpointsBySubjectTable();
  }, [chartRefresh, updateEndpointsBySubjectTable]);

  const graphByChangeHandler = useCallback(
    (key?: string) => {
      urlSearch.set("graphBy", key?.toLowerCase() ?? null);
    },
    [urlSearch]
  );

  if (!currentTenant) {
    return <AccessDeniedPage />;
  }

  const dropdownOptions = [
    { key: "endpoint", text: "Endpoint Compliance" },
    { key: "subject", text: "Product Compliance" },
  ];

  if (!mainChartData) {
    return <LoadingPage />;
  }

  return (
    <>
      <ActionButtons timespanButton={true} onRefresh={refreshHandler}>
        <ExportToCsvButton items={[exportData]} />
      </ActionButtons>
      <EndpointFilter />
      {mainChartData ? (
        <div className="main-chart">
          <div className="card">
            <div className="card-body">
              <span className="endpoint-head d-flex align-items-center gap-1">
                {currentTenant.isSubjectStatusEnabled ? (
                  <Dropdown
                    dropdownWidth={300}
                    selectedKey={selectedGraphBy ? selectedGraphBy : "endpoint"}
                    onChange={(_, item) => graphByChangeHandler(item?.key?.toString())}
                    responsiveMode={ResponsiveMode.xxxLarge}
                    styles={fluentUiTitleDropdownStyle}
                    options={dropdownOptions}
                    theme={fluentUiDarkTheme}
                  />
                ) : (
                  dropdownOptions[0].text
                )}
                <InfoIcon
                  text={`${
                    selectedGraphBy === "endpoint"
                      ? "Status of each endpoint based on full compliance with your desired state policy."
                      : "Status of all required products and tasks on all endpoints.  "
                  }`}
                />{" "}
              </span>
              <TheMainChart data={mainChartData} selectedGraphBy={selectedGraphBy} />
              {selectedDateFormatted && (
                <div className="summary-number">
                  <div className="d-flex justify-content-between gap-3 align-items-center">
                    <p className="mb-0">
                      <span className="endpoint-head">
                        {" "}
                        Endpoints by subject{" "}
                        <InfoIcon text="For a given subject, a count of endpoints in where the subject is in a given compliance state." />
                      </span>
                    </p>
                    <p className="d-flex flex-column flex-md-row gap-1 gap-md-2 align-items-md-center mb-0">
                      <span className="lh-sm"> Selected date and time</span>
                      <span className="number lh-sm">{selectedDateFormatted}</span>
                    </p>
                  </div>
                </div>
              )}
              {endpointsBySubjectData ? (
                <div className="machine-table scroll" style={{ maxHeight: "65vh", marginTop: "16px", paddingRight: "10px" }}>
                  <EndpointsBySubjectTable data={endpointsBySubjectData} selectedDateString={selectedDateString} />
                </div>
              ) : (
                <Loading message={loadingMessage} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <LoadingPage />
      )}
    </>
  );
};

export default MainPage;

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { mainActions } from "system/store/slices/main-slice";
import IAppBreadcrumb from "system/types/interfaces/IAppBreadcrumb";

const useBreadcrumbs = (breadcrumbs?: IAppBreadcrumb[] | null) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mainActions.setBreadcrumbs(breadcrumbs));

    return () => {
      dispatch(mainActions.setBreadcrumbs());
    };
  }, [dispatch, breadcrumbs]);
}

export default useBreadcrumbs;
import { useMemo, useState } from "react";
import csvDownload from "json-to-csv-export";
import { CommandButton, IContextualMenuProps } from "@fluentui/react";

type Source = object[] | null | undefined | (() => Promise<object[] | null | undefined>);

interface IExportItem {
  text?: string;
  source: Source;
  fileName?: string;
}

interface IProps {
  text?: string;
  icon?: string;
  items?: IExportItem[];
}

const ExportToCsvButton = ({ text, icon, items }: IProps) => {
  const [loading, setLoading] = useState(false);

  const buttonText = text ? text : "Export";
  const buttonIcon = icon ? icon : "Download";

  const exportHandler = async (source: Source, fileName: string = "CsvExport") => {
    setLoading(true);
    const csvData = source instanceof Function ? await source() : source;
    if (!csvData || csvData.length < 1) {
      setLoading(false);
      return;
    }

    csvDownload({
      data: csvData,
      delimiter: ",",
      filename: `${fileName}.csv`,
    });

    setLoading(false);
  };

  const menuItems = useMemo<IContextualMenuProps>(
    () => ({
      items:
        items?.map((x) => ({
          key: x.text ?? Math.random().toString(),
          text: x.text,
          onClick: () => {
            exportHandler(x.source, x.fileName);
          },
        })) ?? [],
    }),
    [items]
  );

  if (loading) {
    return <CommandButton iconProps={{ iconName: buttonIcon }} text="Loading" disabled={true} />;
  }

  if (!items || items.length < 1) {
    return <></>;
  }

  if (items.length === 1) {
    return (
      <CommandButton
        iconProps={{ iconName: buttonIcon }}
        text={buttonText}
        onClick={() => {
          if (menuItems.items[0].onClick) {
            menuItems.items[0].onClick();
          }
        }}
      />
    );
  }

  return <CommandButton iconProps={{ iconName: buttonIcon }} text={buttonText} menuProps={menuItems} />;
};

export default ExportToCsvButton;

import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import usePageTitle from "system/hooks/use-page-title";
import IMachineSubjectHistory from "system/types/interfaces/IMachineSubjectHistory";
import EndpointSubjectStatusList from "components/ui/EndpointSubjectStatusList";
import LoadingPage from "./system/LoadingPage";
import ActionButtons from "components/ui/ActionButtons";
import usePageNavigation from "system/hooks/use-page-navigation";
import useApi from "system/hooks/use-api";
import IAppBreadcrumb from "system/types/interfaces/IAppBreadcrumb";
import useBreadcrumbs from "system/hooks/use-breadcrumbs";
import { getSorter, toDate, toFormatedDate } from "system/libraries/utilities";
import ExportToCsvButton from "components/ui/ExportToCsvButton";

const EndpointSubjectHistory = () => {
  const api = useApi();
  const { machineId, time, subject } = useParams();
  const [refreshCount, setRefreshCount] = useState(0);
  const [subjectHistoryData, setSubjectHistoryData] = useState<IMachineSubjectHistory | null>(null);
  const { navigateToEndpointPage, getEndpointPageUrl } = usePageNavigation();

  usePageTitle({
    title: subject ?? "",
    subTitle: "Subject",
    navigateOnTitleClick: getEndpointPageUrl(machineId),
  });

  useBreadcrumbs(
    useMemo<IAppBreadcrumb[]>(() => {
      if (!subjectHistoryData?.machineName) {
        return [];
      }

      let result: IAppBreadcrumb[] = [];
      result.push({
        name: subjectHistoryData.machineName,
        url: getEndpointPageUrl(machineId, time),
      });

      if (time) {
        result.push({
          title: "Time",
          name: toFormatedDate(time),
          url: getEndpointPageUrl(machineId, time),
        });
      }

      if (subject) {
        result.push({
          name: subject,
        });
      }

      return result;
    }, [subjectHistoryData, machineId, time, subject, getEndpointPageUrl])
  );

  useEffect(() => {
    let requestTime: Date | null = null;
    if (time && !isNaN(Date.parse(time))) {
      requestTime = new Date(time);
    }

    if (!subject || !requestTime) {
      navigateToEndpointPage(machineId);
      return;
    }

    if (!!machineId && !isNaN(+machineId)) {
      api.machine
        .getMachineSubjectHistory(+machineId, requestTime!, subject)
        .then((data) => {
          if (data.isSuccess) {
            setSubjectHistoryData(data.value);
          } else {
            console.error(data.failMessage);
            navigateToEndpointPage(machineId);
          }
        })
        .catch((error) => {
          console.error(error);
          navigateToEndpointPage(machineId);
        });
    }

    return () => {
      setSubjectHistoryData(null);
    };
  }, [api, machineId, time, subject, navigateToEndpointPage, getEndpointPageUrl, refreshCount]);

  const exportData = useMemo(
    () => ({
      fileName: `Aiden ${subject} Subject History ${toDate(time)}`,
      source: subjectHistoryData?.subjectStatuses?.sort(getSorter("referenceJsTime", true)).map((row) => ({
        Subject: row.subject,
        Package: row.packageName,
        State: row.complianceStatus,
        Justification: row.complianceJustification,
        AssessmentTime: toFormatedDate(row.referenceJsTime),
        AssessmentTimeISO8601: row.referenceJsTime,
        UseCase: row.packageType,
        Rule: row.ruleDescription,
        LastExitCode: row.packageExitCode,
        Status: row.applicabilityMessage,
      })),
    }),
    [subjectHistoryData, subject, time]
  );

  const refreshHandler = () => {
    setRefreshCount(refreshCount + 1);
  };

  if (!subjectHistoryData) {
    return <LoadingPage />;
  }

  return (
    <>
      <ActionButtons onRefresh={refreshHandler}>
        <ExportToCsvButton items={[exportData]} />
      </ActionButtons>
      <div className="machine-table scroll" style={{ maxHeight: "65vh", marginTop: "16px", paddingRight: "10px" }}>
        <EndpointSubjectStatusList
          subjectStatuses={subjectHistoryData!.subjectStatuses}
          machineId={machineId}
          defaultSort={{ sort: "referenceJsTime", sortDesc: true }}
        />
      </div>
    </>
  );
};

export default EndpointSubjectHistory;

import {
  Announced,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  TextField,
  Toggle,
  TooltipHost,
  Text,
  IRenderFunction,
  IDetailsHeaderProps,
  IDetailsRowStyles,
  DetailsHeader,
  DetailsRow,
  IDetailsRowProps,
  IDetailsHeaderStyles,
  IDetailsColumnRenderTooltipProps,
  Dropdown,
  ResponsiveMode,
  IDropdownOption,
} from "@fluentui/react";
import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import useApiCve from "system/hooks/use-api-cve";
import usePageTitle from "system/hooks/use-page-title";
import fluentUiClasses, {
  fluentUiDarkTheme,
  fluentUiControlStyles,
  fluentUiGridStyles,
  fluentUiBoldStyle,
  fluentUiDropdownStyle,
} from "system/styles/fluent-ui-classes";
import ICvePackage from "system/types/interfaces/ICvePackage";
import ICvePackageData from "system/types/interfaces/ICvePackageData";
import ICveResult from "system/types/interfaces/ICveResult";
import ConnectionErrorPage from "./system/ConnectionErrorPage";
import ActionButtons from "components/ui/ActionButtons";
import LoadingPage from "./system/LoadingPage";
import { getSorter, toDate } from "system/libraries/utilities";
import useConfiguration from "system/hooks/use-configuration";
import ICveCustomer from "system/types/interfaces/ICveCustomer";
import ExportToCsvButton from "components/ui/ExportToCsvButton";
import ICvePackageCustomer from '../../system/types/interfaces/ICvePackage';

const PackagesPage = () => {
  const [packageData, setPackageData] = useState<ICvePackageData | null>(null);
  const [customerList, setCustomerList] = useState<ICveCustomer[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<number[]>([]);
  const [isAllPackages, setIsAllPackages] = useState(true);
  const [isPackagesLoading, setIsPackagesLoading] = useState(false);
  const [packageSearch, setPackageSearch] = useState("");
  const [sortedColumnField, setSortedColumnField] = useState("packageName");
  const [isSortedDescending, setIsSortedDescending] = useState(false);
  const [isFailedToLoad, setIsFailedToLoad] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { currentTenant } = useConfiguration();

  const getPackageId = useCallback((item: any): string => {
    return item?.packageId ?? Math.random().toString();
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  usePageTitle({
    title: "Packages",
    subTitle: "",
  });

  const apiCve = useApiCve();
  const loadData = useCallback(() => {
    setIsPackagesLoading(true);
    setIsFailedToLoad(false);
    const requestPackages = isAllPackages ? apiCve.fetchAllPackages() : apiCve.fetchPackagesByCustomers(selectedCustomers);

    requestPackages
      .then((data: ICveResult<ICvePackageData>) => {
        setPackageData(data.value);
        setIsPackagesLoading(false);
        setIsLoaded(true);
      })
      .catch(() => {
        setIsFailedToLoad(true);
        setIsLoaded(false);
      });
  }, [apiCve, isAllPackages, setIsPackagesLoading, setIsLoaded, selectedCustomers]);

  const loadCustomers = useCallback(() => {
    apiCve.fetchCustomers().then(({ value }: ICveResult<ICveCustomer[]>) => {
      setCustomerList(value);
    });
  }, [apiCve, setCustomerList]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    loadCustomers();
  }, [loadCustomers]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) {
        return setWindowWidth(window.innerWidth);
      }
      return setWindowWidth(window.innerWidth / 2);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isAllPackages) {
      setSelectedCustomers([]);
      return;
    }

    setSelectedCustomers(
      customerList
        .filter(
          (x) =>
            x.customerName.toLocaleLowerCase() === currentTenant?.shortName.toLocaleLowerCase() ||
            x.customerName.toLocaleLowerCase() === currentTenant?.siteName.toLocaleLowerCase()
        )
        .map((x) => x.customerId)
    );
  }, [isAllPackages, setSelectedCustomers, currentTenant, customerList]);

  const filteredPackageList = useMemo<ICvePackage[]>(() => {
    if (!packageData?.items) {
      return [];
    }

    return packageData.items
      .filter((x) => x.packageName.toLowerCase().indexOf(packageSearch.toLowerCase().trim()) !== -1)
      .sort(getSorter(sortedColumnField, isSortedDescending));
  }, [packageData, packageSearch, sortedColumnField, isSortedDescending]);

  const onColumnClickHandler = useCallback(
    (_: any, column: IColumn) => {
      if (sortedColumnField === column.fieldName) {
        setIsSortedDescending(!isSortedDescending);
        return;
      }

      setSortedColumnField(column.fieldName ?? sortedColumnField);
      setIsSortedDescending(false);
    },
    [sortedColumnField, isSortedDescending, setSortedColumnField, setIsSortedDescending]
  );

  const columns = useMemo<IColumn[]>(() => {
    const initialColumns: IColumn[] = [
      {
        key: "icon",
        name: "Icon",
        className: fluentUiClasses.iconCell,
        iconClassName: fluentUiClasses.iconHeader,
        ariaLabel: "Column operations for File type, Press to sort on File type",
        iconName: "Page",
        isIconOnly: true,
        fieldName: "icon",
        minWidth: 16,
        maxWidth: 16,
        onRender: (item: ICvePackage) => (
          <TooltipHost content={`Package`}>
            <img src={item.icon} className={fluentUiClasses.fileIconImg} alt={"Package"} />
          </TooltipHost>
        ),
      },
      {
        key: "packageName",
        name: "Package Name",
        fieldName: "packageName",
        minWidth: 500,
        isRowHeader: true,
        isResizable: true,
        isSorted: sortedColumnField === "packageName",
        isSortedDescending: isSortedDescending && sortedColumnField === "packageName",
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: onColumnClickHandler,
        data: "string",
        isPadded: true,
      },
      {
        key: "customerName",
        name: "Customer Name",
        fieldName: "customerName",
        minWidth: windowWidth,
        isRowHeader: true,
        isResizable: true,
        isSorted: sortedColumnField === "customerName",
        isSortedDescending: isSortedDescending && sortedColumnField === "customerName",
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: onColumnClickHandler,
        data: "string",
        isPadded: true,
      },
    ];

    return isAllPackages ? initialColumns.filter((c: IColumn) => c.key !== "customerName") : initialColumns;
  }, [windowWidth, sortedColumnField, isSortedDescending, onColumnClickHandler, isAllPackages]);

  const refreshHandler = useCallback(() => {
    loadData();
    loadCustomers();
  }, [loadData, loadCustomers]);

  const onRenderColumnHeaderTooltipHandler = useCallback((props?: IDetailsColumnRenderTooltipProps) => {
    const customStyles: CSSProperties = {
      color: fluentUiDarkTheme.palette.black,
      backgroundColor: fluentUiDarkTheme.palette.blackTranslucent40,
    };

    if (props) {
      return (
        <span className={props.hostClassName} style={customStyles}>
          {props.children}
        </span>
      );
    }

    return null;
  }, []);

  const onRenderDetailsHeaderHandler = useCallback<IRenderFunction<IDetailsHeaderProps>>(
    (props?: IDetailsHeaderProps) => {
      const customStyles: Partial<IDetailsHeaderStyles> = {};
      if (props) {
        customStyles.root = {
          backgroundColor: fluentUiDarkTheme.palette.blackTranslucent40,
          color: fluentUiDarkTheme.palette.black,
        };

        return (
          <DetailsHeader
            {...props}
            styles={customStyles}
            theme={fluentUiDarkTheme}
            onRenderColumnHeaderTooltip={onRenderColumnHeaderTooltipHandler}
          />
        );
      }

      return null;
    },
    [onRenderColumnHeaderTooltipHandler]
  );

  const onRenderRowHandler = useCallback((props?: IDetailsRowProps) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      customStyles.root = {
        backgroundColor: fluentUiDarkTheme.palette.blackTranslucent40,
      };

      customStyles.isRowHeader = { ...customStyles.root };
      return <DetailsRow {...props} styles={customStyles} theme={fluentUiDarkTheme} />;
    }

    return null;
  }, []);

  const onCustomerSelectHandler = useCallback(
    (option: IDropdownOption<any> | undefined) => {
      if (typeof option?.key !== "number") {
        return;
      }

      if (option.selected && selectedCustomers.indexOf(option.key) === -1) {
        setSelectedCustomers([...selectedCustomers, option.key]);
      } else {
        setSelectedCustomers(selectedCustomers.filter((x) => x !== option.key));
      }
    },
    [selectedCustomers, setSelectedCustomers]
  );

  const exportData = useMemo(() => {
    const fileName = isAllPackages ? `Aiden All Packages - ${filteredPackageList.length} packages - ${toDate(new Date().toString())}`: `Aiden Packages by Customers - ${filteredPackageList.length} packages - ${toDate(new Date().toString())}`;
    return {
      fileName,
      source:
        isAllPackages ? 
        filteredPackageList?.map((x: ICvePackageCustomer) => ({
          packageName: x.packageName
        })) : 
        filteredPackageList?.map((x: ICvePackageCustomer) => ({
          packageName: x.packageName,
          customerName: x.customerName,
        }))
        ,
    };
  }, [filteredPackageList, isAllPackages]);

  return (
    <div style={{ color: "var(--text-color)" }}>
      <ActionButtons onRefresh={refreshHandler}>
        <ExportToCsvButton items={[exportData]} />
      </ActionButtons>
      {isFailedToLoad ? (
        <ConnectionErrorPage />
      ) : (
        <div className="card-body">
          <div className={fluentUiClasses.controlWrapper}>
            <TextField
              label="Search Packages:"
              onChange={(_, value?: string) => {
                setPackageSearch(value ?? "");
              }}
              styles={fluentUiControlStyles}
              disabled={isPackagesLoading}
              theme={fluentUiDarkTheme}
            />
            <Toggle
              label="Show Packages"
              checked={isAllPackages}
              onChange={() => setIsAllPackages(!isAllPackages)}
              onText="All Packages"
              offText="Company specific Packages"
              styles={fluentUiControlStyles}
              disabled={isPackagesLoading}
              theme={fluentUiDarkTheme}
            />
            {!isAllPackages && customerList && (
              <Dropdown
                label="Customers"
                multiSelect={true}
                dropdownWidth={300}
                selectedKeys={selectedCustomers}
                onChange={(_, option: IDropdownOption<any> | undefined) => {
                  onCustomerSelectHandler(option);
                }}
                responsiveMode={ResponsiveMode.xxxLarge}
                styles={fluentUiDropdownStyle}
                options={customerList.map<IDropdownOption<number>>((x: ICveCustomer) => ({
                  key: x.customerId,
                  text: x.customerName,
                }))}
                theme={fluentUiDarkTheme}
              />
            )}
            <Announced message={`Number of items after filter applied: ${filteredPackageList.length}.`} />
          </div>
          {isLoaded ? (
            <>
              <DetailsList
                items={filteredPackageList}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={getPackageId}
                setKey="none"
                layoutMode={DetailsListLayoutMode.fixedColumns}
                isHeaderVisible={true}
                styles={fluentUiGridStyles}
                onRenderDetailsHeader={onRenderDetailsHeaderHandler}
                onRenderRow={onRenderRowHandler}
                theme={fluentUiDarkTheme}
              />
              <div className={fluentUiClasses.controlWrapper} style={{ marginTop: "10px" }}>
                <Text variant="large" styles={fluentUiBoldStyle}>
                  {`All Packages Count: ${filteredPackageList.length ?? 0}`}
                </Text>
              </div>
            </>
          ) : (
            <LoadingPage />
          )}
        </div>
      )}
    </div>
  );
};

export default PackagesPage;

import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { configActions } from "system/store/slices/config-slice";
import useApi from "./use-api";

const useConfigure = () => {
  const api = useApi();
  const dispatch = useDispatch();
  
  return useCallback(async (): Promise<boolean | null> => {
    return await api.admin.me().then((result) => {
      if (!result?.isSuccess) {
        console.warn(result.failMessage);
        return null;
      }

      dispatch(configActions.setData(result));

      if (!result.tenants?.length) {
        return false;
      }
      
      return true;
    }).catch((e: Error) => {
      console.error(e);
      return null;
    });
  }, [api, dispatch]);
};

export default useConfigure;

import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import useAppNavigation from "system/hooks/use-app-navigation";
import ISortableTableHeaderProps from "system/types/interfaces/ISortableTableHeaderProps";

const SingleSortTableHeader = ({ title, className, property }: ISortableTableHeaderProps) => {
  const navigate = useAppNavigation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sortDesc = queryParams.get(property ?? '') !== '1';

  const clickHandler = useCallback(() => {
    if (!property) {
      return;
    }

    if (sortDesc) {
      navigate(`?${property}=1`);
    } else {
      navigate("");
    }
  }, [sortDesc, property, navigate]);

  const iconClassName = !!property ? (sortDesc ? 'bi bi-arrow-up' : 'bi bi-arrow-down') : null;

  return (<span
    className={className + " clickable"}
    onClick={clickHandler}
  >{title} {iconClassName && <i className={iconClassName}></i>}</span>);
}

export default SingleSortTableHeader;
import { Icon } from "@fluentui/react/lib/Icon";

const containerStyle = {
  backgroundColor: "white",
  borderRadius: ".4rem",
};
const folderStyle = {
  backgroundColor: "#ffc107",
  borderRadius: ".4rem",
};
const iconStyle = {
  fontSize: "1.1rem",
  verticalAlign: "top",
};

type FileIconMapping = {
  [key: string]: { iconName: string; color: string };
};

const fileIcons: FileIconMapping = {
  // Office documents
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
    iconName: "ExcelDocument",
    color: "#217346",
  },
  "application/vnd.ms-excel": { iconName: "ExcelDocument", color: "#217346" },
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
    iconName: "WordDocument",
    color: "#2B579A",
  },
  "application/msword": { iconName: "WordDocument", color: "#2B579A" },
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": {
    iconName: "PowerPointDocument",
    color: "#D24726",
  },
  "application/vnd.ms-powerpoint": {
    iconName: "PowerPointDocument",
    color: "#D24726",
  },

  // Images
  "image/png": { iconName: "FileImage", color: "#0078D4" },
  "image/jpeg": { iconName: "FileImage", color: "#0078D4" },
  "image/gif": { iconName: "FileImage", color: "#0078D4" },
  "image/svg+xml": { iconName: "FileImage", color: "#0078D4" },

  // Code and text
  "text/plain": { iconName: "TextDocument", color: "#333333" },
  "text/html": { iconName: "Code", color: "#D83B01" },
  "text/css": { iconName: "Code", color: "#D83B01" },
  "text/javascript": { iconName: "Code", color: "#D83B01" },
  "application/json": { iconName: "Code", color: "#D83B01" },
  "application/xml": { iconName: "Code", color: "#D83B01" },

  // Zip files
  "application/zip": { iconName: "ZipFolder", color: "#FF8C00" },
  "application/x-rar-compressed": { iconName: "ZipFolder", color: "#FF8C00" },
  "application/x-7z-compressed": { iconName: "ZipFolder", color: "#FF8C00" },

  // Video and Audio
  "video/mp4": { iconName: "Video", color: "#800080" },
  "video/x-msvideo": { iconName: "Video", color: "#800080" },
  "video/quicktime": { iconName: "Video", color: "#800080" },
  "audio/mp3": { iconName: "MusicNote", color: "#0078D4" },
  "audio/wav": { iconName: "MusicNote", color: "#0078D4" },
  "audio/aac": { iconName: "MusicNote", color: "#0078D4" },

  // PDF
  "application/pdf": { iconName: "PDF", color: "#D83B01" },

  // folder
  folder: { iconName: "FabricFolder", color: "#000000" },

  default: { iconName: "FileASPX", color: "#333333" },
};

const FileIcon = ({ fileType }: { fileType: string }) => {
  const icon = fileIcons[fileType] || fileIcons["default"];

  return (
    <div style={fileType === "folder" ? folderStyle : containerStyle}>
      <Icon iconName={icon.iconName} style={{ ...iconStyle, color: icon.color }} />
    </div>
  );
};

export default FileIcon;

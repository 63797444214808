import SortableTableHeader from "components/ui/SortableTableHeader";
import ITableColumnHeaderProps from "system/types/interfaces/ITableColumnHeaderProps";
import SingleSortTableHeader from "./SingleSortTableHeader";

const TableColumnHeader = ({ title, property, width, singleSort }: ITableColumnHeaderProps) => {
  if (!!property && singleSort) {
    return (<th className="fit align-middle" scope="col" style={width === undefined ? {} : { width: width }}>
      <SingleSortTableHeader title={title} className="ts-date-title truncated-header" property={property} />
    </th>);
  }


  return (
    <th className="fit align-middle" scope="col" style={width === undefined ? {} : { minWidth: width }}>
      {!!property
        ? <SortableTableHeader title={title} className="ts-date-title truncated-header" property={property} />
        : <span className="ts-date-title">{title}</span>}
    </th>
  );
};

export default TableColumnHeader;
const AccessDeniedPage = () => {
  return (
    <>
      <div className="denied">
        <div className="denied__content"
        >
          <img
            alt="logo"
            src="/icon.png"
            style={{ float: "left", width: "50px", height: "50px" }}
          />
          <div className="denied__message">
            Access to your organization's data requires approval.
            <br />
            Please contact your AidenVision administrator.
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessDeniedPage;

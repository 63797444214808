import { useState } from "react";
import { DateRange, RangeKeyDict, Range } from "react-date-range";

interface IProps {
  from: Date;
  to: Date;
  onChange?: (from: Date, to: Date) => void;
}

const TimespanRangeSelect = ({ from, to, onChange }: IProps) => {
  const [datespan, setDatespan] = useState<Range[]>([
    {
      startDate: from,
      endDate: to,
      key: "selection"
    }
  ]);

  const setDatespanHandler = (item: RangeKeyDict) => {
    setDatespan([item.selection]);
    if (onChange &&
      item.selection.startDate &&
      item.selection.endDate &&
      item.selection.endDate > item.selection.startDate) {
      const endDate = item.selection.endDate;
      endDate.setHours(23, 59, 59, 0);
      onChange(item.selection.startDate, endDate);
    }
  };

  return (
    <div>
      <DateRange
        onChange={setDatespanHandler}
        moveRangeOnFirstSelection={false}
        ranges={datespan}
        direction="vertical"
        scroll={{ enabled: true }}
        showDateDisplay={false}
        maxDate={new Date()}
      />
    </div>
  );
}

export default TimespanRangeSelect;
import { ThreeDots } from "react-loader-spinner";

interface LoadingProps {
  message?: string;
}

const Loading = ({ message }: LoadingProps) => {
  return (
    <>
      <div style={{ width: "100%", textAlign: "center" }}>
        <div style={{ color: "#f7f7f7", fontSize: "20pt", marginTop: "130px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <ThreeDots visible={true} height="50" width="50" color="#fff" radius="10" ariaLabel="three-dots-loading" />
          <p>{message || "Please wait..."}</p>
        </div>
      </div>
    </>
  );
};

export default Loading;
import { configureStore } from "@reduxjs/toolkit";
import mainSlice from "./slices/main-slice";
import configSlice from "./slices/config-slice";

const store = configureStore({
  reducer: {
    main: mainSlice.reducer,
    config: configSlice.reducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
import { useState } from "react";
import { FormikErrors, useFormik } from "formik";
import useApi from "system/hooks/use-api";
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { AccessEntryInfo } from "system/types/wireTypes";

interface IProps {
  done: (user: AccessEntryInfo) => void;
}

interface ViewModel {
  email: string;
  role: "User" | "Admin";
}

export default function AccessForm({ done }: IProps) {
  const api = useApi();
  const [message, setMessage] = useState("");
  const form = useFormik({
    initialValues: {
      email: "",
      role: "User",
    },
    validate: (values) => {
      const errors: FormikErrors<ViewModel> = {};
      if (!values.email) errors.email = "Required";
      if (values.role !== "User" && values.role !== "Admin") errors.role = "Must be User or Admin";
      return errors;
    },
    onSubmit: async (values) => {
      const user: AccessEntryInfo = {
        email: values.email,
        role: values.role,
      };

      const result = await api.access.grant(user);
      if (result.isSuccess) {
        done(result.value ?? user);
      } else {
        setMessage(result.failMessage ?? "Error");
      }
      form.setSubmitting(false);
    },
  });

  return (
    <div>
      <form id="AccessForm" onSubmit={form.handleSubmit}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          <TextField
            label="Users email Address"
            name="email"
            value={form.values.email}
            onChange={form.handleChange}
            error={!!form.errors.email}
            helperText={form.errors.email}
            variant="standard"
            InputLabelProps={{ style: { color: "#ffffff" } }}
            InputProps={{ style: { color: "#ffffff", padding: "6px" } }}
          />

          <FormControl fullWidth error={!!form.errors.role}>
            <InputLabel id="role-label" sx={{ color: "#ffffff", fontSize: "0.875rem" }}>
              Role
            </InputLabel>
            <Select
              labelId="role-label"
              id="role"
              name="role"
              value={form.values.role}
              label="Role"
              onChange={form.handleChange}
              sx={{ color: "#ffffff", fontSize: "0.875rem", ".MuiSelect-icon": { color: "#ffffff" } }}
            >
              <MenuItem value="User">User</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
            </Select>
            {form.errors.role && <FormHelperText className="warning">{form.errors.role}</FormHelperText>}
          </FormControl>
          <div className="warning">{message}</div>
        </Box>
      </form>
    </div>
  );
}
